import {
  GridFilterModel,
  GridSortModel,
  GridPaginationModel,
} from "@mui/x-data-grid-premium";
import { useQuery } from "./useQuery";
import { useMemo } from "react";

export interface QsParams {
  q: string;
  page?: number;
  pageSize?: number;
  sort?: string;
  filter?: string;
  field?: string;
  linkType?: "and" | "or";
  test?: number[][];
}

export function useGridStateQuery() {
  const [query, setQuery] = useQuery<QsParams>(
    "field",
    "filter",
    "linkType",
    "q",
    "sort",
    "page",
    "pageSize",
    "test",
  );

  const sortModel: GridSortModel = useMemo(() => {
    return query.field
      ? [{ field: query.field, sort: (query.sort as any) || "asc" }]
      : [];
  }, [query.field, query.sort]);
  const paginationModel: GridPaginationModel = useMemo(
    () => ({ page: query.page || 0, pageSize: query.pageSize || 25 }),
    [query.page, query.pageSize],
  );
  const { setFilterModel, setFilters, setPaginationModel, setSortModel } =
    useMemo(() => {
      function setFilterModel(fModel: GridFilterModel) {
        const { logicOperator, quickFilterValues, items } = fModel;
        return setQuery(
          {
            filter: JSON.stringify(items),
            linkType: logicOperator,
            q: quickFilterValues?.[0],
          },
          "page",
        );
      }
      function setFilters(items: GridFilterModel["items"]) {
        return setQuery(
          {
            filter: JSON.stringify(items),
          },
          "page",
        );
      }
      function setSortModel(
        sModel: GridSortModel | GridSortModel[number] | undefined,
      ) {
        if (Array.isArray(sModel)) {
          return setSortModel(sModel[0]);
        }
        return setQuery(
          {
            field: sModel?.field,
            sort: sModel?.sort as string,
          },
          "page",
        );
      }
      function setPaginationModel(pagModel: GridPaginationModel) {
        setQuery({
          page: pagModel.page || undefined,
          pageSize: pagModel.pageSize === 25 ? undefined : pagModel.pageSize,
        });
      }
      return { setFilterModel, setFilters, setSortModel, setPaginationModel };
    }, [setQuery]);

  const { filterModel, setFilterItem } = useMemo(() => {
    const items = (() => {
      try {
        return JSON.parse(query.filter || "[]");
      } catch (e) {
        console.warn("Unable to parse filter", e);
        return [];
      }
    })();
    const filterModel: GridFilterModel = {
      logicOperator: query.linkType as any,
      quickFilterValues: query.q ? [query.q] : [],
      items,
    };
    function setFilterItem(
      id: number | string,
      item?: Omit<GridFilterModel["items"][number], "id">,
    ) {
      const newItems = filterModel.items.filter((i) => i.id !== id);
      setQuery(
        {
          filter:
            !newItems.length && !item
              ? undefined
              : JSON.stringify(
                  item ? [...newItems, { ...item, id }] : newItems,
                ),
        },
        "page",
      );
    }
    return { filterModel, setFilterItem };
  }, [query.filter, query.q, query.linkType]);
  return {
    filterModel,
    setFilterModel,
    setFilters,
    setPaginationModel,
    setSortModel,
    paginationModel,
    sortModel,
    setFilterItem,
  };
}
