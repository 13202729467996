import { useStatusesList } from "../hooks/useLists";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import {
  useMarkSessionNoShowMutation,
  useUpdateSessionStatusMutation,
} from "../../state/rtk-query/state/session";
import { useUpdateTaskStatusMutation } from "../../state/rtk-query/state/tasks";
import { DropDownButton } from "../buttons/dropdown";
import { ColoredChip } from "./ColoredChip";

type TaskType = "task" | "internal_task" | "custom_work" | "quick_question";

type PartColoredChipProps = Omit<
  ColoredChip.Props,
  "color" | "children" | "includeChevron" | "dropDown"
>;

export namespace StatusChip {
  export type Props = PartColoredChipProps & {
    statusId: number | undefined;
  } & (
      | ({
          dropDown: boolean;
          id: number | undefined;
          orgId: number | undefined;
        } & (SessionStatusProps | { type: TaskType | undefined }))
      | { dropDown?: undefined }
    );
}

type StatusChipProps = PartColoredChipProps & {
  statusId: number;
};

type SessionStatusProps = {
  type: "session";
  allowNoShow: boolean;
};

type TaskStatusProps = {
  type: "task" | "internal_task" | "custom_work" | "quick_question";
  allowNoShow?: boolean;
};

type DropDownProps = {
  dropDown: true;
  id: number;
  orgId: number;
};

function _StatusChip(props: StatusChip.Props) {
  if (!props.statusId) return null;
  if (!props.dropDown || !props.id || !props.orgId || !props.type) {
    return (
      <StatusChip
        statusId={props.statusId}
        sx={props.sx}
        pill={props.pill}
        small={props.small}
        fullWidth={props.fullWidth}
      />
    );
  }
  const { id, orgId, statusId, sx, dropDown, pill, small, ...rest } = props;
  return (
    <ValidateDropDown
      {...(rest as SessionStatusProps | TaskStatusProps)}
      small={small}
      pill={pill}
      dropDown={dropDown}
      id={id}
      orgId={orgId}
      statusId={statusId}
      sx={sx}
      fullWidth={props.fullWidth}
    />
  );
}

export { _StatusChip as StatusChip };

function ValidateDropDown(
  props: StatusChipProps &
    DropDownProps &
    (SessionStatusProps | TaskStatusProps),
) {
  const statuses = useStatusesList(props.type);
  const status = statuses.getStatus(props.statusId);
  const isInternal = useSelector(authSelectors.isInternal);
  if (!isInternal || !props.type) {
    return <StatusChip {...props} dropDown={false} />;
  }
  if (props.type === "session") {
    if (status?.transition_phase === "blocked") {
      return <StatusChip {...props} dropDown={false} />;
    }
    return <SessionStatusChip {...props} />;
  }
  return <TaskStatusChip {...props} type={props.type} />;
}

function StatusChip(
  props: StatusChipProps & { includeChevron?: boolean; dropDown?: boolean },
) {
  const { statusId, includeChevron, dropDown, pill, small, sx } = props;
  const statuses = useStatusesList();
  const status = statuses.getStatus(statusId);
  if (!status?.color) return null;
  return (
    <ColoredChip
      color={status.color}
      dropDown={dropDown}
      pill={pill}
      small={small}
      sx={sx}
      includeChevron={includeChevron}
      fullWidth={props.fullWidth}
    >
      {status.name}
    </ColoredChip>
  );
}

function StatusChipDropDown(
  props: StatusChipProps &
    DropDownProps &
    (SessionStatusProps | TaskStatusProps) & {
      handleStatusChange: (
        item: ReturnType<typeof useStatusesList>["statuses"][number],
      ) => void;
    },
) {
  const { type, handleStatusChange, statusId } = props;
  const { getEditableStatuses, getStatus } = useStatusesList(type);
  const editableStatuses = getEditableStatuses();

  return (
    <DropDownButton
      items={editableStatuses}
      selected={statusId}
      setSelected={(v) => {
        v && getStatus(v) && handleStatusChange(getStatus(v)!);
      }}
      allowDeselection={false}
      menuProps={{ sx: { maxHeight: "100%" } }}
      itemProps={(params) => ({
        children: (
          <StatusChip
            sx={{ zIndex: 100 }}
            statusId={params.value}
            dropDown
            fullWidth
          />
        ),
        sx: { display: params.isSelected ? "none" : undefined },
      })}
    >
      <StatusChip statusId={statusId} dropDown includeChevron />
    </DropDownButton>
  );
}

function SessionStatusChip(
  props: StatusChipProps & DropDownProps & SessionStatusProps,
) {
  const { statusId, allowNoShow, id } = props;
  const { getStatus } = useStatusesList("session");
  const status = getStatus(statusId);
  const [updateSessionStatus] = useUpdateSessionStatusMutation();
  const [markNoShow] = useMarkSessionNoShowMutation();
  if (!status) return null;
  return (
    <StatusChipDropDown
      {...props}
      handleStatusChange={(item) => {
        if (item.internal_name !== "no_show") {
          updateSessionStatus({ id, body: { status_id: item.id } });
        } else if (allowNoShow) {
          markNoShow({
            id,
          });
        }
      }}
    />
  );
}

function TaskStatusChip(
  props: StatusChipProps & DropDownProps & TaskStatusProps,
) {
  const [updateTaskStatus] = useUpdateTaskStatusMutation();
  const { orgId, id } = props;
  return (
    <StatusChipDropDown
      {...props}
      handleStatusChange={(item) => {
        updateTaskStatus({
          id,
          orgId,
          body: { status_id: item.id },
        });
      }}
    />
  );
}
