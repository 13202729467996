import {
  Stack,
  Typography,
  FormGroup,
  FormControlLabel,
  Divider,
  useTheme,
  Box,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import {
  GetMyPreferencesApiResponse,
  useUpdateMyPreferencesMutation,
} from "../../state/rtk-query/state/preferences";
import { useOrgId } from "../hooks";
import { CardStyled, StyledSwitch } from "../styled";
import { useMobile, useTablet } from "../../themes";

interface PrefenceCardContainerProps {
  children: ReactNode;
  title: string;
}
export const PreferenceCardsContainer = ({
  children,
  title,
}: PrefenceCardContainerProps) => {
  const { palette } = useTheme();
  const isMobile = useMobile();
  return (
    <Stack
      sx={{
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: "6px",
        my: 2,
      }}
      direction={isMobile ? "column" : "row"}
      bgcolor={"#FFF"}
      p={isMobile ? 1 : 2}
      divider={
        isMobile ? (
          <Divider sx={{ width: "95%", alignSelf: "center" }} flexItem />
        ) : (
          <Divider
            orientation="vertical"
            sx={{ height: "95%", alignSelf: "center" }}
            flexItem
          />
        )
      }
    >
      <Box sx={{ p: 2, width: isMobile ? "100%" : "15%" }}>
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Stack pl={3} width={isMobile ? "100%" : "85%"} direction={"column"}>
        {children}
      </Stack>
    </Stack>
  );
};

export const PreferenceCard = ({
  name: title,
  description,
  value,
  preference_type_id,
}: GetMyPreferencesApiResponse[number]) => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isSmallerThanPc = isMobile || isTablet;

  const [activeLocal, setActiveLocal] = useState(value);
  const [updatePref] = useUpdateMyPreferencesMutation();
  const orgId = useOrgId();

  const updatePreferences = () => {
    if (preference_type_id) {
      updatePref({ orgId, id: preference_type_id });
    }
    setActiveLocal(!activeLocal);
  };

  useEffect(() => {
    if (value !== activeLocal) {
      setActiveLocal(!!value);
    }
  }, [value]);

  return (
    <CardStyled
      sx={{
        py: 2,
        px: isSmallerThanPc ? 0 : 2,
        border: "none",
        width: "100%",
      }}
    >
      {isSmallerThanPc ? (
        <Stack direction={"column"} width={"100%"}>
          <Stack
            direction={"row"}
            gap={2}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography fontWeight={600} fontSize={16} width={"100%"}>
              {title}
            </Typography>
            <FormGroup>
              <FormControlLabel
                control={
                  <StyledSwitch
                    sx={{ m: 1 }}
                    checked={activeLocal}
                    onClick={updatePreferences}
                  />
                }
                label=""
              />
            </FormGroup>
          </Stack>

          <Typography fontWeight={500} fontSize={14} lineHeight={1.5}>
            {description}
          </Typography>
        </Stack>
      ) : (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack direction={"column"}>
            <Typography fontWeight={600} fontSize={16} lineHeight={1.5}>
              {title}
            </Typography>
            <Typography fontWeight={500} fontSize={16} lineHeight={2}>
              {description}
            </Typography>
          </Stack>
          <FormGroup>
            <FormControlLabel
              control={
                <StyledSwitch
                  sx={{ m: 1 }}
                  checked={activeLocal}
                  onClick={updatePreferences}
                />
              }
              label=""
            />
          </FormGroup>
        </Stack>
      )}
    </CardStyled>
  );
};
