import {
  Option,
  Multiple,
  DropDownButton,
  MenuSearch,
  GetSetterType,
  useMenuItems,
} from "../../buttons/dropdown/index";
import { FormField } from "./FormField";
import { useState } from "react";
import { Select } from "formik-mui";
import { SmallDownIcon } from "./SmallDownIcon";
import { PropsOf } from "@emotion/react";

export namespace SelectField {
  export type Props<
    O extends Option,
    M extends boolean | undefined = undefined,
  > = FormField.FieldProps<typeof CustomSelect<O, M>>;
}

const MenuSx = {
  sx: { maxHeight: "50%", width: "100%" },
};

type CustomSelectProps<O extends Option, M extends Multiple = undefined> = Omit<
  DropDownButton.Props<O, M>,
  "selected" | "setSelected" | "children"
> &
  Omit<
    PropsOf<typeof Select>,
    keyof DropDownButton.Props<O, M> | "renderValue"
  > & { renderValue?: (value?: GetSetterType<O, M>) => React.ReactNode };

const sx = {
  "& > svg": {
    top: "calc(50% - .75em)",
  },
};

function CustomSelect<O extends Option, M extends Multiple = undefined>({
  menuProps,
  menuHeader,
  items,
  allowDeselection,
  multiple,
  rerenderKey,
  withSearch,
  itemProps,
  renderValue,
  RenderItem,
  additionalItems,
  capitalize,
  ...props
}: CustomSelectProps<O, M>) {
  const [open, setOpen] = useState(false as boolean | undefined);
  const {
    form: { setFieldValue, status, getFieldMeta },
    field: { name },
  } = props;
  const { value } = getFieldMeta<any>(name);

  const menuItems = useMenuItems(
    {
      items,
      allowDeselection: allowDeselection ?? !props.required,
      menuHeader,
      multiple,
      itemProps,
      menuProps,
      rerenderKey,
      withSearch,
      selected: value,
      setSelected: (val) => {
        setFieldValue(name, val || "");
      },
      RenderItem,
      additionalItems,
      capitalize,
    },
    setOpen,
  );

  const isDisabled = status === "disabled" || props.disabled || props.readOnly;

  return (
    <Select
      onClose={() => {
        setOpen(false);
      }}
      open={!!open}
      onClick={() => {
        setOpen(!isDisabled);
      }}
      MenuProps={{
        ...menuProps,
        sx: {
          ...MenuSx?.sx,
          ...menuProps?.sx,
        },
        onClose: (e: any) => {
          e.preventDefault();
          e.stopPropagation();
          setOpen(false);
        },
        onClick: (e: any) => {
          e.preventDefault();
          e.stopPropagation();
        },
      }}
      IconComponent={isDisabled ? () => null : SmallDownIcon}
      {...props}
      disabled={status === "disabled" || props.disabled}
      renderValue={renderValue as any}
      sx={props.sx ? { ...sx, ...props.sx } : sx}
      multiple={multiple}
    >
      {!!menuHeader && menuHeader}
      {menuItems}
    </Select>
  );
}

function SelectWithSearch<O extends Option, M extends Multiple = undefined>(
  props: CustomSelectProps<O, M>,
) {
  const [search, setSearch] = useState("");
  return (
    <CustomSelect
      {...props}
      menuHeader={
        <MenuSearch key={"search"} search={search} setSearch={setSearch} />
      }
      itemProps={(params, item) => {
        const hide = !params.name.toLowerCase().includes(search.toLowerCase());
        const iProps = props.itemProps && props.itemProps(params, item);
        return {
          ...iProps,
          rerenderKey: `${hide}${iProps?.rerenderKey}`,
          sx: { display: hide ? "none" : undefined, ...iProps?.sx },
        };
      }}
    />
  );
}

export function SelectField<O extends Option, M extends Multiple = undefined>(
  props: SelectField.Props<O, M>,
) {
  const select = props.withSearch ? SelectWithSearch : CustomSelect;
  if (!props.items || props.items.length === 0) {
    console.warn("props.items are empty not rendering SelectField");
    return null;
  }
  return <FormField {...(props as any)} component={select} />;
}
