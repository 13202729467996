import { Stack, Typography, Link, SxProps, IconButton } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useState } from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { RouterLink } from "../../lib";
import { HomepageSectionStyled } from "../../pages/main/shared";
import {
  useGetUpcomingSessionsQuery,
  useGetTodaysSessionsQuery,
} from "../../state/rtk-query/state/session";
import { MiniSessionCard } from "../cards";
import { useGetStatusId, useOrgId } from "../hooks";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { useMobile, useTablet } from "../../themes";

export const UpcomingSessionsSideBar = ({
  orgId: orgIdProp,
  sx,
}: {
  orgId?: number;
  sx?: SxProps;
}) => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isSmallerThanPc = isMobile || isTablet;
  const orgIdHook = useOrgId();
  const orgId = orgIdProp !== undefined ? orgIdProp : orgIdHook;

  const isInternal = useSelector(authSelectors.isInternal);
  const canMinimize = isInternal && !isSmallerThanPc;
  const [isMinimized, setIsMinimized] = useState(canMinimize);
  const upcomingStatus = useGetStatusId("upcoming");

  const displayTodays = isInternal && orgIdProp === undefined;
  const { currentData: upcomingSessions, isSuccess: upComingSuccess } =
    useGetUpcomingSessionsQuery(
      orgId
        ? {
            orgId,
          }
        : skipToken,
      { skip: displayTodays, refetchOnMountOrArgChange: true },
    );
  const { currentData: todaysSessions, isSuccess: todaySuccess } =
    useGetTodaysSessionsQuery(undefined, {
      skip: !displayTodays,
      refetchOnMountOrArgChange: true,
    });
  const isSuccess = todaySuccess || upComingSuccess;

  const sessionsToDisplay = displayTodays ? todaysSessions : upcomingSessions;
  const hasSessions = sessionsToDisplay?.rows?.length && isSuccess;
  const sessionCount = sessionsToDisplay?.rows?.length || 0;
  const toggleMinimized = () => setIsMinimized(!isMinimized && canMinimize);
  const ArrowIcon = (
    <IconButton onClick={toggleMinimized} size="small" disableRipple>
      <ArrowRightAltIcon
        sx={{
          transform: isMinimized ? "rotate(180deg)" : undefined,
          fontSize: 22,
        }}
      />
    </IconButton>
  );
  if (isMinimized) {
    return (
      <HomepageSectionStyled
        sx={{
          writingMode: "vertical-rl",
          textOrientation: "mixed",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "min-content",
          padding: 0,
          pt: 4,
          flexDirection: "row",
          minWidth: "32px",
          ...sx,
        }}
      >
        {ArrowIcon}
        <Typography
          variant="h3"
          fontSize={24}
          mt={0.5}
          sx={{ cursor: "pointer" }}
          onClick={toggleMinimized}
        >
          {displayTodays ? "Today's Sessions" : "Upcoming Sessions"}:&nbsp;
          {sessionCount}
        </Typography>
      </HomepageSectionStyled>
    );
  }
  return (
    <HomepageSectionStyled
      sx={{
        pr: "auto",
        ...sx,
        minWidth: "fit-content",
        width: isSmallerThanPc ? "100%" : "25%",
      }}
    >
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {canMinimize && ArrowIcon}
        <Stack
          direction={isSmallerThanPc ? "column" : "row"}
          justifyContent={"space-between"}
          alignItems={isSmallerThanPc ? "flex-start" : "center"}
          width={"100%"}
          gap={!isSmallerThanPc ? 1 : 0}
          mt={1}
        >
          <Typography variant="h2" fontSize={24}>
            {displayTodays ? "Today's sessions" : "Upcoming sessions"}
          </Typography>
          {hasSessions || isInternal ? (
            <Link
              component={RouterLink}
              to={
                displayTodays
                  ? `/sessions?sessionFilterMyOrgs=true&sessionStatuses[]=${upcomingStatus}`
                  : isInternal
                  ? `/organizations/${orgId}?tab=sessions`
                  : "/sessions"
              }
              fontSize={14}
              fontWeight={600}
              pl={isSmallerThanPc ? 0.5 : 0}
            >
              View all{displayTodays ? " upcoming" : ""}
            </Link>
          ) : null}
        </Stack>
        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={hasSessions ? "center" : "flex-start"}
          my={3}
          gap={2}
          width={"100%"}
        >
          {hasSessions ? (
            sessionsToDisplay.rows
              .slice(0, isInternal ? sessionsToDisplay?.rows?.length : 4)
              .map((s) => {
                return (
                  s.id && (
                    <MiniSessionCard
                      key={s.id}
                      session={s}
                      displayTodays={displayTodays}
                    />
                  )
                );
              })
          ) : isSuccess ? (
            <Typography variant="h4" mt={2} color={"GrayText"}>
              {displayTodays
                ? "You have no sessions for today."
                : "You have no upcoming sessions."}
            </Typography>
          ) : null}
        </Stack>
      </Stack>
    </HomepageSectionStyled>
  );
};
