import tippy from "tippy.js";
import MentionList from "./MentionList.js";
import { ReactRenderer } from "@tiptap/react";

export const suggestionRender = (
  suggestions?: string[] | { id: number | string; name: string }[],
) => ({
  items: ({ query }: { query: string }) => {
    if (!suggestions?.length) return [];
    return suggestions.filter((item) => {
      const name = typeof item === "string" ? item : item?.name;
      return name?.toLowerCase().startsWith(query.toLowerCase());
    });
  },

  render: () => {
    let component: any;
    let popup: any;

    return {
      onStart: (props: Record<string, any>) => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
        });
      },

      onUpdate(props: Record<string, any>) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        // Check if the popup instance is still active before updating
        if (popup && popup[0] && !popup[0].state.isDestroyed) {
          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        }
      },

      onKeyDown(props: Record<string, any>) {
        if (props.event.key === "Escape") {
          if (popup && popup[0] && !popup[0].state.isDestroyed) {
            popup[0].hide();
          }
          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        // Ensure proper cleanup and avoid double-destroying
        if (popup && popup[0] && !popup[0].state.isDestroyed) {
          popup[0].destroy();
        }
        component.destroy();
      },
    };
  },
});
