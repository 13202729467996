import { Box, Button, ButtonProps, Typography, useTheme } from "@mui/material";
import { ListOption } from "./types";
import { useMobile } from "../../../themes";
import { ChevronDown } from "../../icons";
import { capitalizeSentence } from "../../../lib";

export namespace TitleWithValues {
  export type Props = {
    title: string;
    value?: string[] | number[] | string | number;
    getName?: (val?: number) => ListOption | undefined;
    buttonProps?: ButtonProps;
    capitalize?: boolean;
  };
}

function getTitleWithValues(
  title: string,
  values: (string | number)[],
  getName?: (val?: number | string) => ListOption | undefined,
  capitalize?: boolean,
) {
  const first = getName ? getName(values[0])?.name : values[0];
  if (!first) {
    return title;
  }
  const tWithValName = `${title}: ${
    capitalize && typeof first === "string"
      ? capitalizeSentence(first.split("_").join(" "))
      : first
  }`;
  if (values.length === 1) {
    return tWithValName;
  }
  return `${tWithValName} +${values.length - 1}`;
}

export function TitleWithValues({
  title,
  getName,
  value,
  buttonProps,
  capitalize,
}: TitleWithValues.Props) {
  const { palette } = useTheme();
  const isMobile = useMobile();
  const values = Array.isArray(value) ? value : value ? [value] : [];
  const titleWithVal = getTitleWithValues(
    title,
    values,
    getName as (val?: number | string) => ListOption | undefined,
    capitalize,
  );

  return (
    <Button
      color="black"
      {...buttonProps}
      sx={{
        width: "fit-content",
        height: "max-content",
        ...buttonProps?.sx,
      }}
    >
      <Typography
        fontSize={isMobile ? 14 : 16}
        width={"100%"}
        fontWeight={500}
        color={values.length ? "primary" : "inherit"}
        display={"flex"}
        alignItems={"center"}
        noWrap
      >
        <Box component={"span"}>{titleWithVal}</Box>
        <ChevronDown color={palette.primary.main} />
      </Typography>
    </Button>
  );
}
