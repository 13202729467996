import { MenuItem } from "@mui/material";
import {
  Option,
  Multiple,
  GetIdType,
  SetParallelSelected,
  MenuItemProps,
  Close,
} from "./types";
import { useMemo } from "react";
import { DefaultItem } from "./DefaultItem";

export namespace DropDownItem {
  export type Props<O extends Option, M extends Multiple> = {
    value: GetIdType<O>;
    name: string;
    deleted_at?: any;
    rerenderKey?: any;
    isSelected?: boolean;
    allowDeselection?: boolean;
    multiple?: M;
    setSelected?: SetParallelSelected<O, M>;
    close?: Close;
    showDeleted?: boolean;
    displayNone?: boolean;
    capitalize?: boolean;
  } & MenuItemProps;
}

export function DropDownItem<O extends Option, M extends Multiple = undefined>({
  value,
  name,
  deleted_at,
  rerenderKey,
  isSelected,
  setSelected,
  close,
  multiple,
  allowDeselection = true,
  showDeleted,
  displayNone,
  capitalize,
  ...props
}: DropDownItem.Props<O, M>) {
  const id = (props as any)["data-value"] ?? value;
  const Item = useMemo(() => {
    return (
      <MenuItem
        {...props}
        selected={isSelected}
        aria-selected={isSelected ? "true" : "false"}
        value={id}
        onClick={
          setSelected
            ? (e) => {
                if (!multiple && close) {
                  close(() => undefined);
                }
                e.preventDefault();
                e.stopPropagation();
                setSelected((prev) => {
                  if (!prev) {
                    return (multiple ? [id] : id) as typeof prev;
                  }
                  if (isSelected) {
                    return (
                      Array.isArray(prev)
                        ? prev.filter((p) => p !== id)
                        : allowDeselection
                        ? undefined
                        : prev
                    ) as typeof prev;
                  }
                  return (
                    Array.isArray(prev) ? [...prev, id] : id
                  ) as typeof prev;
                });
              }
            : props.onClick
        }
        sx={
          (deleted_at && !showDeleted) || displayNone
            ? { ...props.sx, display: "none" }
            : props.sx
        }
      >
        <DefaultItem
          withCheckbox={multiple}
          checked={isSelected}
          label={name}
          capitalize={capitalize}
        >
          {props.children !== name && props.children}
        </DefaultItem>
      </MenuItem>
    );
  }, [
    rerenderKey,
    id,
    name,
    deleted_at,
    isSelected,
    setSelected,
    close,
    multiple,
    allowDeselection,
    !!showDeleted,
    !!displayNone,
    !!capitalize,
  ]);
  return Item;
}
