import { useDispatch, useSelector } from "react-redux";
import { clientSelectors, clientActions, authSelectors } from "../../state";
import { useEffect, useState } from "react";
import {
  GetTaskApiResponse,
  GetTaskChatsApiResponse,
} from "../../state/rtk-query/state/tasks";

type Params = {
  task: GetTaskApiResponse | undefined;
  chat?: GetTaskChatsApiResponse["rows"][number];
};

export function useDraftChats({ task, chat }: Params) {
  const dispatch = useDispatch();
  const inImpersonation = useSelector(authSelectors.inImpersonation);
  const draftChats = useSelector(clientSelectors.draftChats);
  const taskId = task?.id || 0;
  const draftChat =
    chat || inImpersonation
      ? undefined
      : Object.hasOwn(draftChats, taskId)
      ? draftChats[taskId]
      : undefined;
  const initialMessage = chat?.message || draftChat?.message || "";
  const initialAttachments = chat ? [] : draftChat?.attachments || [];
  const initialIsInternal =
    chat?.is_internal ??
    draftChat?.is_internal ??
    task?.type === "internal_task";
  const [message, setMessage] = useState(initialMessage);
  const [attachments, setAttachments] = useState(initialAttachments);
  const [isInternalComment, setIsInternalComment] = useState(initialIsInternal);

  useEffect(() => {
    if (!chat && !inImpersonation && taskId) {
      dispatch(
        clientActions.setDraftChat({
          id: taskId,
          chat:
            message || attachments?.length
              ? {
                  message,
                  attachments,
                  is_internal: isInternalComment,
                  saved_at: Date.now(),
                }
              : undefined,
        }),
      );
    }
  }, [message, attachments, isInternalComment, task, chat]);

  return {
    message,
    setMessage,
    attachments,
    setAttachments,
    isInternalComment,
    setIsInternalComment,
  };
}
