import { JSXElementConstructor, useEffect } from "react";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { VITE_SITE_TITLE } from "../../config";
import { useSelector } from "react-redux";
import { authSelectors, redirectToLogin } from "../../state";
import { useLocation } from "react-router-dom";
import { Navigation } from ".";
import { RolesGroup, validateRoles } from "..";

export interface PageProps {
  anon: boolean;
  path: string;
  title: string;
  roles?: RolesGroup;
  type?: string;
  view: JSXElementConstructor<any>;
}

function Page(props: PageProps) {
  const { view: Component, title, anon, roles: roleGroup, ...rest } = props;
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const rolesNames = useSelector(authSelectors.rolesNames);
  const location = useLocation();
  useEffect(() => {
    if (anon === false && !isLoggedIn) {
      redirectToLogin();
    } else if (!validateRoles(rolesNames, roleGroup)) {
      //TODO where to redirect
      Navigation.go("/");
    }
  }, [anon, isLoggedIn, location.pathname, location.search, rolesNames]);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {title} | {VITE_SITE_TITLE}
        </title>
      </Helmet>
      <Component {...rest} />
    </>
  );
}

export function renderPageRoutes(pages: any) {
  if (!pages) {
    throw new Error("Missing pages: " + typeof pages);
  }
  return Object.keys(pages).map((pageName) => {
    const { path, ...rest } = pages[pageName];
    if (!rest.title) {
      throw new Error("Page missing title");
    }
    return (
      <Route
        {...rest}
        key={path}
        // exact={pathExact}
        path={path}
        element={<Page {...rest} />}
      />
    );
  });
}
