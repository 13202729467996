import { CheckboxWithLabel } from "formik-mui";
import { FormField } from "./FormField";
import { PropsOf } from "@emotion/react";

export namespace CheckboxField {
  export type Props = FormField.FieldProps<typeof CustomCheckbox>;
}
function CustomCheckbox(
  props: Omit<PropsOf<typeof CheckboxWithLabel>, "Label"> &
    (
      | {
          Label: PropsOf<typeof CheckboxWithLabel>["Label"];
          label?: string;
        }
      | {
          label: string;
          Label?: PropsOf<typeof CheckboxWithLabel>["Label"];
        }
    ),
) {
  const {
    form: { status },
  } = props;

  const Label =
    "label" in props
      ? {
          label: props.label,
        }
      : props.Label;

  return (
    <CheckboxWithLabel
      disabled={status === "disabled"}
      disableRipple
      type="checkbox"
      {...props}
      sx={{ alignItems: "flex-start", ...props.sx }}
      Label={Label}
    />
  );
}

export function CheckboxField(props: CheckboxField.Props) {
  return (
    <FormField
      component={CustomCheckbox}
      disableRipple
      type="checkbox"
      sx={{ alignItems: "flex-start" }}
      {...props}
    />
  );
}
