import { Box, Menu, MenuProps, Popover, PopoverProps } from "@mui/material";

export namespace DropDownShell {
  export type Props = (
    | ({ type: "menu" } & Partial<MenuProps>)
    | ({ type: "popover" } & Partial<PopoverProps>)
  ) & {
    button?: React.ReactNode;
    children?: React.ReactNode;
    anchorEl: MenuProps["anchorEl"];
    setAnchorEl: React.Dispatch<
      React.SetStateAction<HTMLElement | null | undefined>
    >;
  };
}

const anchorOrigin = { vertical: "bottom", horizontal: "left" } as const;
const transformOrigin = {
  vertical: "top",
  horizontal: "left",
} as const;

export function DropDownShell({
  type,
  children,
  anchorEl,
  setAnchorEl,
  ...props
}: DropDownShell.Props) {
  const baseProps = {
    ...props,
    open: !!anchorEl,
    anchorEl,
    onClose: () => setAnchorEl(null),
    anchorOrigin,
    transformOrigin,
    children,
  };

  const box = (
    <Box
      width={"min-content"}
      height={"min-content"}
      aria-expanded={anchorEl ? "true" : undefined}
      onClick={(e) => {
        if (!anchorEl) {
          e.stopPropagation();
          e.preventDefault();
          setAnchorEl(e.currentTarget);
        }
      }}
      sx={{
        cursor: "pointer",
      }}
    >
      {!!props.button && props.button}
    </Box>
  );

  if (type === "popover") {
    return (
      <>
        {box}
        <Popover {...baseProps} />
      </>
    );
  }
  return (
    <>
      {box}
      <Menu
        {...baseProps}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        sx={{
          fontWeight: 500,
          fontSize: 14,
          display: "flex",
          alignItems: "baseline",
          mt: 1,
          ...props?.sx,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
          ...props?.anchorOrigin,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
          ...props?.transformOrigin,
        }}
      />
    </>
  );
}
