import { Stack } from "@mui/material";
import { UpcomingSessionsSideBar, TasksTableInternal } from "../../components";
import { useMobile, useTablet } from "../../themes";
import { TaskStats } from "../misc";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";

export const OrgDashboardView = ({
  isNewAdmire,
}: {
  isNewAdmire?: boolean;
}) => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isSmallerThanPc = isTablet || isMobile;
  const orgId = +useParams().id!;
  const isCW = useSelector(authSelectors.isCustomWorkUser);

  return (
    <Stack
      direction={isSmallerThanPc ? "column" : "row"}
      id="homepage-container"
      flexGrow={1}
      minHeight={0}
      minWidth={0}
    >
      <Stack
        gap={2}
        pb={2}
        minWidth={0}
        flexGrow={1}
        minHeight={0}
        overflow={"auto"}
      >
        <Stack
          direction={isSmallerThanPc ? "column" : "row"}
          justifyContent={"space-between"}
          width="100%"
          height={"auto"}
        >
          <TaskStats orgId={orgId} isNewAdmire={isNewAdmire} />
        </Stack>
        {!isSmallerThanPc && (
          <Stack minHeight={0} minWidth={0} flexGrow={1}>
            <TasksTableInternal
              height={"auto"}
              //minHeight="500px"
              orgId={orgId}
              isPageSubSection={true}
            />
          </Stack>
        )}
      </Stack>

      {!isCW && <UpcomingSessionsSideBar orgId={orgId} sx={{ pt: 0 }} />}

      {isSmallerThanPc && (
        <Stack height="100%">
          <TasksTableInternal
            height="100%"
            orgId={orgId}
            isPageSubSection={true}
          />
        </Stack>
      )}
    </Stack>
  );
};
