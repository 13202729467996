import { FormControlLabel } from "@mui/material";
import { CustomCheckBox } from "../../styled";
import { capitalizeSentence } from "../../../lib";

export namespace DefaultItem {
  export type Props = {
    children?: React.ReactNode;
    label: string;
    checked?: boolean;
    withCheckbox?: boolean;
    capitalize?: boolean;
  };
}

export function DefaultItem({
  label,
  children,
  checked,
  withCheckbox,
  capitalize,
}: DefaultItem.Props) {
  if (children) {
    return children;
  }
  if (capitalize) {
    label = capitalizeSentence(label.split("_").join(" "));
  }
  if (withCheckbox) {
    return (
      <FormControlLabel
        control={<CustomCheckBox checked={!!checked} />}
        label={label}
      />
    );
  }
  return label;
}
