import {
  CardContentContainerStyled,
  CardContentItemStyled,
  CardStyled,
} from "../styled";
import { useMobile } from "../../themes";
import { DisplayUsage } from "./DisplayUsage";

interface Props {
  hoursUsed: number;
  hoursAvailable: number;
  hoursAverage: number;
  suggestedAvg: number;
  isNewAdmire?: boolean;
}
export const TermUsageCard = ({
  hoursUsed,
  hoursAvailable,
  hoursAverage,
  suggestedAvg,
  isNewAdmire,
}: Props) => {
  const isMobile = useMobile();

  return (
    <CardStyled
      sx={{
        my: 1,
        minHeight: "75px",
        overflow: "auto",
      }}
    >
      <CardContentContainerStyled container minHeight={"100%"}>
        <CardContentItemStyled
          item
          display={"flex"}
          justifyContent={isMobile ? "flex-start" : "space-between"}
          px={2}
          py={isMobile ? 1 : 2}
          alignItems={"flex-start"}
          flexDirection={isMobile ? "column" : "row"}
          gap={1}
          height={"100%"}
          minHeight={"100%"}
        >
          <DisplayUsage
            count={hoursUsed}
            title="Already used"
            decimalPlaces={1}
          />
          {!isNewAdmire && (
            <DisplayUsage
              count={hoursAvailable}
              title="Available"
              decimalPlaces={1}
            />
          )}
          <DisplayUsage count={hoursAverage} title={"Average monthly usage"} />
          {!isNewAdmire && (
            <DisplayUsage
              count={Number(Number(suggestedAvg).toFixed(2))}
              title={"Suggested average"}
            />
          )}
        </CardContentItemStyled>
      </CardContentContainerStyled>
    </CardStyled>
  );
};
