import { DropDownButton } from "./DropDownButton";
import { TitleWithValues } from "./TitleWithValues";
import { GetIdType, ListOption, Multiple, Option } from "./types";

export namespace FilterButton {
  export type Props<
    O extends Option,
    M extends Multiple,
  > = DropDownButton.Props<O, M> &
    (O extends string
      ? { getItem?: (val?: number) => ListOption | undefined }
      : {
          getItem: (val?: GetIdType<O>) => ListOption | undefined;
        }) & {
      children: string;
    };
}

const anchorOrigin = { vertical: "bottom", horizontal: "left" } as const;
const transformOrigin = {
  vertical: "top",
  horizontal: "left",
} as const;

export function FilterButton<O extends Option, M extends Multiple = undefined>(
  props: FilterButton.Props<O, M>,
) {
  const { children, getItem, ...rest } = props;
  const { selected } = rest;
  return (
    <DropDownButton
      {...rest}
      menuProps={{ anchorOrigin, transformOrigin, ...rest.menuProps }}
    >
      <TitleWithValues
        title={children}
        value={selected as string[] | number[] | string | number | undefined}
        getName={getItem}
        capitalize={props.capitalize}
      />
    </DropDownButton>
  );
}
