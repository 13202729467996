import { differenceInSeconds } from "date-fns";
import {
  useDeleteTimerMutation,
  useGetMyActiveTimerQuery,
  useUpdateTimerMutation,
} from "../../../state/rtk-query/state/timer";
import {
  Alert,
  Box,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  CancelIcon,
  ChevronDown,
  EllipsisTypographyStyled,
  PlayCircleIcon,
  RestartIcon,
  StopIcon,
  TimerButton,
  TimerIcon,
  WarningIcon,
  StopwatchSlashIcon,
} from "../../../components";
import {
  authSelectors,
  clientSelectors,
  timerActions,
  timerSelectors,
} from "../../../state";
import { useCallback, useEffect, useMemo } from "react";
import { RouterLink, isDate, useLocation } from "../../../lib";
import { MainPages } from "../../../pages";
import { useMobile } from "../../../themes";
import {
  useActivityTypesList,
  useOrgsList,
} from "../../../components/hooks/useLists";
import {
  useClock,
  useMemoObj,
  useOpenTaskDrawer,
  useTimerActions,
  useTimerListener,
} from "../../../components/hooks";
import { matchPath, useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";

const TIMER_SWITCHING_COUNTDOWN = 5;

export const TimerBar = () => {
  const nav = useNavigate();
  const isMobile = useMobile();
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { pathname, query } = useLocation();
  const { activityTypes, getActivityTypes } = useActivityTypesList();
  const isInternal = useSelector(authSelectors.isInternal);
  const isCW = useSelector(authSelectors.isCustomWorkUser);
  const { getOrg } = useOrgsList(!isInternal);
  const openTaskDrawer = useOpenTaskDrawer();
  const {
    id: timerId,
    orgId: timerOrgId,
    state: timerState,
    stayOnOrgId,
    cancelledSwitch,
    inferredOrgId,
    midSwitch,
    customWork,
  } = useSelector(timerSelectors.currentTimer) ?? {};

  const [updateTimer] = useUpdateTimerMutation();

  const [deleteTimer] = useDeleteTimerMutation(); // confirm cancel === delete
  const {
    restartTimer: restart,
    startTimer,
    stopTimer,
  } = useTimerActions() ?? {};
  const inferredOrg =
    (inferredOrgId && getOrg(Number(inferredOrgId ?? 0))) ?? 0;

  const { data, isSuccess } = useGetMyActiveTimerQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    skip: !isInternal,
  });

  const isRunning = !!(
    ["active", "switching"].includes(timerState ?? "") &&
    ((data?.start_time && !data?.end_time) || !data || midSwitch)
  );
  const { time, setTime } = useClock({
    direction: "up",
    start: isRunning,
  });
  const {
    time: switchingCount,
    setTime: setSwitchingCount,
    stopClock: clearSwitchTimer,
  } = useClock({
    direction: "down",
    start: !!(
      timerOrgId &&
      inferredOrgId &&
      timerOrgId !== inferredOrgId &&
      !cancelledSwitch
    ),
    defaultCount: TIMER_SWITCHING_COUNTDOWN,
  });

  const stopCurrentTimer = async () => {
    await stopTimer();
  };
  const cancelCurrentTimer = async () => {
    const hasTimerId = timerId || data?.id;
    if (hasTimerId) {
      await deleteTimer({ id: hasTimerId });
      dispatch(timerActions.setTimerStateAction("stopped"));
    }
  };
  const restartTimer = async () => {
    if (restart) {
      await restart();
      setTime(0);
    }
  };
  /** Sync DB with Frontend Start*/
  useEffect(() => {
    if (
      data &&
      data.id !== 0 &&
      data.id !== timerId &&
      timerState !== "stopped"
    ) {
      dispatch(
        timerActions.setTimerAction({
          state: "active",
          id: data.id,
          orgId: data?.org_id,
          inferredOrgId,
        }),
      );
    }
  }, [data?.id]);
  useEffect(() => {
    if (data && data?.start_time && isDate(data?.start_time)) {
      setTime((old) => {
        const timeDif =
          data?.start_time &&
          differenceInSeconds(new Date(), new Date(data?.start_time));
        // if the difference between the actual time and the state time is more than 5 seconds update the time
        if (!data?.start_time || (timeDif && Math.abs(timeDif - time) <= 5)) {
          return old + 1;
        }

        return differenceInSeconds(new Date(), new Date(data?.start_time)) + 1;
      });
    }
  }, [data?.start_time, document.visibilityState]);
  /** Sync DB with Frontend End */

  /** Handle "switch" state, other states are handled in useTimerListener.ts */

  useEffect(() => {
    (async () => {
      if (
        switchingCount === 0 &&
        timerState === "switching" &&
        inferredOrgId &&
        !cancelledSwitch &&
        timerId
      ) {
        await restartTimer();
        clearSwitchTimer();
        setSwitchingCount(TIMER_SWITCHING_COUNTDOWN);
      } else if (switchingCount < 0) {
        setSwitchingCount(0);
      }
    })();
  }, [switchingCount, timerState, inferredOrgId, cancelledSwitch]);

  useEffect(() => {
    setSwitchingCount(TIMER_SWITCHING_COUNTDOWN);
  }, [inferredOrgId]);

  useEffect(() => {
    // if use navigated while countdown is running
    if (
      timerState === "switching" &&
      switchingCount < TIMER_SWITCHING_COUNTDOWN
    ) {
      clearSwitchTimer();
      setSwitchingCount(-1);
      dispatch(timerActions.setTimerInferredOrgIdAction());
    }
  }, [location.pathname]);

  const stayOnCurrentOrg = useCallback(async () => {
    clearSwitchTimer();
    dispatch(timerActions.setTimerStateAction("active"));
    await dispatch(timerActions.setTimerCancelledSwitchAction(timerOrgId));
    setSwitchingCount(0);
  }, [inferredOrgId]);

  const switchToNextOrg = useCallback(async () => {
    await restartTimer();
    clearSwitchTimer();
    setSwitchingCount(TIMER_SWITCHING_COUNTDOWN);
  }, [inferredOrgId]);
  /** Handle "switch" state end */

  useTimerListener();

  const hours = `${Math.floor(time / 3600)}`.padStart(2, "0");
  const minutes = `${Math.floor((time % 3600) / 60)}`.padStart(2, "0");
  const seconds = `${Math.floor(time % 60)}`.padStart(2, "0");

  const displayCW =
    isCW &&
    timerState === "active" &&
    customWork?.taskCode &&
    customWork?.taskId &&
    customWork.taskTitle;

  const bgColor = useMemo(() => {
    return cancelledSwitch
      ? palette.orange[600]
      : isRunning
      ? pathname ===
          MainPages(true).organization.path.replace(
            ":id",
            String(timerOrgId),
          ) ||
        (timerOrgId === inferredOrgId &&
          (matchPath(MainPages(true).session.path, pathname) || query?.task)) ||
        displayCW
        ? palette.primary.main
        : palette.warning[600]
      : palette.error[700];
  }, [
    cancelledSwitch,
    isRunning,
    pathname,
    midSwitch,
    timerOrgId,
    inferredOrgId,
    query?.task,
  ]);

  if (!isInternal) return null;

  const iconProps = useMemoObj({
    size: 18,
    style: { marginRight: "4px" },
  });

  const handleChange = useCallback(
    async (event: SelectChangeEvent<unknown>) => {
      const org = cancelledSwitch && stayOnOrgId ? stayOnOrgId : timerOrgId;

      if (timerId && org && getActivityTypes(Number(event.target.value ?? 0))) {
        await updateTimer({
          id: timerId,
          body: {
            end_time: null,
            activity_type_id: Number(event.target.value),
            org_id: org,
          },
        });
      }
    },
    [
      data?.activity_type_id,
      timerId,
      timerOrgId,
      timerState,
      stayOnOrgId,
      inferredOrgId,
      cancelledSwitch,
      data,
    ],
  );

  const displaySwitchingMsg =
    timerState === "switching" &&
    inferredOrg &&
    inferredOrgId !== timerOrgId &&
    switchingCount > 0;

  const timeEntriesPage = useMemo(() => MainPages(true).timer.path, []);

  const navToTimerEntries = useCallback(
    () => (isMobile ? nav(timeEntriesPage) : null),
    [isMobile],
  );

  const openTask = useCallback(() => {
    if (displayCW) {
      openTaskDrawer(customWork.taskId);
    }
  }, [displayCW, location, customWork?.taskId]);

  const isDrawer = useSelector(clientSelectors.taskPreference) === "drawer";

  const dataOrg = getOrg(data?.org_id);
  const stopAndEditCurrentTimer = async () => {
    await stopTimer();
    window.open(
      `${MainPages(true).timer.path}?timerId=${timerId}&open=true&mode=edit`,
    );
  };
  return (
    <Stack
      direction={"row"}
      alignItems={isMobile ? "flex-start" : "center"}
      justifyContent={"space-between"}
      position={"sticky"}
      top={0}
      bgcolor={bgColor}
      color={"#FFF"}
      py={isMobile ? 1 : 0}
      px={isMobile ? 1 : 2}
      sx={(theme) => ({
        zIndex:
          Number(
            isDrawer ? theme.zIndex.drawer ?? 0 : theme.zIndex.modal ?? 0,
          ) + Number(timerId && timerState === "active" ? 3 : 1), // on top of the drawer if there is a timer running
        transition: theme.transitions.create(["height", "display"], {
          duration: theme.transitions.duration.enteringScreen,
        }),
        height: !isSuccess ? 0 : isMobile ? "100%" : "48px",
        display: !isSuccess ? "none !important" : "flex !important",
      })}
      gap={2}
    >
      <Stack
        direction={"row"}
        alignItems={isMobile ? "baseline" : "center"}
        gap={1}
        justifyContent={isMobile ? "space-between" : "flex-start"}
        width={isMobile ? "100%" : "auto"}
      >
        <Stack
          direction={"row"}
          alignItems={isMobile ? "flex-start" : "center"}
          onClick={navToTimerEntries}
          width={"100%"}
          maxWidth={isMobile ? "60%" : "100%"}
        >
          <TimerIcon size={isMobile ? 18 : 20} style={{ flexShrink: 0 }} />
          <Typography
            fontSize={16}
            fontWeight={600}
            pl={1}
            width={"100%"}
            whiteSpace={"nowrap"}
            sx={{
              textOverflow: isMobile ? "ellipsis" : "-moz-initial",
              overflow: isMobile ? "hidden" : "initial",
              whiteSpace: "nowrap",
            }}
          >
            {data?.org_id === -1
              ? "Admire"
              : dataOrg?.name || (inferredOrg && inferredOrg?.name) || ""}
          </Typography>
          <Typography fontSize={16} fontWeight={600} pl={1} width={"85px"}>
            {isRunning ? `${hours}:${minutes}:${seconds}` : "00:00:00"}
          </Typography>
        </Stack>

        <Stack
          direction={isMobile ? "column" : "row"}
          alignItems={isMobile ? "flex-start" : "center"}
        >
          {isRunning ? (
            <>
              {!isMobile ? (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  mx={isMobile ? 0 : 2}
                >
                  <Select
                    label="activity"
                    value={data?.activity_type_id ?? ""}
                    onChange={handleChange}
                    autoWidth
                    IconComponent={ChevronDown}
                    disableUnderline
                    variant="standard"
                    defaultValue={"activity"}
                    displayEmpty
                    renderValue={(v: any) => {
                      return (
                        <Typography
                          pl={1}
                          pt={0.25}
                          fontWeight={600}
                          sx={{ display: "flex", alignItems: "baseline" }}
                        >
                          {typeof v === "string" && v?.trim() === ""
                            ? "Select an activity" // what's default
                            : getActivityTypes(Number(v ?? 0))?.name}
                        </Typography>
                      );
                    }}
                    sx={{
                      "& > svg": {
                        top: ".5em",
                        color: "#FFF !important",
                      },
                      "& .MuiSelect-select": {
                        backgroundColor: "transparent !important",
                      },
                      "& .MuiInputBase-input": {
                        fontWeight: 600,
                        fontSize: 16,
                      },
                      "& .MuiSelect-select[aria-expanded='true']": {
                        backgroundColor: "rgba(255,255,255,0.12) !important",
                        borderRadius: 2,
                      },
                      color: "#FFF",
                    }}
                  >
                    {activityTypes?.map((at) => {
                      return (
                        <MenuItem
                          key={at.id}
                          value={at.id}
                          sx={{ fontWeight: 600, fontSize: 16 }}
                        >
                          {at.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {displayCW ? (
                    <EllipsisTypographyStyled
                      onClick={openTask}
                      width={"100%"}
                      fontWeight={600}
                      fontSize={16}
                      ml={2}
                      maxWidth={"400px"}
                      sx={{ cursor: "pointer" }}
                    >{`[${customWork.taskCode}] - ${customWork.taskTitle}`}</EllipsisTypographyStyled>
                  ) : null}
                </Stack>
              ) : null}
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={isMobile ? 0 : 2}
                mr={2}
                width={isMobile ? "100%" : "auto"}
                justifyContent={isMobile ? "flex-end" : "flex-start"}
              >
                <TimerButton
                  onClick={restartTimer}
                  Icon={RestartIcon}
                  text="Restart"
                />
                <TimerButton
                  onClick={stopCurrentTimer}
                  Icon={StopIcon}
                  text="Stop"
                />
                <TimerButton
                  Icon={StopwatchSlashIcon}
                  onClick={stopAndEditCurrentTimer}
                  sx={{ minWidth: "fit-content" }}
                  text="Stop and edit"
                />
                <TimerButton
                  onClick={cancelCurrentTimer}
                  Icon={CancelIcon}
                  text="Cancel"
                />
              </Stack>
            </>
          ) : (
            inferredOrgId && (
              <TimerButton
                onClick={() =>
                  startTimer
                    ? startTimer({ org_id: Number(inferredOrgId ?? -1) })
                    : null
                }
                Icon={PlayCircleIcon}
                text="Start"
              />
            )
          )}
        </Stack>
      </Stack>
      {!isMobile ? (
        <Stack
          direction={isMobile ? "column" : "row"}
          alignItems={isMobile ? "flex-start" : "center"}
          // on dashboard we hide start button
          justifyContent={displaySwitchingMsg ? "space-between" : "flex-end"}
          gap={2}
          mr={2}
          width={isMobile ? "100%" : "40%"}
        >
          {displaySwitchingMsg ? (
            <Stack
              direction={"row"}
              alignItems={isMobile ? "flex-start" : "center"}
              mr={isMobile ? 0 : 4}
              justifyContent={"space-between"}
              width={"max-content"}
              gap={1.5}
            >
              <WarningIcon {...iconProps} style={{ minWidth: "fit-content" }} />{" "}
              <Typography
                fontSize={16}
                fontWeight={600}
                ml={1}
                mr={2}
                whiteSpace={"nowrap"}
              >
                Switching to {inferredOrg?.name} in{" "}
                <Box
                  component={"span"}
                  sx={{
                    display: "inline-block",
                    width: "2ch", // 2 characters long (to prevent shifting of "stay" button)
                  }}
                >
                  {switchingCount}
                </Box>
              </Typography>
              <TimerButton onClick={stayOnCurrentOrg} text="Stay" />
              <TimerButton onClick={switchToNextOrg} text="Switch" />
            </Stack>
          ) : null}
          <Link
            to={timeEntriesPage}
            component={RouterLink}
            sx={{
              fontSize: "16px",
              fontWeight: 600,
            }}
            color={"#FFF"}
          >
            View entries
          </Link>
        </Stack>
      ) : null}
      {isMobile && displaySwitchingMsg
        ? createPortal(
            <Alert
              severity={"warning"}
              sx={(theme) => ({
                position: "absolute",
                bottom: 12,
                borderRadius: "6px",
                left: 0,
                right: 0,
                margin: "auto",
                lineHeight: "1.25em",
                my: 2,
                zIndex: theme.zIndex.drawer + Number(timerId ? 3 : 1), // on top of the drawer if there is a timer running
                width: "90%",
                backgroundColor: palette.warning[600],
                color: "#FFF",
                fontWeight: 600,
                "& > .MuiAlert-icon": {
                  color: "#FFF",
                },
              })}
              action={
                <Stack direction={"row"} alignItems={"center"} height={"100%"}>
                  <TimerButton onClick={stayOnCurrentOrg} text="Stay" />
                </Stack>
              }
            >
              Switching to {inferredOrg?.name} in {switchingCount}
            </Alert>,
            document.body,
          )
        : null}
    </Stack>
  );
};
