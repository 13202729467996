import { PropsOf } from "@emotion/react";
import { InputAdornment, TextField } from "@mui/material";
import {
  DateRange,
  DateRangePicker,
  DateTimePicker,
  SingleInputDateRangeField,
  ClockIcon,
  DatePicker as MUIDatePicker,
} from "@mui/x-date-pickers-pro";
import { FieldProps } from "formik";
import { useCallback, useMemo, useState } from "react";
import { CalendarBlankIcon } from "../../icons";
import { FormField } from "./FormField";
import { useMobile } from "../../../themes";

export function isValidDate(date: any | Date): date is Date {
  if (!(date instanceof Date)) return false;
  return !isNaN(new Date(date).getTime());
}

const slots = {
  field: SingleInputDateRangeField,
};

function DatePicker({
  field: { name, value },
  form: { setFieldValue, setFieldTouched, getFieldMeta, status },
  ...props
}: PropsOf<typeof DateRangePicker> & FieldProps) {
  const handleDateChange = useCallback(
    (newValue: DateRange<unknown>) => {
      if (isValidDate(newValue[1])) {
        const end_date = new Date(newValue[1]);
        end_date.setHours(23, 59, 59, 999);
        setFieldValue(name, [newValue[0], end_date], true);
      } else {
        setFieldValue(name, newValue, true);
      }
    },
    [name],
  );

  const { error, touched } = getFieldMeta(name);

  const slotProps = useMemo(() => {
    return {
      textField: {
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <CalendarBlankIcon />
            </InputAdornment>
          ),
        },
        error: touched && !!error,
        helperText: touched && error,
      },
    } as (typeof props)["slotProps"];
  }, [error, touched, value]);
  const onClose = () => {
    setTimeout(() => {
      setFieldTouched(name, true);
    }, 100);
  };
  return (
    <DateRangePicker
      slots={slots}
      slotProps={slotProps}
      value={value}
      onClose={onClose}
      format="M.d.yy"
      disabled={status === "disabled"}
      {...props}
      onChange={handleDateChange}
    />
  );
}

export namespace DatePickerField {
  export type Props = FormField.FieldProps<typeof DatePicker>;
}

export function DatePickerField({
  name,
  disablePast,
  ...props
}: DatePickerField.Props) {
  return (
    <FormField
      component={DatePicker}
      {...props}
      name={name}
      disablePast={disablePast}
    />
  );
}

function SingleDatePicker({
  field: { name, value },
  form: { setFieldValue, setFieldTouched, getFieldMeta, status },
  ...props
}: FieldProps &
  (
    | ({ withTime: true } & PropsOf<typeof DateTimePicker>)
    | ({ withTime?: false } & PropsOf<typeof MUIDatePicker>)
  )) {
  const isMobile = useMobile();
  const { touched, error } = getFieldMeta(name);
  const [open, setOpen] = useState(false);

  const handleDateChange = (newValue: unknown) => {
    setFieldValue(name, isValidDate(newValue) ? new Date(newValue) : null);
  };

  const onClick = useCallback(
    () =>
      setOpen((o) => {
        if (o && !touched) {
          setTimeout(() => {
            setFieldTouched(name, true);
          }, 100);
        }
        return !o;
      }),
    [setOpen, setFieldTouched, name],
  );

  const newProps = {
    value: isValidDate(value) ? new Date(value) : null,
    open,
    onClose: onClick,
    slots: {
      textField: TextField,
    },
    slotProps: {
      textField: {
        placeholder: "",
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <ClockIcon style={{ fontSize: isMobile ? "1rem" : ".8rem" }} />
            </InputAdornment>
          ),
        },
        error: Boolean(touched && error),
        helperText: touched && error ? <>{error}</> : "",
        onClick: onClick,
      },
      previousIconButton: {
        sx: { fontSize: "1em !important" },
      },
      nextIconButton: {
        sx: { fontSize: "1em !important" },
      },

      switchViewIcon: {
        sx: { fontSize: "1em !important" },
      },
    },
    onChange: handleDateChange,
    disabled: status === "disabled",
  };

  if (props.withTime) {
    const { withTime: _, ...rest } = props;
    return <DateTimePicker {...newProps} {...rest} />;
  }
  const { withTime: _, ...rest } = props;
  return <MUIDatePicker {...newProps} {...rest} />;
}

export function SingleDatePickerField(
  props: FormField.FieldProps<typeof SingleDatePicker>,
) {
  return <FormField component={SingleDatePicker} {...props} />;
}
