import { useLocation } from "../../lib";
import { useGetTimerQuery } from "../../state/rtk-query/state/timer";
import { skipToken } from "@reduxjs/toolkit/query";
import { BaseTimeEntryModal } from "./BaseTimerModal";
interface Props {
  open: boolean;
  onClose: () => void;
}

export const TimeEntryModal = ({ open, onClose }: Props) => {
  const { query } = useLocation();
  const { currentData: timer } = useGetTimerQuery(
    query?.timerId
      ? {
          id: query?.timerId,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  return <BaseTimeEntryModal timer={timer} open={open} onClose={onClose} />;
};
