import { useTheme } from "@mui/material";
import { Button, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { yup } from "../../lib";
import { authSelectors } from "../../state";
import { useUpdateTaskOrgMutation } from "../../state/rtk-query/state/tasks";
import { useGetUserQuery } from "../../state/rtk-query/state/user";
import { BaseModal, BaseForm } from "../forms";
import { TopLabel, SelectField } from "../forms/FormFields";
import { useOpenTaskDrawer } from "../hooks";

export namespace ChangeTaskOrgModal {
  export interface Props {
    reportedBy: number;
    orgId: number;
    taskId: number;
  }
}

export function ChangeTaskOrgModal({
  reportedBy,
  orgId,
  taskId,
}: ChangeTaskOrgModal.Props) {
  const [open, setOpen] = useState(true);
  const userId = useSelector(authSelectors.userId);
  const isInternal = useSelector(authSelectors.isInternal);
  const props = {
    closeModal: () => setOpen(false),
    open,
    originalOrgId: orgId,
    taskId,
    reportedBy,
  };

  if (userId === reportedBy) {
    return <ExternalOrgChangeModal {...props} />;
  }

  if (isInternal) {
    return <InternalOrgChangeModal {...props} />;
  }

  return null;
}

interface OrgChangeModalProps {
  taskId: number;
  open: boolean;
  closeModal: () => void;
  originalOrgId: number;
  reportedBy: number;
}

function InternalOrgChangeModal({
  closeModal,
  open,
  originalOrgId,
  taskId,
  reportedBy: userId,
}: OrgChangeModalProps) {
  const { currentData: userInfo } = useGetUserQuery(
    { id: userId },
    { skip: !userId },
  );
  return (
    <OrgChangeModal
      closeModal={closeModal}
      open={open}
      taskId={taskId}
      orgs={userInfo?.orgs || []}
      originalOrgId={originalOrgId}
      reportedBy={userId}
    />
  );
}

function ExternalOrgChangeModal({
  closeModal,
  open,
  originalOrgId,
  taskId,
  reportedBy,
}: OrgChangeModalProps) {
  const userInfo = useSelector(authSelectors.userInfo);
  return (
    <OrgChangeModal
      closeModal={closeModal}
      open={open}
      taskId={taskId}
      orgs={"orgs" in userInfo ? userInfo.orgs : []}
      originalOrgId={originalOrgId}
      reportedBy={reportedBy}
    />
  );
}
const vs = yup.object({
  org_id: yup.number().required("Required"),
});
const initialValues = {
  org_id: "" as "" | number,
};
function OrgChangeModal({
  orgs,
  open,
  closeModal,
  originalOrgId,
  taskId,
}: OrgChangeModalProps & { orgs: { id: number; name?: string }[] }) {
  const { palette } = useTheme();
  const [updateTaskOrg] = useUpdateTaskOrgMutation();
  const closeTask = useOpenTaskDrawer();

  const onSubmit = ({ org_id }: typeof initialValues) => {
    if (org_id) {
      updateTaskOrg({ body: { org_id }, id: taskId, orgId: originalOrgId });
      closeModal();
      closeTask();
    }
  };
  return (
    <BaseModal
      open={open}
      onClose={closeModal}
      title="Move task to another org?"
    >
      <BaseForm
        onClose={closeModal}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={vs}
        submitButtonName="Move"
        additionalButtons={
          <Button
            variant="contained"
            color={"warning"}
            onClick={() => {
              updateTaskOrg({
                id: taskId,
                orgId: originalOrgId,
                body: { org_id: originalOrgId },
              });
              closeModal();
            }}
          >
            Keep
          </Button>
        }
      >
        {() => {
          return (
            <>
              <Typography color={palette.grey[900]} fontWeight={500}>
                This task was created via email with more than one potential
                target organizations. If this is the correct org click "Keep".
                Otherwise select the org you want to move the task to.
              </Typography>
              <TopLabel label="" fullWidth>
                <SelectField
                  name="org_id"
                  items={orgs.map((o) => ({
                    id: o.id,
                    name: o.name || "",
                  }))}
                  fullWidth
                />
              </TopLabel>
            </>
          );
        }}
      </BaseForm>
    </BaseModal>
  );
}
