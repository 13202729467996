import { useMemo } from "react";
import { TimerActions, TimerActivityTypes } from "../../lib";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { useList, useOrgsList as uol, useUsersByOrgsList } from "./useNewLists";

export function useAdmireUsersList(skip?: boolean) {
  const { list, get } = useUsersByOrgsList(-1, skip);
  return { admireUsers: list, getAdmireUser: get };
}

export function useOrgUsersList(orgId: number, skip?: boolean) {
  const { list, get } = useUsersByOrgsList(orgId, skip);
  return { orgUsers: list, getOrgUser: get };
}

export function useCombinedUsersList(orgId: number, skip?: boolean) {
  const { get: getClientUser, list: clientUsers } = useList(
    "clientUsers",
    orgId !== -1 || skip,
  );
  const { admireUsers, getAdmireUser } = useAdmireUsersList(skip);
  const { orgUsers, getOrgUser } = useOrgUsersList(orgId, orgId === -1 || skip);
  return useMemo(() => {
    return {
      users: [...orgUsers, ...admireUsers, ...clientUsers],
      getUser: (id: number | undefined | null) => {
        if (id === undefined || id === null) return;
        return getAdmireUser(id) || getClientUser(id) || getOrgUser(id);
      },
    };
  }, [admireUsers, clientUsers, orgUsers, orgId, !!skip]);
}

export function useConsultantsList(skip?: boolean) {
  const { list, get } = useList("consultants", skip);
  return { consultants: list, getConsultant: get };
}

export function useAccountManagersList(skip?: boolean) {
  const { list, get } = useList("accountManagers", skip);
  return { accountManagers: list, getAccountManager: get };
}

export function useLicensesList(skip?: boolean) {
  const { list, get } = useList("licenses", skip);
  const ls = useList("licenseStatuses", skip);
  return {
    licenses: list,
    getLicense: get,
    licenseStatuses: ls.list,
    getLicenseStatus: ls.get,
  };
}

export function useTopicsList(skip?: boolean) {
  const topics = useList("topics", skip);
  return { topics: topics.list, getTopic: topics.get };
}

export function useOrgsList(skip?: boolean) {
  const orgs = uol(skip);
  return { orgs: orgs.list, getOrg: orgs.get };
}

export function useTermItemTypesList(skip?: boolean) {
  const tit = useList("termItemTypes", skip);
  return { termItemTypes: tit.list, getTermItemTypes: tit.get };
}

export function useActivityTypesList(skip?: boolean) {
  const isInternal = useSelector(authSelectors.isInternal);

  const { list, get } = useList("activityTypes", !isInternal || skip);
  return useMemo(() => {
    return {
      activityTypes: list,
      getActivityTypes: get,
      getActivityId: (activity?: TimerActivityTypes) => {
        if (!activity) return 0;
        return list?.find((a) => a?.internal_name === activity)?.id ?? 0;
      },
    };
  }, [list, get, !!skip, isInternal]);
}

export function useActivityActionsList(skip?: boolean) {
  const isInternal = useSelector(authSelectors.isInternal);
  const { list: actions, get } = useList(
    "activityActions",
    !isInternal || skip,
  );
  return useMemo(() => {
    return {
      actions,
      getActivityTypes: get,
      getActionId: (action?: TimerActions) => {
        if (!action) return 0;
        return actions?.find((a) => a?.internal_name === action)?.id ?? 0;
      },
    };
  }, [actions, isInternal, skip]);
}

export function useStatusesList<
  StatusType extends
    | "all"
    | "all_tasks"
    | "session"
    | "task"
    | "internal_task"
    | "custom_work"
    | "quick_question" = "all",
>(type = "all" as StatusType, skip?: boolean) {
  const { list, get } = useList("statuses", skip);
  return useMemo(() => {
    const l = list.filter((s) => {
      if (type === "all") return true;
      if (s.type === "all") return true;
      if (s.type === type) return true;
      if (s.type === "all_tasks" && type !== "session") return true;
      if (s.type !== "session" && type === "all_tasks") return true;
    });
    function getStatusByName<SType extends StatusType = StatusType>(
      internal_name: string,
    ): SType extends "all" | "all_tasks" ? number[] : number {
      const statusesByName = list
        .filter((s) => s.internal_name === internal_name)
        .map((s) => s.id);
      return (
        type === "all" || type === "all_tasks"
          ? statusesByName
          : statusesByName[0]
      ) as SType extends "all" | "all_tasks" ? number[] : number;
    }

    function getStatusByPhase(
      phase: (typeof list)[number]["transition_phase"],
    ) {
      return l.filter((s) => s.transition_phase === phase).map((s) => s.id);
    }

    function getEditableStatuses() {
      return l?.filter((ss) => !ss?.is_automated);
    }

    function getCompleteStatusByPhase() {
      const statuses = getStatusByPhase("done");
      const completeStatuses = new Set([21, 14, 20, 19, 13]);
      const status = statuses.find((s) => completeStatuses.has(s));
      if (!status) {
        throw new Error("Failed to get complete status for" + type);
      }
      return status;
    }

    function isCompleteStatus(statusId?: number) {
      if (!statusId) return false;
      const status = get(statusId);
      return status?.transition_phase === "done";
    }

    return {
      statuses: l,
      getStatus: get,
      getStatusByName,
      getStatusByPhase,
      getCompleteStatusByPhase,
      getEditableStatuses,
      isCompleteStatus,
    };
  }, [list, get, type, !!skip]);
}

export function usePositionsList(skip?: boolean) {
  const pos = useList("positions", skip);
  return { positions: pos.list, getPosition: pos.get };
}

export function useTemplatesList(skip?: boolean) {
  const { list, get } = useList("templates", skip);
  return { templates: list, getTemplate: get };
}
