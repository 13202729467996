import { Stack, Typography, useTheme } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { ActiveElement, ChartData, ChartEvent } from "chart.js";
import { useState, useRef, useMemo } from "react";
import { Chart } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { castZero, filterBoolean, Navigation } from "../../../lib";
import { useGetInternalTimeSpentOnOrgQuery } from "../../../state/rtk-query/state/timer";
import { useMobile } from "../../../themes";
import { SortableButton } from "../../buttons";
import { CardStyled } from "../../styled";
import {
  baseBarDataSet,
  HORIZONTAL_BAR_THICKNESS,
  onClickGetLabel,
  onHoverUpdateCursor,
} from "../ChartConstants";
import { HorizontalMultiBarBackgroundPlugin } from "../ChartPlugins";
import { defaultHorizontalChartOptions } from "./SupportByUserChartConstants";
import { authSelectors, useSelector } from "../../../state";
import { useOrgsList } from "../../hooks";
const castMinToHours = (num?: number | null) => {
  return castZero(num) / 60;
};

export const SupportByUserChart = ({ orgId }: { orgId?: number }) => {
  const { palette } = useTheme();
  const isMobile = useMobile();
  const isAdmin = useSelector(authSelectors.isAdmin);
  const { getOrg } = useOrgsList(!isAdmin);
  const [dateRange, setDateRange] = useState<string[] | undefined>(undefined);
  const ref = useRef<ChartJSOrUndefined<"bar", number[], string> | null>(null);
  const { currentData } = useGetInternalTimeSpentOnOrgQuery(
    orgId ? { orgId, dateRange } : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  const data = useMemo(
    () =>
      currentData?.rows?.map((r) => ({
        user: `${r.user?.first_name ?? ""} ${r.user?.last_name ?? ""}`,
        sessions: castMinToHours(r.sessions),
        tasks: castMinToHours(r.tasks),
      })) ?? [],
    [currentData],
  );
  const hasDateRange = useMemo(
    () => dateRange && !!filterBoolean(dateRange)?.length,
    [dateRange],
  );

  const amountOfUsers = useMemo(() => data.length, [data, !!hasDateRange]);

  const chartData = useMemo(() => {
    return {
      labels: data.map((d) => d.user),
      datasets: [
        baseBarDataSet({
          label: "Sessions",
          data: data.map((d) => d.sessions),
          backgroundColor: palette.accent[600],
          barThickness: HORIZONTAL_BAR_THICKNESS,
          borderRadius: 5,
        }),
        baseBarDataSet({
          label: "Tasks",
          data: data.map((d) => d.tasks),
          backgroundColor: palette.info.main,
          borderRadius: 5,
          barThickness: HORIZONTAL_BAR_THICKNESS,
        }),
      ],
    } as ChartData<"bar", number[], string>;
  }, [amountOfUsers]);

  const height = useMemo(() => {
    return (
      // there are 2 bars per user & if there are less than 4 users the chart looks a bit squished so I add some moe space
      amountOfUsers * (HORIZONTAL_BAR_THICKNESS * 2) +
      (amountOfUsers < 4 ? HORIZONTAL_BAR_THICKNESS * 2 : 0)
    );
  }, [amountOfUsers, isMobile, dateRange]);
  const orgName = getOrg(orgId)?.name;
  
  const options = useMemo(
    () => ({
      ...defaultHorizontalChartOptions,

      onClick: (e: any) => {
        if (!isAdmin) return null;
        const consultant = onClickGetLabel(e);

        if (!consultant || !orgName) return null;
        Navigation.go(`/timer/admin`, {
          query: {
            view: "table",
            time_filter: "all_orgs",
            filter: JSON.stringify([
              {
                id: 123,
                field: "org_name",
                operator: "equals",
                value: orgName,
              },
              {
                id: "consultant",
                field: "consultant",
                operator: "equals",
                value: consultant,
              },
            ]),
          },
        });
      },

      onHover: (e: ChartEvent, el: ActiveElement[]) =>
        onHoverUpdateCursor(e, el, isAdmin),
    }),
    [isAdmin, onClickGetLabel, orgName, orgId],
  );
  return (
    <>
      <CardStyled
        sx={{
          p: 2,
          pb: 0,
          gap: 1,
          display: "flex",
          flexDirection: "column",
          width: isMobile ? "100%" : "50%",
          maxHeight: isMobile ? height + 150 + "px" : "100%",
          height: "100%",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h3" fontWeight={600}>
            USAGE PER USER
          </Typography>
          <SortableButton
            title={"All dates"}
            onChange={(range) => {
              setDateRange(range[0] && range[1] ? range : undefined);
            }}
            isDropDown
            isDate
            currentSort={dateRange as any as Date[]}
            removeTitle={hasDateRange}
          />
        </Stack>

        {amountOfUsers ? (
          <Stack
            //  This controls th height of the chart
            direction={"column"}
            width="100%"
            height={height * 3}
            minHeight={height}
          >
            <Chart
              ref={ref}
              type="bar"
              data={chartData}
              options={options}
              plugins={[HorizontalMultiBarBackgroundPlugin]}
            />
          </Stack>
        ) : (
          <Stack
            justifyContent="center"
            width="100%"
            direction={"row"}
            height={"300px"}
            alignItems={"center"}
          >
            <Typography>
              No data available {hasDateRange ? "for the selected dates" : ""}
            </Typography>
          </Stack>
        )}
      </CardStyled>
    </>
  );
};
