import { Navigation } from "../../lib";
import { useState, useCallback } from "react";

const onRowClick = ({
  row,
}: {
  row: { id: number; deleted_at?: string | Date };
}) => {
  if (!row.deleted_at) {
    Navigation.go("/organizations/:id", { params: { id: row.id } });
  }
};

export function useRowSelectionModel<Row extends { id?: number | string }>(
  rows?: Row[],
) {
  const [selectedRows, setSelectedRows] = useState([] as any[]);
  const onCellClick = useCallback(
    (c: any, e: any) => {
      if (c.field === "__check__") {
        if (e.target.checked) {
          setSelectedRows((prev) => [...prev, c.id]);
        } else {
          setSelectedRows((prev) => prev.filter((id) => id !== c.id));
        }
      }
    },
    [setSelectedRows],
  );
  const onColumnHeaderClick = useCallback(
    (c: any, e: any) => {
      if (c.field === "__check__") {
        if (e.target.checked) {
          setSelectedRows(rows?.map((r) => r.id) || []);
        } else {
          setSelectedRows([]);
        }
      }
    },
    [setSelectedRows, rows],
  );
  return {
    onCellClick,
    onColumnHeaderClick,
    selectedRows,
    setSelectedRows,
    onRowClick,
  };
}
