import { api } from "../api";
export const addTagTypes = ["organizations", "timer"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      calculateBillings: build.query<
        CalculateBillingsApiResponse,
        CalculateBillingsApiArg
      >({
        query: (queryArg) => ({
          url: `/org_terms/billings`,
          params: {
            sort: queryArg.sort,
            field: queryArg.field,
            filter: queryArg.filter,
            range: queryArg.range,
            linkType: queryArg.linkType,
            q: queryArg.q,
            dateRange: queryArg.dateRange,
            billed: queryArg.billed,
            activityType: queryArg.activityType,
          },
        }),
        providesTags: ["organizations", "timer"],
      }),
      getOrgTerms: build.query<GetOrgTermsApiResponse, GetOrgTermsApiArg>({
        query: (queryArg) => ({ url: `/org_terms/${queryArg.orgId}` }),
        providesTags: ["organizations"],
      }),
      updateTerms: build.mutation<UpdateTermsApiResponse, UpdateTermsApiArg>({
        query: (queryArg) => ({
          url: `/org_terms/${queryArg.orgId}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["organizations"],
      }),
      getMyOrgsTermsStats: build.query<
        GetMyOrgsTermsStatsApiResponse,
        GetMyOrgsTermsStatsApiArg
      >({
        query: (queryArg) => ({
          url: `/org_terms/${queryArg.orgId}/mine/used`,
        }),
        providesTags: ["organizations"],
      }),
      getCwStats: build.query<GetCwStatsApiResponse, GetCwStatsApiArg>({
        query: (queryArg) => ({
          url: `/org_terms/${queryArg.orgId}/mine/cw/stats`,
        }),
        providesTags: ["organizations"],
      }),
      getTermItemTypes: build.query<
        GetTermItemTypesApiResponse,
        GetTermItemTypesApiArg
      >({
        query: () => ({ url: `/term_item_types` }),
        providesTags: ["timer"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type CalculateBillingsApiResponse =
  /** status 200 Org Billing calculated hours and cost */ {
    count: number;
    rows: {
      id: number;
      name: string;
      quickbooks_customer_id?: string | null;
      total_hours: number;
      billable_hours: number;
      cost: number;
      last_billed?: string | null;
    }[];
  };
export type CalculateBillingsApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
  /** date range to filter by */
  dateRange?: string[];
  /** calculate billed timers */
  billed?: boolean;
  /** timer activity type */
  activityType:
    | "custom_work"
    | "standardized_custom_work"
    | "complimentary_custom_work";
};
export type GetOrgTermsApiResponse = /** status 200 Show current terms */ {
  id?: number;
  org_id: number;
  start_date?: string;
  end_date?: string;
  original_term_id?: number | null;
  created_at?: string;
  updated_at?: string | null;
  deleted_at?: string | null;
  sessions_post_term?: number;
  suggested_average: number;
  total_sessions: number;
  term_items?: {
    id?: number;
    term_id?: number;
    item_id?: number;
    rate?: number | null;
    quantity?: number;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
    item?: {
      id?: number;
      internal_name?: string;
      name?: string;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    };
  }[];
} | null;
export type GetOrgTermsApiArg = {
  /** organization id */
  orgId: number;
};
export type UpdateTermsApiResponse = /** status 204 An empty response */ void;
export type UpdateTermsApiArg = {
  /** organization id */
  orgId: number;
  /** terms body */
  body: {
    org_id: number;
    start_date?: string;
    end_date?: string;
    term_items?: {
      item_id?: number;
      quantity?: number;
      rate?: number | null;
    }[];
  };
};
export type GetMyOrgsTermsStatsApiResponse =
  /** status 200 Actions that constitute as using minutes from terms */ {
    sessions?: {
      month?: string;
      month_idx?: number;
      total?: number;
      row?: number;
      monthly_avg?: number;
      total_month_over_month?: number;
      avg_month_over_month?: number;
      valid_row?: boolean;
    }[];
    tasks?: {
      month?: string;
      month_idx?: number;
      total?: number;
      row?: number;
      monthly_avg?: number;
      total_month_over_month?: number;
      avg_month_over_month?: number;
      valid_row?: boolean;
    }[];
    chart_row_start?: number;
  };
export type GetMyOrgsTermsStatsApiArg = {
  /** organization id */
  orgId: number;
};
export type GetCwStatsApiResponse = /** status 200 count */ {
  billed_minutes: number;
  unbilled_minutes: number;
  available_hours?: number;
  used_hours?: number;
};
export type GetCwStatsApiArg = {
  /** organization id */
  orgId: number;
};
export type GetTermItemTypesApiResponse = /** status 200 Term item types */ {
  count: number;
  rows: {
    id: number;
    internal_name: string;
    name: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetTermItemTypesApiArg = void;
export const {
  useCalculateBillingsQuery,
  useLazyCalculateBillingsQuery,
  useGetOrgTermsQuery,
  useLazyGetOrgTermsQuery,
  useUpdateTermsMutation,
  useGetMyOrgsTermsStatsQuery,
  useLazyGetMyOrgsTermsStatsQuery,
  useGetCwStatsQuery,
  useLazyGetCwStatsQuery,
  useGetTermItemTypesQuery,
  useLazyGetTermItemTypesQuery,
} = injectedRtkApi;
