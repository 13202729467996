import { ui, UIViewType, UIViewState, UINotification } from "./state";

const { actions } = ui;

export const uiActions = {
  ...actions,

  // Notifications

  showError(message = "There was an error processing your request.") {
    return actions.showNotification({
      message,
      variant: "error",
    });
  },

  showMessage(message: UINotification["message"]) {
    return actions.showNotification({ message });
  },

  showSuccess(message: UINotification["message"]) {
    return actions.showNotification({ message, variant: "success" });
  },

  showWarning(message: UINotification["message"]) {
    return actions.showNotification({ message, variant: "warning" });
  },

  // Views

  /** Dynamically creates an action function which sets `view[name] = false`. */
  toHideView(name: keyof UIViewState) {
    return () => actions.setView({ [name]: false });
  },
  /** Dynamically creates an action function which removes `view[name]`. */
  toRemoveView(name: keyof UIViewState) {
    return () => actions.setView({ [name]: undefined });
  },
  /** Dynamically creates an action function which sets `view[name] = value`. */
  toSetView(name: keyof UIViewState) {
    return (value?: UIViewType) => actions.setView({ [name]: value });
  },
  /** Dynamically creates an action function which sets `view[name] = true`. */
  toShowView(name: keyof UIViewState) {
    return () => actions.setView({ [name]: true });
  },
};
