import { logout } from "../../state";
import React from "react";

const ErrorBoundary: React.FC = () => {
  const handleRefresh = () => {
    logout();

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        padding: "20px",
        backgroundColor: "#f8f9fa",
        color: "#333",
      }}
    >
      <h1 style={{ fontSize: "2em", marginBottom: "0.5em" }}>
        Oops! Something went wrong.
      </h1>
      <p
        style={{
          fontSize: "1.1em",
          maxWidth: "600px",
          textAlign: "center",
          lineHeight: "1.6",
        }}
      >
        We encountered an unexpected error.
        <br /> Please try refreshing the page to continue.
      </p>
      <button
        onClick={handleRefresh}
        style={{
          padding: "12px 24px",
          marginTop: "20px",
          fontSize: "1em",
          backgroundColor: "#007bff",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#0056b3")}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#007bff")}
      >
        Refresh Page
      </button>
    </div>
  );
};

export default ErrorBoundary;
