const slotProps = {
  panel: {
    sx: {
      "& .MuiIconButton-root, .MuiSvgIcon-root": {
        fontSize: "20px !important",
      },
    },
  },

  columnMenu: {
    sx: {
      "& .MuiIconButton-root, .MuiSvgIcon-root": {
        fontSize: "1em !important",
      },
    },
  } as any,
};

const autosizeOptions = {
  includeHeaders: true,
  outliersFactor: 100,
  includeOutliers: false,
};

export const useDatagridProps = () => {
  return {
    slotProps,
    autosizeOptions,
  };
};
