import {
  format as dateFormatter,
  isValid,
  parseISO,
  compareAsc,
} from "date-fns";
import zxcvbn from "zxcvbn";
import { UserSimplified } from "../global";

export { arrayMoveMutable as arrayMove } from "array-move";

const dateInputKeyFilter = [
  "Backspace",
  "Delete",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

/**
 * Returns an entity list from the given array.
 * @param {any[]} arr
 * @param {string} idField
 * @returns {{ids:number[],entities:{[id:string]:any}}}
 */
export function arrayToEntityList(arr: any[], idField = "id") {
  const list = { ids: [], entities: {} };
  return arr.reduce((list, item) => {
    const id = item[idField];
    list.ids.push(id);
    list.entities[id] = item;
    return list;
  }, list);
}

/**
 * Crops the given `text` if it's longer than the `max` length.
 * Optionally adds a suffix to the cropped text.
 * @param {string} text
 * @param {number} max
 * @param {string} [suffix]
 */
export function cropText(text: string, max: number, suffix = "...") {
  if (text?.length > max) {
    return text.substring(0, max) + suffix;
  }
  return text;
}
/** Returns todays local date as a string, formatted as a US date by default. */
export function dateTodayLocal(format = "MM/dd/yyyy") {
  return dateFormatter(new Date(), format);
}
/** Returns todays local date as a string, formatted as an ISO date. */
export function dateTodayLocalISO() {
  return dateTodayLocal("yyyy-MM-dd");
}
/** Returns todays UTC date as a string in ISO format. */
export function dateTodayISO() {
  return new Date().toISOString().split("T")[0];
}
/**
 * Simple debounce function
 * @param {Function} fn Function to call after the `delay`.
 * @param {number} delay Time in milliseconds.
 */
export function debounce<T extends (...args: any[]) => any>(
  func: T,
  delay: number,
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout;

  return (...args: Parameters<T>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
}

/**
 * Converts a decimal percentage to an integer percentage.
 * @param {number} value
 */
export function decimalToPercent(value: string) {
  return parseFloat(value) * 100;
}
/** An empty function. */
export function emptyHandler() {}
/**
 * Allows only the arrow keys to change a native date input.
 * @param {React.KeyboardEvent<HTMLInputElement>} e
 */
export function filterDateInputKeys(e: React.KeyboardEvent) {
  if (dateInputKeyFilter.includes(e.key)) {
    e.preventDefault();
    e.stopPropagation();
  }
}
/**
 * @template T
 * @param {T[]} items
 * @param {any} id
 */
export function findById(items: any[], id: any) {
  return items.find((it) => it.id === id);
}
/**
 * @template T
 * @param {T[]} items
 * @param {any} uid
 */
export function findByUid(items: any[], uid: any) {
  return items.find((it) => it.uid === uid);
}
/**
 * @template T
 * @param {T[]} items
 * @param {string} fieldName
 * @param {any} value
 */
export function findByField(items: any[], fieldName: string, value: any) {
  return items.find((it) => it[fieldName] === value);
}
/**
 * Finds the earliest ISO formatted date property in an object array.
 * @param {Record<string,string>[]} objects
 * @param {string} propName
 */
export function findLowestISODateProp(
  objects: Record<string, string>[],
  propName: string,
) {
  const sorted = objects
    .map((it) => {
      const value = it[propName];
      return {
        value,
        valueAsDate: parseISO(value),
      };
    })
    .sort((a, b) => compareAsc(a.valueAsDate, b.valueAsDate));
  return sorted[0]?.value;
}
/** Flattens nested objects and arrays into a single dimension object.
 * See https://stackoverflow.com/questions/54896928/flattening-the-nested-object-in-javascript
 */
export function flatten(
  obj: Record<string, any>,
  prefix = "",
  res: Record<string, any> = {},
) {
  return Object.entries(obj).reduce((r, [key, val]) => {
    const k = `${prefix}${key}`;
    if (typeof val === "object") {
      flatten(val, `${k}.`, r);
    } else {
      res[k] = val;
    }
    return r;
  }, res);
}
/**
 * Formats `amount` in standard USD format.
 * - This was used instead of `Intl.NumberFormat` since the polyfill for that is
 * huge and we don't want to use a third-party polyfill.io service for a
 * financial app.
 * - See https://stackoverflow.com/a/149099/16387
 * - Removed decimal option.
 * - Added dollar sign option.
 * - Converted options to a single object argument.
 * @param {number} amount
 * @param {{decimalCount:number,decimalIfNotWhole:boolean,dollarSign:string,thousands:string}} [options]
 * @param {number} [options.decimalCount] Number of decimals to display. (`2`)
 * @param {boolean} [options.decimalIfNotWhole] If should only show decimal if not a whole dollar amount
 * @param {string} [options.dollarSign] Dollar sign to display. (`"$"`)
 * @param {string} [options.thousands] Thousands separator. (`","`)
 */
export function formatDecimal(amount: number) {
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

//https://stackoverflow.com/a/31732581 JS respects date if there is a `/` vs but is off with `-`
export function fixJsFormattedDate(date: string) {
  if (!date || !isValid(new Date(date))) {
    return "";
  }
  return new Date(String(date).replace(/-/g, "/"));
}

/**
 * Formats the given `date` to the given `format` (`"MM/dd/yyyy"`).
 * **WARNING: If you provide `date` as an ISO string without a timezone
 * specifier, this function will convert that date to UTC time.**
 */
export function formatDate(date: string | Date, format = "MM/dd/yyyy") {
  if (!date) {
    return "";
  }

  const d = typeof date === "string" ? fixJsFormattedDate(date) : date;
  if (!isValid(d) || d === "") {
    return "";
  }

  return dateFormatter(d, format);
}
/** Formats the given `date` to ISO-8601 date format.
 * **WARNING: If you provide `date` as an ISO string without a timezone
 * specifier, this function will convert that date to UTC time.**
 */
export function formatDateISO(date: string, format = "yyyy-MM-dd") {
  if (!date) {
    return "";
  }
  const d = new Date(date);
  if (!isValid(d)) {
    return "";
  }
  return dateFormatter(d, format);
}
export function formatDateLong(date: string, format = "MMMM dd, yyyy") {
  if (!date) {
    return "";
  }
  const d = new Date(date);
  if (!isValid(d)) {
    return "";
  }
  return dateFormatter(d, format);
}

export function formatDateToDayOfWeek(date: Date) {
  let dayOfWeek = undefined;
  switch (date.getDay()) {
    case 0:
      dayOfWeek = "Sunday" as const;
      break;
    case 1:
      dayOfWeek = "Monday" as const;
      break;
    case 2:
      dayOfWeek = "Tuesday" as const;
      break;
    case 3:
      dayOfWeek = "Wednesday" as const;
      break;
    case 4:
      dayOfWeek = "Thursday" as const;
      break;
    case 5:
      dayOfWeek = "Friday" as const;
      break;
    case 6:
      dayOfWeek = "Saturday" as const;
      break;
    default:
      break;
  }
  return dayOfWeek;
}
/**
 * @example ```
 * default - Tuesday, August 22nd
 * short - Aug 22nd
 * ```
 * */
export function formatDateToHuman(date: Date | string, short = false) {
  const d = typeof date === "string" ? fixJsFormattedDate(date) : date;
  if (!date || !isValid(d)) {
    return "";
  }
  return formatDate(date, short ? "LLL d" : "EEEE, LLLL dd");
}
/**
 * @example ```
 *  5:30 PM
 * ``` */
export function formatTimeToHuman(date: Date | string) {
  const d = typeof date === "string" ? new Date(date) : date;
  if (!d || !isValid(d)) {
    return "";
  }
  return d.toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
  });
}

/**
 * **WARNING: If you provide `date` as an ISO string without a timezone
 * specifier, this function will convert that date to UTC time.**
 */
export function formatDateTime(
  datetime: string,
  format = "MM/dd/yyyy h:mm aa",
) {
  return formatDate(datetime, format);
}

/**
 * Formats an ISO formatted `date` string (`"yyyy-mm-dd"`) as a local US date
 * (`"MM/dd/yyyy"`) without changing timezone, unlike `formatDate`.
 * @param {string} [isoDate]
 */
export function formatISODate(isoDate: string) {
  if (!isoDate || !isoDate.split) {
    return "";
  }
  const parts =
    // Split by "T" first, in case there is a time following the date.
    isoDate
      .split("T")[0]
      // Split by dash to get date parts.
      .split("-");
  if (parts.length < 3 || parts[0] === "0000") {
    return "";
  }
  return `${parts[1]}/${parts[2]}/${parts[0]}`;
}

export function formatTimeForInput(datetime: string, format = "HH:mm") {
  return formatDate(datetime, format);
}
/**
 * Returns the ordinal indicator text (e.g. 1st, 2nd, etc) for any number.
 * See https://english.stackexchange.com/questions/192804
 * @param {number} [num]
 */
export function formatOrdinal(num: number) {
  return `${num}${
    num % 10 === 1 && num % 100 !== 11
      ? "st"
      : num % 10 === 2 && num % 100 !== 12
      ? "nd"
      : num % 10 === 3 && num % 100 !== 13
      ? "rd"
      : "th"
  }`;
}
export function formatPercent(value: number, options: any) {
  let decimalCount = 2;
  if (options) {
    if (options.decimalCount) decimalCount = options.decimalCount;
  }
  if (isNaN(value)) {
    return "";
  }
  return `${value.toFixed(decimalCount)}%`;
}
/** @param {string} value The phone number. */
export function formatPhone(value: string, dashed: boolean = false) {
  const cleaned = getPhoneNumbersOnly(value);
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [
      intlCode,
      dashed ? "" : "(",
      match[2],
      dashed ? "-" : ") ",
      match[3],
      "-",
      match[4],
    ].join("");
  }
  return null;
}
/** @param {string} value */
export function getPhoneNumbersOnly(value: string) {
  return ("" + (value || "")).replace(/\D/g, "");
}
/**
 * Returns true if the given `date` is a valid `Date` object.
 * @param {Date} date
 */
export function isDateValid(d: Date) {
  return d instanceof Date && !isNaN(d.getTime());
}
/** True if the given `str` is 'yes'. (Case insensitive) */
export function isYes(str: string) {
  return ("" + str).toLowerCase() === "yes" ? true : false;
}
/**
 * Converts the given value to lower camel case.
 * @param {string} value
 */
export function lowerCamelCase(value: string) {
  if (!value) {
    return "";
  }
  return value.substr(0, 1).toLowerCase() + value.substr(1);
}
/**
 * Converts the column name to a title - remove underscores & make first letter uppercase.
 * @param {string} value
 */
export function columnToTitle(column: string) {
  if (!column) {
    return "";
  }
  return column
    .split("_")
    .map((i) => i.substr(0, 1).toUpperCase() + i.substr(1))
    .join(" ");
}
/**
 * Returns an array of values from a map of values, by key.
 * The opposite of `arrayToObjById`.
 * @param {{ [key:string]:any }} obj Map of values by key.
 */
export function mapToArray(obj: Record<string, any>) {
  return Object.keys(obj).map((key) => obj[key]);
}
/**
 * Returns the given string value with numbers masked by an asterisk, if
 * `shouldMask` is true.
 * @param {boolean} shouldMask
 * @param {string} value
 */
export function maskNumbersIf(shouldMask: boolean, value: string) {
  return shouldMask ? ("" + value).replace(/[0-9]/g, "*") : value;
}
/**
 * Masks all characters up to the last 4.
 * @param {string} value
 * @param {number} [maskLen] Optional number of mask characters. If passed, this
 * number will be used instead of detecting how many characters came before the
 * last 4.
 */
export function maskUpToLast4(value: string, maskLen: number) {
  value = "" + value;
  const lengthBeforeLast4 = Math.max(0, value.length - 4);
  const last4 = value.substr(lengthBeforeLast4);
  const mask = "*".repeat(maskLen || lengthBeforeLast4);
  return mask + last4;
}

export function replaceNullProps(props: any, replace = "") {
  const newProps: Record<string, any> = {};
  Object.keys(props).forEach((prop) => {
    const value = props[prop];
    newProps[prop] = value === null ? replace : value;
  });
  return newProps;
}
/** Function that simply returns it's given argument. */
export function returnArg(arg: any) {
  return arg;
}
/** Function that returns true. */
export function returnTrue() {
  return true;
}
/**
 * Returns a CSS `hsl` color string hashed from the given `str`.
 * @param {string} str The input string.
 * @param {number} saturation Percentage of saturation (`0 - 100`).
 * Use a value around `30` for pastels.
 * @param {number} lightness Percentage of lightness (`0 - 100`).
 * Use a value around `80` for pastels.
 *
 * @see https://medium.com/%40pppped/compute-an-arbitrary-color-for-user-avatar-starting-from-his-username-with-javascript-cd0675943b66
 * @see https://codepen.io/sergiopedercini/pen/RLJYLj/
 */
export function stringToHslColor(
  str: string,
  saturation: number,
  lightness: number,
) {
  const { length } = str || "";
  let hash = 0;
  for (let i = 0; i < length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = hash % 360;
  return `hsl(${color},${saturation}%,${lightness}%)`;
}
/**
 * Returns a pastel CSS `hsl` color string hashed from the given `str`.
 * @param {string} str The input string.
 * @see `stringToHslColor`
 */
export function stringToHslPastel(str: string) {
  return stringToHslColor(str, 30, 80);
}
/**
 * Asynchronously waits for the given amount of time in `ms`.
 * @param {number} [ms] Time to wait, in milliseconds.
 */
export function timeoutAsync(ms = 0) {
  return new Promise((resolve, _reject) => {
    setTimeout(resolve, ms);
  });
}
/**
 * Returns the first `collection` property value that matches `predicate`.
 * @template TCollection
 * @param {TCollection} collection
 * @param {(Pick<TCollection, keyof TCollection>)=>boolean} predicate
 * @returns {Pick<TCollection, keyof TCollection>}
 */
export function find(collection: any, predicate: any) {
  const key = Object.keys(collection).find((key) => predicate(collection[key]));
  return key !== undefined ? collection[key] : undefined;
}
/**
 * Maps over `obj` keys and returns values from the given `map` function.
 * @template T
 * @template R
 * @param {Record<string,T>} obj
 * @param {(value:T,key:string,obj:Record<string,T>)=>R} map
 * @returns {R}
 */
export function mapValues(obj: any, map: any) {
  return Object.keys(obj).map((key) => map(obj[key], key, obj));
}
/**
 * Reduce function for objects. Transforms `obj` to a new `accumulator` object
 * using the given `map` function.
 * @template T
 * @template {T} R
 * @param {Record<string,T>} obj
 * @param {(value:T,key:string,obj:Record<string,T>)=>R} map
 * @param {Record<string,T>} [accumulator]
 * @returns {Record<string,R>}
 */
export function transform(
  obj: Record<string, any>,
  map: any,
  accumulator: Record<string, any> = {},
) {
  return Object.keys(obj).reduce((accumulator, key) => {
    accumulator[key] = map(obj[key], key, obj);
    return accumulator;
  }, accumulator);
}
/**
 * Converts `array` to a new object keyed by the given `key`.
 * @example reduceBy([{id:1},{id:2}],"id") // returns { 1:{id:1}, 2:{id:2} }
 * @example reduceBy(["a", "b"]) // returns { 0: "a", 1: "b" }
 * @template T
 * @param {T[]} [array] An array of values to convert.
 * @param {keyof T} [key] For an array of objects, key to use. If ommited, the
 * array index is used as the key.
 * @param {Record<string,T>} [obj] Optional object to convert into.
 * @returns {Record<string,T>}
 */
export function reduceBy(array: any[], key: any, obj = {}) {
  if (!array) {
    return [];
  }
  return array.reduce((obj, it, i) => {
    const prop = key !== undefined ? it[key] : i;
    obj[prop] = it;
    return obj;
  }, obj);
}
export function shallowEqualsObj(
  objA: Record<string, any>,
  objB: Record<string, any>,
) {
  if (objA === objB) {
    return true;
  }

  if (!objA || !objB) {
    return false;
  }

  const aKeys = Object.keys(objA);
  const bKeys = Object.keys(objB);
  const len = aKeys.length;

  if (bKeys.length !== len) {
    return false;
  }

  for (let i = 0; i < len; i++) {
    const key = aKeys[i];

    if (
      objA[key] !== objB[key] ||
      !Object.prototype.hasOwnProperty.call(objB, key)
    ) {
      return false;
    }
  }

  return true;
}
/** Returns true if the given object, array or string value is empty. */
export function isEmpty(value: any) {
  return !value || Object.keys(value).length === 0;
}
/**
 * Returns true if any of the given object, array or string values are empty.
 */
export function allEmpty(...values: any[]) {
  const { length } = values;
  for (let i = 0; i < length; i++) {
    const value = values[i];
    if (!isEmpty(value)) {
      return false;
    }
  }
  return true;
}
export function passwordStrength(password: string) {
  return zxcvbn(password).score;
}
export function passwordFeedback(password: string) {
  return zxcvbn(password).feedback.warning;
}
export const createPasswordLabel = (score: number) => {
  switch (score) {
    case 0:
      return "Weak";
    case 1:
      return "Weak";
    case 2:
      return "Fair";
    case 3:
      return "Good";
    case 4:
      return "Strong";
    default:
      return "Weak";
  }
};
export const linearBarColor = (score: number) => {
  switch (score) {
    case 0:
      return "error";
    case 1:
      return "error";
    case 2:
      return "warning";
    case 3:
      return "info";
    case 4:
      return "success";
    default:
      return "error";
  }
};

/**
 * Converts number byte size.
 @param n number of bytes
 * k = solve for `x`  2^x = original number  =>  / 10 and rounded down between 0 and 5
 * rank = using `k` as an index (i.e. Mb, Kb , Gb, Tb)
 */
export function formatBytes(n: number) {
  const k = n > 0 ? Math.floor(Math.log2(n) / 10) : 0;
  const rank = (k > 0 ? "KMGT"[k - 1] : "") + "b";
  const count = Math.floor(n / Math.pow(1024, k));
  return count + rank;
}
/**
 * @param value the value we are checking if its tagging a user
 * @returns true if the provided string contains an "@" symbol
 * only if it is not followed by any space at some point after
 */
export function checkAts(value: string): Boolean {
  if (value.trim() === "") {
    return false;
  }
  // find @ not followed by space
  const matchWithSpace = value.match(/(@(\w+ )|\s)/g) ?? [];
  const matchFullUser = value.match(/\B@\w+/g) ?? [];
  const splitLength = value.split("@").length;
  // if the value starts with "@" theres an extra space added to split length
  return (
    matchWithSpace.length > 0 &&
    matchWithSpace.length !==
      (value.startsWith("@") ? splitLength - 1 : splitLength) &&
    matchWithSpace.length === matchFullUser.length
  );
}

export function deepCopy(obj: Record<string, any>) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * @param array
 * @param cb : filterBy callback
 * @returns 2 arrays , [passing , failing]
 * @see https://stackoverflow.com/a/47225591
 */
export function partitionArray<T>(array: T[], cb: (val: T) => boolean) {
  return array.reduce(
    ([pass, fail], elem) => {
      return cb(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
    },
    [[], []] as T[][],
  );
}

/**
 * @param {string} color hex
 * @param {float} opacity
 */

export function addOpacityToHex(color: string, opacity: number) {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

/**
 *Capitalizes every word in a sentence
 * @param {string} value
 */
export function capitalizeSentence(string: string) {
  return string.replace(/\b(\w)/g, (m, g) => g.toUpperCase());
}
/**
 *Capitalizes first letter of a sentence
 * @param {string} value
 */
export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
/***
 * @param {users} users session_invitees or task_watchers
 * @returns UserSimplified[]
 */
export function isUsersSimplified(users: any[] | undefined): UserSimplified[] {
  if (!users) return [];

  const usersIsSimplified = users.filter(
    (c): c is UserSimplified => typeof c !== "boolean",
  );
  return usersIsSimplified ?? [];
}
export function isDate(str: string | number | Date): str is Date {
  try {
    return !isNaN(new Date(str).getTime());
  } catch (error) {
    return false;
  }
}
/**
 * if column is null or a number isValid(new Date(columnValue)) returns true
 */
export function datagridColType(columnValue: unknown) {
  if (columnValue === null) return "null";
  if (typeof columnValue !== "string") {
    return typeof columnValue;
  } else {
    if (isDate(columnValue) && !/^\d+$/.test(columnValue)) {
      return "dateTime";
    }
    if (/^\d+$/.test(columnValue)) {
      return "number";
    }
    return "string";
  }
}

export function filterBoolean<A extends any[]>(array: A) {
  return array.filter(Boolean) as {
    [K in keyof A]: Exclude<A[K], undefined | null | false | 0 | "">;
  };
}

type DistributiveUnion<T> = T extends any ? (k: T) => void : never;

export type MergeUnion<T> = DistributiveUnion<T> extends (k: infer U) => void
  ? Readonly<{
      [K in keyof U]: K extends keyof T ? T[K] : U[K] | undefined;
    }>
  : never;

export function isStringifiedObj(str: string) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

export const pluralize = (count: number, noun: string, suffix = "s") =>
  `${count} ${noun}${count !== 1 ? suffix : ""}`;

export const castZero = (num?: number | null) => Number(num || 0);

export const reorderArray = (array: any[], startIdx = 0) => {
  return array.slice(startIdx, array.length).concat(array.slice(0, startIdx));
};

export const padArray = (arr: any[], totalLength: number, padValue = 0) => {
  const minLength = arr?.length > totalLength ? arr.length : totalLength;
  return arr.concat(new Array(minLength).fill(padValue)).slice(0, minLength);
};

export const convertToHoursAndMinutes = (minutes: number, short?: boolean) => {
  const hours = Math.floor(minutes / 60);
  const minRemainder = castZero(Number((minutes % 60).toFixed(0)));
  return `${hours > 0 ? pluralize(hours, short ? "hr" : "hour") : ""} ${
    short
      ? `${minRemainder} min`
      : pluralize(minRemainder, short ? "min" : "minute")
  }`;
};

export const castMinToHours = (num?: number | null) => {
  return castZero(num) / 60;
};

export type RequireOrOptional<
  T,
  Req extends keyof T,
  Opt extends keyof T = never,
> = Omit<T, Req | Opt> & Required<Pick<T, Req>> & Partial<Pick<T, Opt>>;
