import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { ActiveElement, ChartData, ChartEvent } from "chart.js";
import { Chart } from "react-chartjs-2";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useMemo, useRef, useState } from "react";
import { ChevronRight } from "@mui/icons-material";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { useSelector } from "react-redux";
import {
  castZero,
  padArray,
  formatDate,
  reorderArray,
  castMinToHours,
  Navigation,
} from "../../../lib";
import { authSelectors } from "../../../state";
import {
  useGetOrgTermsQuery,
  useGetMyOrgsTermsStatsQuery,
} from "../../../state/rtk-query/state/terms";
import { useMobile } from "../../../themes";
import { TermUsageCard } from "../../cards";
import { useCurOrg } from "../../hooks";
import { ChevronLeftIcon } from "../../icons";
import { OrgTermsModal } from "../../modal";
import { CardStyled } from "../../styled";
import {
  baseBarDataSet,
  onClickGetLabel,
  onHoverUpdateCursor,
} from "../ChartConstants";
import { BarBackgroundPlugin } from "../ChartPlugins";
import {
  defaultChartOptions,
  suggestedAverage,
  CHART_DURATION,
} from "./SupportChartConstants";
import { lastDayOfMonth, parse } from "date-fns";

const numberArr = () =>
  Array.from({ length: CHART_DURATION }).fill(0) as number[];

export const SupportChart = () => {
  const { palette } = useTheme();
  const isMobile = useMobile();
  const isAdmin = useSelector(authSelectors.isAdmin);
  const ref = useRef<ChartJSOrUndefined<"bar", number[], string> | null>(null);

  const isInternal = useSelector(authSelectors.isInternal);
  const org = useCurOrg();
  const isNewAdmire = org?.license_status?.license?.name === "The New Admire";
  const [curIdx, setCurIdx] = useState(0);
  const [initialLoad, setInitialLoad] = useState(false);
  const [hoursUsed, setHoursUsed] = useState(0);
  const [hourAverage, setHoursAverage] = useState(0);
  const [tasks, setTasks] = useState<number[]>(numberArr());
  const [sessions, setSessions] = useState<number[]>(numberArr());
  const [averages, setAverages] = useState<number[]>(numberArr());
  const [labels, setLabels] = useState<string[]>([]);
  const orgIdArg = org?.id ? { orgId: org.id } : skipToken;
  const { currentData: myTerms } = useGetOrgTermsQuery(orgIdArg, {
    refetchOnMountOrArgChange: true,
  });
  const { currentData, isSuccess } = useGetMyOrgsTermsStatsQuery(orgIdArg, {
    refetchOnMountOrArgChange: true,
  });

  const sessionData = currentData?.sessions ?? [];
  const tasksData = currentData?.tasks ?? [];

  const taskTotals = useMemo(
    () =>
      padArray(
        tasksData.map((t) => castMinToHours(t?.total)),
        CHART_DURATION,
      ),
    [tasksData],
  );
  const sessionTotals = useMemo(
    () =>
      padArray(
        sessionData.map((s) => castMinToHours(s?.total)),
        CHART_DURATION,
      ),
    [sessionData],
  );
  const lastValidSession = useMemo(
    () => sessionData.findIndex((s) => !s.valid_row),
    [sessionData],
  );
  const lastValidTask = useMemo(
    () => tasksData.findIndex((t) => !t.valid_row),
    [tasksData],
  );
  const initialOrder = useMemo(
    () =>
      padArray(
        sessionData?.map((s) =>
          formatDate((s.month ?? "")?.split("T")[0], "MMM '`'yy"),
        ),
        CHART_DURATION, // we should always have 12 now from the backend
      ),
    [sessionData],
  );

  const length = initialOrder.length;
  useEffect(() => {
    const row = currentData?.chart_row_start;

    if (!initialLoad && row) {
      setLabels(
        initialOrder.slice(row - 1, length - row > 12 ? row + 11 : length),
      );
      setCurIdx(row > 1 ? row : 0); //rows start at 1
      setInitialLoad(true);
    }
  }, [sessionData]);

  useEffect(() => {
    const ending = length - curIdx > 12 ? curIdx + 12 : length;
    setLabels(() => initialOrder.slice(curIdx, ending));

    setSessions(() => reorderArray(sessionTotals, curIdx));

    setTasks(() => reorderArray(taskTotals, curIdx));

    const averagesMonthOverMonth = [...sessionData, ...tasksData].reduce(
      (acc: number[], t) => {
        if (!acc || !t.row || t.row - 1 < 0) return [];

        acc[t.row - 1] =
          castZero(acc[t.row - 1]) + castMinToHours(t?.avg_month_over_month);
        return acc;
      },
      [] as number[],
    );
    // minus 1 since the last month is always zero, based on the BE since in order to get this months timers we need this full month
    setAverages(
      reorderArray(
        padArray(
          averagesMonthOverMonth.splice(
            0,
            castZero(averagesMonthOverMonth?.length) - 1,
          ),
          CHART_DURATION,
        ),
        curIdx,
      ),
    );

    setHoursAverage(
      castMinToHours(
        Number(sessionData[lastValidSession - 1]?.avg_month_over_month ?? 0) +
          Number(tasksData[lastValidTask - 1]?.avg_month_over_month ?? 0),
      ),
    );
  }, [isSuccess, curIdx]);

  useEffect(() => {
    const used = [...sessions, ...tasks].reduce((a, b) => a + b, 0);
    setHoursUsed(() => used);
  }, [sessions]);

  const includedHours = useMemo(
    () => castZero(myTerms?.total_sessions),
    [myTerms?.total_sessions],
  );

  const data = {
    labels,
    datasets: [
      baseBarDataSet({
        label: "Sessions",
        backgroundColor: palette.accent[600],
        data: sessions,
        order: 2,
        borderWidth: 0,
        borderRadius: 10,
      }),
      baseBarDataSet({
        label: "Tasks",
        backgroundColor: palette.info.main,
        data: tasks,
        order: 1,
      }),
      {
        type: "line",
        label: "Your average",
        borderColor: palette.accent[500],
        borderWidth: 2,
        animation: { easing: "easeInCubic" },
        data: averages,
        pointStyle: "dash",
        borderDash: [5, 5],
      },
      suggestedAverage(
        palette.grey[700],
        Number(castZero(myTerms?.suggested_average)),
      ),
    ],
  } as ChartData<"bar", number[], string>;

  const orgName = org?.name;
  const options = useMemo(
    () => ({
      ...defaultChartOptions,
      onClick: (e: any) => {
        if (!isAdmin) return;
        const month = onClickGetLabel(e);
        if (!orgName || !month) return;

        const date = parse(month, "MMM `yy", new Date()).toLocaleDateString();
        Navigation.go(`/timer/admin`, {
          query: {
            view: "table",
            time_filter: "all_orgs",
            month: date,
            filter: JSON.stringify([
              {
                id: 123,
                field: "org_name",
                operator: "equals",
                value: orgName,
              },
              {
                id: "startMonth",
                field: "start_time",
                operator: "onOrAfter",
                value: date,
              },
              {
                id: "endMonth",
                field: "start_time",
                operator: "onOrBefore",
                value: lastDayOfMonth(new Date(date ?? "")),
              },
            ]),
          },
        });
      },

      onHover: (e: ChartEvent, el: ActiveElement[]) =>
        onHoverUpdateCursor(e, el, isAdmin),
      scales: {
        ...defaultChartOptions.scales,
        suggestedAverage: {
          ticks: { display: false },
          grid: { display: false, drawTicks: false },
          border: { display: false },
        },
      },
    }),
    [orgName, isAdmin],
  );
  const changeMonths = (direction: "pre" | "next") => {
    if (direction === "pre") {
      setCurIdx((cur) => (cur > 0 ? cur - 1 : cur));
    } else {
      setCurIdx((cur) => (cur < CHART_DURATION ? cur + 1 : cur));
    }
  };

  const endIndex = curIdx + CHART_DURATION;
  useEffect(() => {
    return () => {
      if (ref.current) return ref.current.destroy();
    };
  }, []);
  return (
    <CardStyled
      sx={{
        p: 2,
        pb: 0,
        gap: 1,
        display: "flex",
        minHeight: isInternal ? "65vh" : "100%",
        maxHeight: "50%",
        flexDirection: "column",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h3" fontWeight={600}>
          SUPPORT USAGE
        </Typography>
        <Box>
          <IconButton
            disabled={curIdx - 1 < 0}
            onClick={() => changeMonths("pre")}
            title="Previous month"
          >
            <ChevronLeftIcon
              style={{ fontSize: isMobile ? "1rem" : ".8rem" }}
            />
          </IconButton>
          <IconButton
            onClick={() => changeMonths("next")}
            disabled={
              length - curIdx + 1 < CHART_DURATION ||
              labels?.length % CHART_DURATION > 0 ||
              endIndex >= length - 1
            }
            title="Next month"
          >
            <ChevronRight style={{ fontSize: isMobile ? "1rem" : ".8rem" }} />
          </IconButton>
        </Box>
      </Stack>
      <OrgTermsModal
        terms={myTerms}
        totalHours={includedHours}
        isNewAdmire={isNewAdmire}
      />
      <TermUsageCard
        hoursUsed={hoursUsed}
        hoursAvailable={includedHours - hoursUsed}
        hoursAverage={castZero(hourAverage)}
        suggestedAvg={castZero(myTerms?.suggested_average)}
        isNewAdmire={isNewAdmire}
      />
      <Stack direction={"column"} width={"100%"} height={350} maxHeight={"50%"}>
        <Chart
          type="bar"
          options={options}
          data={data}
          plugins={[BarBackgroundPlugin]}
        />
      </Stack>
    </CardStyled>
  );
};
