import {
  Modal as MUIModal,
  Box,
  ModalProps as MUIModalProps,
} from "@mui/material";
import { ReactNode } from "react";
import { useMobile, useTablet } from "../../themes";

export interface ModalProps {
  open: boolean;
  onClose: (event?: any, reason?: "backdropClick" | "escapeKeyDown") => void;
  children: ReactNode;
  pcWidth?: string | number;
  sx?: any;
  props?: Partial<MUIModalProps>;
}

function stopProp(e: any) {
  e?.stopPropagation();
}

export const Modal = ({
  open,
  onClose,
  children,
  pcWidth = "40%",
  sx,
  props,
}: ModalProps) => {
  const isTablet = useTablet();
  const isMobile = useMobile();
  const isSmallerThanPc = isTablet || isMobile;
  // remove blue-outline https://github.com/mui/material-ui/issues/11504#issuecomment-390506409
  return (
    <MUIModal open={open} onClose={onClose} {...props} disableAutoFocus>
      <Box
        onClick={stopProp}
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isSmallerThanPc ? "90%" : pcWidth,
          height: "fit-content",
          backgroundColor: "background.paper",
          boxShadow: "0px 1px 4px rgba(99, 100, 105, 0.2);",
          borderRadius: "16px",
          p: 4,
          px: "16px",
          ...sx,
        }}
      >
        {children}
      </Box>
    </MUIModal>
  );
};
