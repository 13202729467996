import {
  Box,
  Button,
  FormControlLabel,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { ChevronDown } from "../icons";
import { useCallback, useMemo, useState } from "react";
import { PickersShortcutsItem } from "@mui/x-date-pickers";

import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { DateRange } from "@mui/lab/DateRangePicker";
import { useMobile } from "../../themes";
import { CustomCheckBox } from "../styled";

interface SortableButtonProps<
  DI extends readonly { label: string; id: number | string }[] | undefined,
  IsDate extends true | undefined,
> {
  title: string;
  onClick?: (
    value: DI extends readonly { label: string; id: number | string }[]
      ? DI[number]["id"]
      : string,
    checked: boolean,
  ) => void;
  onChange?: (value: DateRange<any>) => void;
  isDropDown?: boolean;
  isDate?: IsDate;
  items?: string[];
  displayItems?: DI;
  currentSort?: DI extends undefined
    ? IsDate extends undefined
      ? string[]
      : Date[]
    : DI extends readonly { label: string; id: string }[]
    ? string[]
    : number[];
  isSingleSelect?: boolean;
  removeTitle?: boolean;
  singleSelectSort?: string;
  buttonPadding?: number;
}

export const SortableButton = <
  DI extends
    | readonly { label: string; id: number | string }[]
    | undefined = undefined,
  IsDate extends true | undefined = undefined,
>({
  title: _title,
  onClick,
  onChange,
  isDropDown = false,
  isDate,
  items,
  displayItems,
  currentSort,
  isSingleSelect,
  singleSelectSort,
  buttonPadding,
  removeTitle,
}: SortableButtonProps<DI, IsDate>) => {
  const { palette } = useTheme();
  const isMobile = useMobile();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCalendarChange = useCallback(
    (values: DateRange<any>) => {
      if (
        isDate &&
        onChange &&
        (values?.filter(Boolean).length === 2 ||
          (values[0] === null && values[1] === null)) // on reset
      ) {
        onChange(values);
        handleClose();
      }
    },
    [isDate, onChange],
  );

  const titleItems = useMemo(() => {
    if (!Array.isArray(currentSort)) {
      return [];
    }
    currentSort?.length
      ? displayItems?.length
        ? currentSort
            .map(
              (i) =>
                displayItems?.find(
                  (item) => item?.id === i || item?.label === i,
                )?.label,
            )
            .filter(Boolean)
        : currentSort
      : undefined;
  }, [currentSort]);

  const showColon = removeTitle ? "" : ": ";

  const title = `${removeTitle ? "" : _title}${
    !isDate && currentSort?.length && titleItems?.length
      ? titleItems?.length === 1
        ? `${showColon}${titleItems[0]}`
        : titleItems?.length > 1
        ? `${showColon}${titleItems[0]} + ${titleItems?.length - 1}`
        : ""
      : isDate && currentSort
      ? currentSort?.length === 2
        ? `${showColon}${new Date(
            currentSort[0],
          ).toLocaleDateString()} - ${new Date(
            currentSort[1],
          ).toLocaleDateString()}`
        : ""
      : singleSelectSort
      ? `${showColon}${singleSelectSort}`
      : ""
  }`;

  const today = new Date();
  const shortcutsItems: PickersShortcutsItem<DateRange<any>>[] = [
    {
      label: "This Week",
      getValue: () => {
        return [startOfWeek(today), endOfWeek(today)];
      },
    },
    {
      label: "Last Week",
      getValue: () => {
        const prevWeek = new Date().setDate(today.getDate() - 7);
        return [startOfWeek(prevWeek), endOfWeek(prevWeek)];
      },
    },
    {
      label: "Last 7 Days",
      getValue: () => {
        return [new Date(new Date().setDate(today.getDate() - 7)), today];
      },
    },
    {
      label: "Current Month",
      getValue: () => {
        return [startOfMonth(today), endOfMonth(today)];
      },
    },
    {
      label: "Next Month",
      getValue: () => {
        const startOfNextMonth = new Date(
          endOfMonth(today).setDate(endOfMonth(today).getDate() + 1),
        );
        return [startOfNextMonth, endOfMonth(startOfNextMonth)];
      },
    },
    { label: "Reset", getValue: () => [null, null] },
  ];

  const anchorOrigin = { vertical: "bottom", horizontal: "left" } as const;
  const transformOrigin = {
    vertical: "top",
    horizontal: "left",
  } as const;

  return (
    <Stack width={"auto"}>
      <Button
        onClick={handleClick}
        color="black"
        aria-expanded={open ? "true" : undefined}
        sx={{
          width: "fit-content",
          height: "max-content",
          ...(buttonPadding !== undefined ? { p: buttonPadding } : {}),
        }}
      >
        <Typography
          fontSize={isMobile ? 14 : 16}
          width={"100%"}
          fontWeight={500}
          color={
            (currentSort && currentSort?.length >= 1) ||
            (isSingleSelect && singleSelectSort)
              ? "primary"
              : "inherit"
          }
          display={"flex"}
          alignItems={"center"}
          noWrap
        >
          <Box component={"span"}>{title}</Box>

          <ChevronDown color={palette.primary.main} />
        </Typography>
      </Button>
      {isDropDown && items && items?.length > 0 ? (
        <Menu
          aria-labelledby="sortable-button-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          sx={{ maxHeight: items?.length > 6 ? "268px" : "100%" }}
        >
          {items.map((i, idx) => (
            <MenuItem
              key={idx}
              value={i}
              sx={{
                ":hover": { bgcolor: palette.accent[50] },
                bgcolor:
                  i === singleSelectSort ? palette.accent[50] : undefined,
              }}
              onClick={
                isSingleSelect
                  ? () => {
                      if (!onClick) return handleClose();
                      const checked = i !== singleSelectSort;
                      onClick(i as any, checked);
                      return handleClose();
                    }
                  : undefined
              }
            >
              {isSingleSelect ? (
                i
              ) : (
                <FormControlLabel
                  control={
                    <CustomCheckBox
                      onChange={(_e, checked) => {
                        return onClick && onClick(i as any, checked);
                      }}
                      checked={Boolean(
                        currentSort &&
                          Array.isArray(currentSort) &&
                          (currentSort as unknown[])?.includes(i),
                      )}
                    />
                  }
                  label={i}
                />
              )}
            </MenuItem>
          ))}
        </Menu>
      ) : isDropDown && displayItems && displayItems?.length > 0 ? (
        <Menu
          aria-labelledby="sortable-button-menu-display"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          sx={{ maxHeight: displayItems?.length > 6 ? "268px" : "100%" }}
        >
          {displayItems.map((i) => (
            <MenuItem
              key={i.id}
              value={i.id}
              sx={{ ":hover": { bgcolor: palette.accent[50] } }}
            >
              <FormControlLabel
                control={
                  <CustomCheckBox
                    onChange={(_e, checked) => {
                      return onClick && onClick(i.id as any, checked);
                    }}
                    checked={Boolean(
                      currentSort &&
                        Array.isArray(currentSort) &&
                        (currentSort as unknown[])?.includes(i.id),
                    )}
                  />
                }
                label={i.label}
              />
            </MenuItem>
          ))}
        </Menu>
      ) : isDate ? (
        <Popover
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          onClose={handleClose}
        >
          <StaticDateRangePicker
            slotProps={{
              shortcuts: {
                items: isMobile ? undefined : shortcutsItems,
              },
              actionBar: { actions: [] },
            }}
            calendars={isMobile ? 1 : 2}
            onChange={onCalendarChange}
            value={(currentSort as DateRange<any>) ?? [null, null]}
          />
        </Popover>
      ) : null}
    </Stack>
  );
};
