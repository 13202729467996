import { TextField } from "formik-mui";
import { FormField } from "./FormField";
import useOs from "../../hooks/useOs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CountryCodes } from "../../../lib/CountryCodes";
import { InputAdornment, MenuItem, Select, Typography } from "@mui/material";
import { SmallDownIcon } from "./SmallDownIcon";
import { PropsOf } from "@emotion/react";

function PhoneWithCCField({
  countryCodeName,
  ...props
}: PropsOf<typeof TextField> & { countryCodeName: string }) {
  const {
    form: { setFieldValue, getFieldMeta, status },
  } = props;
  const countryCode = getFieldMeta(countryCodeName);
  const os = useOs();
  const [countryCodeIndex, setCountryCodeIndex] = useState(0);
  useEffect(() => {
    const findIndex = CountryCodes.findIndex(
      (cc) => cc.country_code === countryCode.value,
    );
    setCountryCodeIndex(findIndex);
  }, []);
  const renderValue = useCallback(
    (value: unknown) => {
      const typProps =
        os === "Windows"
          ? { variant: "body1" as "body1", marginTop: 0.3 }
          : { variant: "h1" as "h1", marginTop: 0.7 };
      return (
        <Typography {...typProps}>
          {os === "Windows"
            ? CountryCodes[value as number]?.country
            : CountryCodes[value as number]?.flag}
        </Typography>
      );
    },
    [os],
  );
  const onChange = useCallback(
    (e: any) => {
      setCountryCodeIndex(e.target.value);
      setFieldValue(countryCodeName, CountryCodes[e.target.value].country_code);
    },
    [countryCodeName],
  );

  const ccMenuItems = useMemo(
    () =>
      CountryCodes.map((cc, i) => (
        <MenuItem key={i} value={i}>
          <Typography align="left" overflow="hidden" variant="labelSmall">
            {os === "Windows"
              ? cc.label + "  +" + cc.country_code
              : cc.flag + " " + cc.label}
          </Typography>
        </MenuItem>
      )),
    [os],
  );

  const InputProps = useMemo(() => {
    return {
      startAdornment: (
        <InputAdornment position="start">
          <Select
            value={countryCodeIndex}
            IconComponent={SmallDownIcon}
            onChange={onChange}
            renderValue={renderValue}
            sx={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              "& > svg": {
                top: ".5em",
              },
            }}
            disabled={status === "disabled"}
          >
            {ccMenuItems}
          </Select>
        </InputAdornment>
      ),
      sx: { p: 0 },
    };
  }, [countryCodeIndex, status === "disabled"]);
  return <TextField {...props} InputProps={InputProps} />;
}

export namespace PhoneWithCountryCodeField {
  export type Props = FormField.FieldProps<typeof PhoneWithCCField>;
}

export function PhoneWithCountryCodeField(
  props: PhoneWithCountryCodeField.Props,
) {
  return <FormField component={PhoneWithCCField} {...props} />;
}
