import {
  DataGridProProps,
  GridColDef,
  GridColumnVisibilityModel,
  GridRowClassNameParams,
  GridState,
  GridValidRowModel,
  useGridApiRef,
  getGridStringOperators,
} from "@mui/x-data-grid-pro";
import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { QueryParams, buildParams } from "../../lib/requestBuilder";
import { ToolBar, ToolBarProps } from "./ToolBar";
import { StyledDataGrid } from "./DataGrid.styles";
import { Stack, Typography } from "@mui/material";
import loadAnimation from "../../assets/img/AdmireLoader.gif";
import { useDatagridProps } from "../hooks";

interface Props {
  rows: Record<string, any>[];
  columns: GridColDef[];
  rowCount: number;
  setStringified?: Dispatch<SetStateAction<string>>;
  setQueryParams?: Dispatch<SetStateAction<QueryParams | undefined>>;
  currentParams: QueryParams | string | undefined;
  showToolbar?: boolean;
  props?: Partial<DataGridProProps>;
  toolbarProps?: Partial<ToolBarProps>;
  updateToolbarProps?: boolean;
  isFetching: boolean;
  hideColumns?: GridColumnVisibilityModel | undefined;
  updateHideColumns?: boolean;
  getRowClassName?:
    | ((params: GridRowClassNameParams<GridValidRowModel>) => string)
    | undefined;
}

const pageSizeOptions = [10, 25, 50, 100, 250, 500];

const getPageSize = (params?: QueryParams | string | undefined) => {
  if (!params || typeof params === "string" || !params.range?.[1]) return 25;
  return params.range?.[1];
};

export const Loading = memo(() => {
  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
      sx={{
        "& > img:hover": {
          transition: "all .5s",
          transform: "rotate(360deg)",
        },
      }}
    >
      <img
        style={{
          width: 100,
          height: 100,
        }}
        src={loadAnimation}
        alt="sidebar"
      />
    </Stack>
  );
});

const Default = () => {
  // No row's flashes right before the data is displayed it seems like it's a server-side issue
  // see: https://github.com/mui/mui-x/issues/4309#issuecomment-1172401858
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 300);
    return () => clearTimeout(timeout);
  }, []);

  if (!show) return null;
  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
    >
      <Typography variant="subtitle1">No Rows Found</Typography>
    </Stack>
  );
};

const stringOperators = getGridStringOperators().filter(
  (fo) => fo.value !== "contains",
);

export const ServerDataGrid = ({
  rows,
  columns,
  rowCount,
  setStringified,
  setQueryParams,
  currentParams,
  props,
  toolbarProps,
  updateToolbarProps = false,
  isFetching,
  hideColumns,
  updateHideColumns = false,
  getRowClassName,
}: Props) => {
  const apiRef = useGridApiRef();
  const [page, setPage] = useState(0);
  const { slotProps: _slotProps, autosizeOptions } = useDatagridProps();
  const initialPageSize = useMemo(() => getPageSize(currentParams), []);
  const [pageSize, setPageSize] = useState(initialPageSize);

  const handleQuickSearch = useCallback(
    (e: any) => apiRef.current.setQuickFilterValues([e.target.value]),
    [apiRef],
  );

  const handleStateChange = (s: GridState, _e: any, _d: any) => {
    const cur = JSON.stringify(currentParams);
    const { stringified, queryParams } = buildParams(s);
    if (setStringified && cur !== stringified) {
      setStringified(stringified);
    }
    if (setQueryParams && cur !== JSON.stringify(queryParams)) {
      setQueryParams(queryParams);
    }
    return () => {
      return;
    };
  };

  const toolProps = useMemo(() => ({ ...toolbarProps }), [updateToolbarProps]);
  const paginationModel = useMemo(() => ({ page, pageSize }), [page, pageSize]);
  const handlePageChange = useCallback(
    (p: { page: number; pageSize: number }) => {
      setPage(p.page);
      setPageSize(p.pageSize);
    },
    [],
  );

  const slots = useMemo(
    () => ({
      toolbar: ToolBar,
      noResultsOverlay: isFetching ? Loading : Default,
      noRowsOverlay: isFetching ? Loading : Default,
    }),
    [Loading, isFetching, Default, ToolBar],
  );
  const slotProps = useMemo(
    () => ({
      ..._slotProps,
      toolbar: {
        handleQuickSearch: handleQuickSearch,
        ...toolProps,
      },
    }),
    [toolProps, handleQuickSearch, _slotProps],
  );

  const cols = useMemo(
    () =>
      columns.map((col) =>
        !col.type || col.type === "string"
          ? { ...col, filterOperators: stringOperators }
          : col,
      ),
    [columns],
  );
  const hideCols = useMemo(() => hideColumns, [updateHideColumns]);

  const [hiddenColumns, setHiddenColumns] = useState(hideCols);

  return (
    <StyledDataGrid
      apiRef={apiRef}
      rowCount={rowCount}
      rows={rows.length ? rows : []}
      columns={cols}
      pagination
      pageSizeOptions={pageSizeOptions}
      paginationModel={paginationModel}
      onPaginationModelChange={handlePageChange}
      sortingMode="server"
      filterMode="server"
      paginationMode="server"
      onStateChange={handleStateChange}
      slots={slots}
      autosizeOnMount={true}
      autosizeOptions={autosizeOptions}
      slotProps={slotProps}
      columnVisibilityModel={hiddenColumns}
      onColumnVisibilityModelChange={setHiddenColumns}
      getRowClassName={getRowClassName}
      {...props}
    />
  );
};
