import { Grid, Typography, Box, Link } from "@mui/material";
import { useEffect } from "react";
import { actions } from "../../state/rtk-query/state";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { ArrayElement } from "../../global";
import { GetTaskApiResponse } from "../../state/rtk-query/state/tasks";
import { NameWithAvatar } from "../icons";
import { GetSessionsByOrgIdApiResponse } from "../../state/rtk-query/state/session";
import { BaseForm, BaseModal } from "./BaseForm";
import { SelectField } from "./fields";
import { useUsersByOrgsList } from "../hooks/useNewLists";
import { TopLabel } from "./fields/TopLabel";

const { useManageAssigneesMutation } = actions;

type AttendeeAndAssigneeFormProps = {
  open: boolean;
  onClose: () => void;
  admire_assignee_id?: number;
  client_assignee_id?: number;
  other_users?: { user_id?: number | null; email?: string | null }[];
  assignee_type: "session" | "task";
  id?: number;
  orgId?: number;
  onlyInternal?: boolean;
};

export function AssigneeForm({
  task,
  open,
  onClose,
}: {
  task?: GetTaskApiResponse;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <AttendeeAndAssigneeForm
      assignee_type="task"
      onClose={onClose}
      open={open}
      admire_assignee_id={
        task?.assigned_to_user?.id || (task as any)?.assigned_to
      }
      client_assignee_id={
        task?.client_assignee_user?.id || (task as any)?.client_assignee
      }
      id={task?.id}
      orgId={task?.org_id}
      onlyInternal={task?.type === "internal_task"}
      other_users={task?.task_watchers || undefined}
    />
  );
}

export function AttendeeForm({
  session,
  open,
  onClose,
}: {
  session?: ArrayElement<GetSessionsByOrgIdApiResponse["rows"]>;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <AttendeeAndAssigneeForm
      assignee_type="session"
      onClose={onClose}
      open={open}
      admire_assignee_id={session?.assigned_to_user?.id}
      client_assignee_id={session?.client_assignee_user?.id}
      id={session?.id}
      orgId={session?.org_id}
      other_users={session?.session_invitees}
    />
  );
}

function UserRenderItem({ item }: { item: { id: number } }) {
  return <NameWithAvatar user={item} />;
}

function AttendeeAndAssigneeForm({
  assignee_type,
  onClose,
  open,
  admire_assignee_id,
  client_assignee_id,
  other_users,
  id,
  orgId,
  onlyInternal,
}: AttendeeAndAssigneeFormProps) {
  const initialValues = {
    admire_assignee_id,
    client_assignee_id,
    other_users: other_users?.map((u) => u.user_id).filter(Boolean) || [],
  };

  const emailOnlyUsers =
    other_users?.filter((u) => !u.user_id && u.email) || [];
  const isInternal = useSelector(authSelectors.isInternal);
  const [updateAssignees] = useManageAssigneesMutation();
  const admireUsers = useUsersByOrgsList(-1);
  const orgUsers = useUsersByOrgsList(orgId, !orgId);

  useEffect(() => {
    if (open && !isInternal && onlyInternal) {
      onClose();
    }
  }, [isInternal, onlyInternal, onClose, open]);

  return (
    <BaseModal open={open} onClose={onClose} title="Manage people">
      <BaseForm
        onClose={onClose}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values) => {
          if (values === initialValues) {
            onClose();
          }
          id &&
            orgId &&
            updateAssignees({
              id,
              orgId,
              body: {
                admire_assignee_id: values.admire_assignee_id || -1,
                client_assignee_id: values.client_assignee_id || -1,
                other_users: values.other_users?.filter(
                  (v) =>
                    ![
                      values.admire_assignee_id,
                      values.client_assignee_id,
                    ].includes(v!),
                ) as number[],
                assignee_type,
              },
            }).then(() => onClose());
        }}
      >
        {({ values }) => {
          return (
            <Grid container rowSpacing={1.5} columnSpacing={2}>
              <SelectField
                name="admire_assignee_id"
                items={admireUsers.list}
                md12
                topLabel
                readOnly={!isInternal}
                RenderItem={UserRenderItem}
              />
              {!onlyInternal && (
                <SelectField
                  name="client_assignee_id"
                  items={orgUsers.list}
                  md12
                  topLabel
                  RenderItem={UserRenderItem}
                />
              )}
              <SelectField
                name="other_users"
                items={orgUsers.list}
                additionalItems={admireUsers.list}
                multiple
                multiline
                md12
                topLabel={assignee_type === "task" ? "Watchers" : "Invitees"}
                itemProps={(_, user) => {
                  const dontShow =
                    (!user.isInternal && onlyInternal) ||
                    (user.isInternal && !isInternal);
                  return {
                    children: <NameWithAvatar user={user} />,
                    displayNone:
                      user.id === values.client_assignee_id ||
                      user.id === values.admire_assignee_id ||
                      dontShow,
                  };
                }}
                renderValue={(value) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {value
                      ?.filter(
                        (v) =>
                          ![
                            values.client_assignee_id,
                            values.admire_assignee_id,
                          ].includes(v),
                      )
                      .map((v) => (
                        <NameWithAvatar
                          key={v}
                          user={admireUsers.get(v) || orgUsers.get(v)}
                        />
                      ))}
                  </Box>
                )}
              />
              {!!emailOnlyUsers?.length && (
                <Grid item md={12}>
                  <TopLabel label="Non User Invitees">
                    {emailOnlyUsers.map(({ email }) => (
                      <Typography
                        fontSize={12}
                        lineHeight={0}
                        my={1}
                        key={email!}
                      >
                        {email}
                        <Link
                          href={`/users?userType=client&action=add_user&email=${email}&orgId=${orgId}`}
                          target="_blank"
                          ml={1}
                        >
                          Add as user
                        </Link>
                      </Typography>
                    ))}
                  </TopLabel>
                </Grid>
              )}
            </Grid>
          );
        }}
      </BaseForm>
    </BaseModal>
  );
}
