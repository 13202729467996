import { api } from "../api";
export const addTagTypes = ["preferences"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getMyPreferences: build.query<
        GetMyPreferencesApiResponse,
        GetMyPreferencesApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/preferences/mine`,
        }),
        providesTags: ["preferences"],
      }),
      updateMyPreferences: build.mutation<
        UpdateMyPreferencesApiResponse,
        UpdateMyPreferencesApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/preferences/${queryArg.id}`,
          method: "PUT",
        }),
        invalidatesTags: ["preferences"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetMyPreferencesApiResponse = /** status 200 Show Preferences */ {
  id?: number;
  preference_type_id?: number;
  value: boolean;
  name?: string;
  internal_name?: string;
  description?: string;
  type?: "email" | "user" | "notification";
  user_access?: "internal" | "external" | "all";
}[];
export type GetMyPreferencesApiArg = {
  /** organization id */
  orgId: number;
};
export type UpdateMyPreferencesApiResponse =
  /** status 204 An empty response */ void;
export type UpdateMyPreferencesApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export const {
  useGetMyPreferencesQuery,
  useLazyGetMyPreferencesQuery,
  useUpdateMyPreferencesMutation,
} = injectedRtkApi;
