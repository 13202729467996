import { isValid } from "date-fns";
import { useLocation, Navigation } from "../../lib";
import { useCallback, useMemo } from "react";

export function useQuery<Q extends Record<string, any> = Record<string, any>>(
  ...keysToUse: (keyof Q)[]
): [
  query: Q,
  setQuery: (query: Partial<Q>, ...resetKeys: (keyof Q)[]) => void,
  replaceQuery: (query: Partial<Q>, ...resetKeys: (keyof Q)[]) => void,
] {
  const { query, pathname, params, hash } = useLocation({
    parseBooleans: true,
    parseNumbers: true,
  });
  if (query.orgs) {
    if (typeof query.orgs === "string" && query.orgs.includes(",")) {
      query.orgs = query.orgs.split(",");
    } else if (typeof query.orgs === "number") {
      query.orgs = [query.orgs];
    }
  }
  const q = useMemo(() => {
    return Object.fromEntries(
      keysToUse
        .filter((key) => key in query)
        .map((key) => [key, parseStringsAndDates(query[key as string])]),
    );
  }, [query, keysToUse.join("")]);
  const setQuery = useCallback(
    (newQuery: Partial<Q>, ...resetKeys: (keyof Q)[]) => {
      const query_ = Object.fromEntries(
        Object.entries({ ...query, ...newQuery }).map(([key, val]) => [
          key,
          retainStringsAndDates(val),
        ]),
      );
      resetKeys.forEach((key) => {
        delete query_[key as string];
      });
      if (query_.orgs && typeof query_.orgs === "string") {
        if (query_.orgs.includes(",")) {
          query_.orgs = query_.orgs.split(",");
        } else {
          query_.orgs = [query_.orgs];
        }
      }
      Navigation.go(pathname, {
        params,
        query: query_,
        hash,
      });
    },
    [query, pathname, hash, params],
  );
  const replaceQuery = useCallback(
    (newQuery: Partial<Q>, ...resetKeys: (keyof Q)[]) => {
      const query_ = Object.fromEntries(
        Object.entries({ ...query, ...newQuery }).map(([key, val]) => [
          key,
          retainStringsAndDates(val),
        ]),
      );
      resetKeys.forEach((key) => {
        delete query_[key as string];
      });
      Navigation.replace(pathname, {
        params,
        query: query_,
        hash,
      });
    },
    [query, pathname, hash, params],
  );
  return [q as Q, setQuery, replaceQuery];
}

export function useQueryWithDefaults<
  Q extends Record<string, any> = Record<string, any>,
>(
  defaults: Partial<Q>,
  dependencies: any[] = [],
  allowNonDefaultEmptyArray = false,
) {
  const [query, setQuery] = useQuery(...Object.keys(defaults));
  return useMemo(() => {
    const q = { ...defaults, ...query };
    const setQ = (newQuery: Partial<Q>, ...resetKeys: (keyof Q & string)[]) => {
      Object.keys(newQuery).forEach((key) => {
        if (defaults[key] !== undefined) {
          if (JSON.stringify(defaults[key]) === JSON.stringify(newQuery[key])) {
            (newQuery as any)[key] = undefined;
          }
        } else if (
          !allowNonDefaultEmptyArray &&
          Array.isArray(newQuery[key]) &&
          newQuery[key]?.length === 0
        ) {
          (newQuery as any)[key] = undefined;
        }
      });
      setQuery(newQuery, ...resetKeys);
    };
    return [
      q,
      setQ,
      function () {
        setQ({}, ...(Object.keys(defaults) as (keyof Q & string)[]));
      },
      Object.keys(defaults).every((key) => q[key] === defaults[key]),
    ] as [
      query: Q,
      setQuery: (query: Partial<Q>, ...resetKeys: (keyof Q)[]) => void,
      clear: () => void,
      isClear: boolean,
    ];
  }, [query, setQuery, allowNonDefaultEmptyArray, ...dependencies]);
}

export function useQueryParam<Value>(
  param: string,
): [value: Value, setQueryParam: (newValue?: Value) => void] {
  const { query, pathname, params, hash } = useLocation({
    parseBooleans: true,
    parseNumbers: true,
  });
  const value: Value = useMemo(
    () => parseStringsAndDates(query[param]),
    [query, param],
  );
  const setQueryParam = useCallback(
    (newValue?: Value) => {
      const { [param]: _, ...rest } = query;
      const newQuery =
        newValue === undefined
          ? rest
          : { ...rest, [param]: retainStringsAndDates(newValue) };
      Navigation.go(pathname, {
        params,
        query: newQuery,
        hash,
      });
    },
    [query, params, pathname, param, hash],
  );
  return [value, setQueryParam];
}

const stringIndicator = '"';
function retainStringsAndDates(value: any): any {
  if (!value) return value;
  if (!Array.isArray(value)) {
    if (typeof value === "string") {
      if (!!Number(value) || value === "true" || value === "false") {
        return `${stringIndicator}${value}${stringIndicator}`;
      }
    } else if (value instanceof Date) {
      return value.toISOString();
    }
    return value;
  }
  return value.map((v) => retainStringsAndDates(v));
}

function parseStringsAndDates(value: any): any {
  if (!value) return value;
  if (!Array.isArray(value)) {
    if (
      typeof value === "string" &&
      value.startsWith(stringIndicator) &&
      value.endsWith(stringIndicator)
    ) {
      return value.slice(1, -1);
    } else if (typeof value === "string" && isISODateString(value)) {
      return new Date(value);
    }
    return value;
  }
  return value.map((v) => parseStringsAndDates(v));
}

function isISODateString(str: string) {
  const regex =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{3})?(Z|[+-]\d{2}:\d{2})?$/;
  return regex.test(str) && isValid(new Date(str));
}
