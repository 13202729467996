import { useEffect, useState } from "react";
import {
  useCreateTaskChatMutation,
  useUpdateTaskChatMutation,
} from "../../state/rtk-query/state/tasks";
import { TaskValidationProps } from "../task";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";

export function useOptimisticChats(
  taskId: number,
  taskChats: TaskValidationProps["taskChats"]["data"],
) {
  const [
    createChat,
    { isLoading: isCreatingChat, originalArgs, isError: errorCreatingChat },
  ] = useCreateTaskChatMutation();

  const [
    updateChat,
    {
      isLoading: isUpdatingChat,
      originalArgs: updateChatArgs,
      isError: errorUpdatingChat,
    },
  ] = useUpdateTaskChatMutation();

  const [chats, setChats] = useState(taskChats?.rows || []);

  useEffect(() => {
    setChats(taskChats?.rows || []);
  }, [taskChats?.rows]);

  const userInfo = useSelector(authSelectors.userInfo);

  useEffect(() => {
    if (isCreatingChat && originalArgs?.body) {
      setChats((prev) => [
        {
          ...originalArgs.body,
          has_media: !!originalArgs.body.has_media,
          user: userInfo,
          task_id: taskId,
          created_at: new Date().toISOString(),
          id: 0,
        },
        ...prev,
      ]);
    }
    if (isUpdatingChat && updateChatArgs?.body) {
      const chatId = updateChatArgs.id;

      const chatIndex = chats.findIndex((c) => c.id === chatId);
      if (chatIndex > -1) {
        setChats((prev) => [
          ...prev.slice(0, chatIndex),
          {
            ...updateChatArgs.body,
            has_media: !!updateChatArgs.body.has_media,
            user: userInfo,
            task_id: taskId,
            created_at: new Date().toISOString(),
            id: chatId,
          },
          ...prev.slice(chatIndex + 1),
        ]);
      }
    }
  }, [isCreatingChat, isUpdatingChat]);

  useEffect(() => {
    if (errorCreatingChat || errorUpdatingChat) {
      setChats(taskChats?.rows || []);
    }
  }, [errorCreatingChat, errorUpdatingChat]);
  return { chats, createChat, updateChat };
}
