import { Multiple, Option, SelectionModel, MenuItemProps } from "./types";
import { DropDownItem } from "./DropDownItem";
import { MenuProps } from "@mui/material";
import React, { useState } from "react";
import { useMenuItems } from "./useMenuItems";
import { MenuSearch } from "./MenuSearch";
import { DropDownShell } from "./DropDownShell";

export namespace DropDownButton {
  export type Props<O extends Option, M extends Multiple> = {
    items: O[] | readonly O[];
    additionalItems?: O[];
    children: React.ReactNode;
    multiple?: M;
    allowDeselection?: boolean;
    rerenderKey?: any;
    itemProps?: (
      params: Omit<DropDownItem.Props<O, M>, keyof MenuItemProps> & { item: O },
      item: O,
    ) => MenuItemProps & {
      rerenderKey?: any;
      renderItem?: React.ReactNode;
      displayNone?: boolean;
    };
    menuProps?: Omit<MenuProps, "children" | "open" | "onClose">;
    menuHeader?: React.ReactNode;
    withSearch?: boolean;
    showDeleted?: boolean;
    capitalize?: boolean;
    RenderItem?: (
      props: Omit<DropDownItem.Props<O, M>, keyof MenuItemProps> & { item: O },
    ) => React.ReactNode;
  } & SelectionModel<O, M>;
}

function DropDown<O extends Option, M extends boolean | undefined = undefined>(
  props: DropDownButton.Props<O, M>,
) {
  const [anchorEl, setAnchorEl] = useState<undefined | null | HTMLElement>(
    null,
  );

  const menuItems = useMenuItems(props, setAnchorEl);
  return (
    <DropDownShell
      type="menu"
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      button={props.children}
      {...props.menuProps}
      sx={{
        maxHeight:
          props.items.length > 6
            ? props.menuHeader
              ? "400px"
              : "268px"
            : "100%",
        ...props.menuProps?.sx,
      }}
    >
      {props.menuHeader && props.menuHeader}
      {menuItems}
    </DropDownShell>
  );
}

function DropDownWithSearch<O extends Option, M extends Multiple = undefined>(
  props: DropDownButton.Props<O, M>,
) {
  const [search, setSearch] = useState("");

  return (
    <DropDown
      {...props}
      menuHeader={
        <MenuSearch key={"search"} search={search} setSearch={setSearch} />
      }
      itemProps={(params, item) => {
        const hide = !params.name.toLowerCase().includes(search.toLowerCase());
        const iProps = props.itemProps && props.itemProps(params, item);
        return {
          ...iProps,
          rerenderKey: `${hide}${iProps?.rerenderKey}`,
          sx: { display: hide ? "none" : undefined, ...iProps?.sx },
        };
      }}
    />
  );
}

export function DropDownButton<
  O extends Option,
  M extends Multiple = undefined,
>({ withSearch, ...props }: DropDownButton.Props<O, M>) {
  if (withSearch) {
    return <DropDownWithSearch {...props} />;
  }
  return <DropDown {...props} />;
}
