export interface Role {
  id: number;
  internal_name: InternalName;
  name: string;
  type: "internal" | "external";
  user_roles: { role_id: number; org_id: number };
}

export const ROLES = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
  BOOKKEEPER: "bookkeeper",
  CONSULTANT: "consultant",
  ACCOUNT_MANAGER: "account_manager",
  MIGRATION_SPECIALIST: "migration_specialist",
  PROFESSIONAL_SERVICES: "professional_services",
  BA: "ba",
  IT: "it",
  DEVELOPER: "developer",
  EDUCATOR: "educator",
  CLIENT_ADMIN: "client_admin",
  ADMIRE_COORDINATOR: "admire_coordinator",
  CLIENT: "client",
  CLIENT_READ_ONLY: "client_read_only",
  TEST_ROLE: "test_role",
} as const;

const {
  ACCOUNT_MANAGER,
  ADMIN,
  ADMIRE_COORDINATOR,
  BA,
  BOOKKEEPER,
  CLIENT,
  CLIENT_ADMIN,
  CLIENT_READ_ONLY,
  CONSULTANT,
  DEVELOPER,
  EDUCATOR,
  IT,
  MIGRATION_SPECIALIST,
  PROFESSIONAL_SERVICES,
  SUPER_ADMIN,
  TEST_ROLE,
} = ROLES;

const ROLES_INFO = {
  1: {
    internal_name: SUPER_ADMIN,
    name: "Super Admin",
    type: "internal",
  },
  2: {
    internal_name: ADMIN,
    name: "Admin",
    type: "internal",
  },
  3: {
    internal_name: CONSULTANT,
    name: "Consultant",
    type: "internal",
  },
  4: {
    internal_name: ACCOUNT_MANAGER,
    name: "Account Manager",
    type: "internal",
  },
  5: {
    internal_name: CLIENT_ADMIN,
    name: "Client Admin",
    type: "external",
  },
  6: {
    internal_name: ADMIRE_COORDINATOR,
    name: "Admire Coordinator",
    type: "external",
  },
  7: {
    internal_name: CLIENT,
    name: "Client",
    type: "external",
  },
  8: {
    internal_name: TEST_ROLE,
    name: "Test",
    type: "internal",
  },
  9: {
    internal_name: BOOKKEEPER,
    name: "Bookkeeper",
    type: "internal",
  },
  10: {
    internal_name: IT,
    name: "IT",
    type: "internal",
  },
  11: {
    internal_name: MIGRATION_SPECIALIST,
    name: "Migration Specialist",
    type: "internal",
  },
  12: {
    internal_name: DEVELOPER,
    name: "Developer",
    type: "internal",
  },
  13: {
    internal_name: PROFESSIONAL_SERVICES,
    name: "Professional Services",
    type: "internal",
  },
  14: {
    internal_name: BA,
    name: "BA",
    type: "internal",
  },
  15: {
    internal_name: EDUCATOR,
    name: "Educator",
    type: "internal",
  },
  16: {
    internal_name: CLIENT_READ_ONLY,
    name: "Client - Read Only",
    type: "external",
  },
} as const;

export const INTERNAL_ADMINS = [SUPER_ADMIN, ADMIN, BOOKKEEPER] as const;

export const INTERNAL_NON_ADMINS = [
  CONSULTANT,
  ACCOUNT_MANAGER,
  PROFESSIONAL_SERVICES,
  DEVELOPER,
  BA,
  EDUCATOR,
  IT,
  MIGRATION_SPECIALIST,
] as const;

export const INTERNAL = [...INTERNAL_ADMINS, ...INTERNAL_NON_ADMINS] as const;

export const EXTERNAL_ADMINS = [ADMIRE_COORDINATOR, CLIENT_ADMIN] as const;

export const EXTERNAL = [
  ADMIRE_COORDINATOR,
  CLIENT_ADMIN,
  CLIENT,
  CLIENT_READ_ONLY,
] as const;

export const ALL_ROLES = [...EXTERNAL, ...INTERNAL] as const;
export type InternalName = (typeof ROLES)[keyof typeof ROLES];

export const PERMISSIONS = {
  FULL: "full",
  STANDARD: "standard",
  LIMITED: "limited",
  BASE: "base",
  READ_ONLY: "read_only",
  NONE: "none",
} as const;

type Permissions = (typeof PERMISSIONS)[keyof typeof PERMISSIONS];
export const GetRolePermissions: (name: InternalName) => Permissions = (
  name,
) => {
  if (name === SUPER_ADMIN || INTERNAL_ADMINS.find((role) => role === name)) {
    return PERMISSIONS.FULL;
  }
  if (INTERNAL_NON_ADMINS.find((role) => role === name)) {
    return PERMISSIONS.STANDARD;
  }
  if (name === CLIENT_ADMIN) return PERMISSIONS.LIMITED;
  if (name === ADMIRE_COORDINATOR || name === CLIENT) return PERMISSIONS.BASE;
  if (name === CLIENT_READ_ONLY) return PERMISSIONS.READ_ONLY;
  return PERMISSIONS.NONE;
};

export const GetRolesPermissions = (role_ids: number[]) => {
  const roleNames = role_ids.map(
    (id) => ROLES_INFO[id as keyof typeof ROLES_INFO].internal_name,
  );
  const permissions = roleNames.map(GetRolePermissions);
  return (
    [
      PERMISSIONS.FULL,
      PERMISSIONS.STANDARD,
      PERMISSIONS.LIMITED,
      PERMISSIONS.BASE,
      PERMISSIONS.READ_ONLY,
    ].find((p) => permissions.includes(p)) || PERMISSIONS.NONE
  );
};

export const ROLES_IDS = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  CONSULTANT: 3,
  ACCOUNT_MANAGER: 4,
  CLIENT_ADMIN: 5,
  ADMIRE_COORDINATOR: 6,
  CLIENT: 7,
  TEST_ROLE: 8,
  BOOKKEEPER: 9,
  IT: 10,
  MIGRATION_SPECIALIST: 11,
  DEVELOPER: 12,
  PROFESSIONAL_SERVICES: 13,
  BA: 14,
  EDUCATOR: 15,
  CLIENT_READ_ONLY: 16,
} as const;

export const GetRoleId: (name: Uppercase<InternalName>) => number = (name) => {
  return ROLES_IDS[name];
};

export const GetRoleById: (
  id: number,
) => Uppercase<InternalName> | undefined = (id: number) => {
  const keys = Object.keys(ROLES).map((k) => k as Uppercase<InternalName>);
  const find = keys?.find((r: Uppercase<InternalName>) => {
    return ROLES_IDS[r] === id;
  });
  return find;
};

export const rolesWithPermissions = INTERNAL.map((r) => {
  return {
    name: r,
    permission: GetRolePermissions(r),
    id: GetRoleId(r.toUpperCase() as Uppercase<InternalName>),
  };
});

export function getRoleInfoById(id: number) {
  return ROLES_INFO[id as keyof typeof ROLES_INFO];
}

export type RolesGroup = readonly InternalName[];

const validateRole = (internalName: InternalName, rolesGroup: RolesGroup) =>
  internalName === ROLES.SUPER_ADMIN || rolesGroup.includes(internalName);

export const validateRoles = (
  usersRoles: InternalName[],
  rolesGroup?: RolesGroup,
) =>
  !rolesGroup?.length ||
  !!usersRoles.find((role) => validateRole(role, rolesGroup));

export const isInternalAdmin = (name?: string) =>
  name && validateRole(name as InternalName, INTERNAL_ADMINS);

export const isInternal = (name?: string) =>
  name && validateRole(name as InternalName, INTERNAL);

export const FileExtensions = [
  ".png",
  ".svg",
  ".bmp",
  ".jpeg",
  ".jpg",
  ".pdf",
  ".csv",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".xml",
  ".zip",
  ".mp3",
  ".mp4",
  ".wav",
] as const;

export const PreviewFileExtensions = [
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".svg",
  ".bmp",
  ".pdf",
] as const;

export const Priorities = ["normal", "high", "critical"] as const;

export type TimerActions =
  | "task_opened"
  | "task_closed"
  | "org_opened"
  | "org_closed"
  | "session_opened"
  | "session_closed"
  | "start_session";

export type TimerActivityTypes =
  | "sessions"
  | "tasks"
  | "custom_work"
  | "it"
  | "research"
  | "standardized_custom_work"
  | "admire_mistake"
  | "complimentary_session"
  | "complimentary_custom_work"
  | "training";
