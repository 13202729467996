import { Box, SxProps, Theme } from "@mui/material";
import { ChipStyled, ChipStyledProps } from "../styled";
import { ChevronDown } from "../icons";

export namespace ColoredChip {
  export type Props = {
    children: string;
    color: ChipStyledProps["shade"];
    includeChevron?: boolean;
    pill?: boolean;
    small?: boolean;
    sx?: SxProps<Theme>;
    dropDown?: boolean;
    fullWidth?: boolean;
  };
}

const sx = {
  textTransform: "uppercase",
  textOverflow: "ellipsis",
  height: 24,
  fontSize: 12,
} as const;

const withFullWidth = {
  ...sx,
  width: "100%",
};

function getSx(newSx?: SxProps<Theme>, fullWidth?: boolean) {
  const base = fullWidth ? withFullWidth : sx;
  return newSx ? { ...base, ...newSx } : base;
}

function emptyFunction() {}

export function ColoredChip(props: ColoredChip.Props) {
  const {
    includeChevron,
    pill,
    small,
    sx,
    color,
    children,
    dropDown,
    fullWidth,
  } = props;
  return (
    <ChipStyled
      sx={getSx(sx, fullWidth)}
      label={
        <Box component={"span"} display={"flex"} alignItems={"center"}>
          {children}
          {!!includeChevron && <ChevronDown />}
        </Box>
      }
      onClick={dropDown ? emptyFunction : undefined}
      edge={pill ? "pill" : "badge"}
      shade={color}
      type={"accent"}
      size={small ? "small" : "medium"}
    />
  );
}
