import { RadioGroup } from "formik-mui";
import { FormField } from "./FormField";
import { useCallback, useMemo } from "react";
import { Box, FormControlLabel, Radio } from "@mui/material";
import { TripOriginIcon } from "../../icons";
import { PropsOf } from "@emotion/react";
import { Option } from "../../buttons/dropdown";

export namespace RadioGroupField {
  export type Props<O extends Option = Option> = FormField.FieldProps<
    typeof CustomRadioGroup<O>
  >;
}

function CustomRadioGroup<O extends Option>({
  items,
  spacing,
  optional,
  ...props
}: PropsOf<typeof RadioGroup> & {
  items: O[];
  spacing?: number;
  optional?: boolean;
}) {
  const {
    field: { name },
    form: { getFieldMeta, setFieldValue },
  } = props;
  const RadioItems = useMemo(() => {
    const _items = items.map((item) => {
      const radioProps =
        typeof item === "string"
          ? { label: item, value: item, key: item }
          : { label: item.name, value: item.id, key: item.id };
      const { key, ...rest } = radioProps;
      return (
        <FormControlLabel
          {...rest}
          key={key}
          control={<Radio disableRipple checkedIcon={<TripOriginIcon />} />}
        />
      );
    });
    if (spacing) {
      const [first, ...rest] = _items;
      const m = props.row ? { mx: spacing / 2 } : { my: spacing / 2 };
      return [
        first,
        rest.map((item, i) => [<Box key={i + "space"} {...m} />, item]).flat(),
      ];
    }
    return _items;
  }, [items, spacing]);

  const onClick = useCallback(
    (e: any) => {
      if (!optional) {
        return;
      }
      const value = String(getFieldMeta(name)?.value);
      if (value === (e as any).target?.value) {
        setFieldValue(name, "");
      }
    },
    [name, setFieldValue, getFieldMeta, !!optional],
  );

  return (
    <RadioGroup {...props} onClick={onClick}>
      {RadioItems}
    </RadioGroup>
  );
}

export function RadioGroupField<O extends Option = Option>(
  props: RadioGroupField.Props<O>,
) {
  return <FormField component={CustomRadioGroup} {...props} />;
}
