import { Modal, ModalProps } from "../modal";
import { Formik, Form, FormikProps } from "formik";
import {
  Button,
  Divider,
  Grid,
  GridProps,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { CloseIcon, Tooltip } from "../icons";
import { useCallback, useEffect } from "react";
import { useMobile } from "../../themes";
import React from "react";

export function GridItem(props: GridProps) {
  return (
    <Grid item xs={12} md={12} gap={1.5} display={"flex"} {...props}></Grid>
  );
}

function castFormValues(values: any, validationSchema: any): any {
  if (
    values === undefined ||
    values === null ||
    validationSchema?.type === "date"
  ) {
    return values;
  }
  if (Array.isArray(values)) {
    if (validationSchema?.type === "array" && validationSchema?.innerType) {
      return values.map((v) => castFormValues(v, validationSchema.innerType));
    } else return values;
  }
  if (typeof values === "object") {
    if (validationSchema?.type === "object" && validationSchema?.fields) {
      return Object.keys(values).reduce((acc, key) => {
        if (validationSchema.fields[key]) {
          acc[key] = castFormValues(values[key], validationSchema.fields[key]);
        } else {
          acc[key] = values[key];
        }
        return acc;
      }, {} as any);
    } else return values;
  }
  if (typeof values === "string") {
    if (validationSchema?.type === "number") {
      if (values === "") return null;
      else return Number(values);
    }
    if (
      validationSchema?.type === "boolean" ||
      validationSchema?.type === "bool"
    ) {
      if (values === "true") return true;
      if (values === "false") return false;
      if (values === "") return null;
    }
  }
  return values;
}
type BaseModalProps = {
  open: boolean;
  title: string;
  onClose: (event?: any, reason?: "backdropClick" | "escapeKeyDown") => void;
  children: React.ReactNode;
  ButtonNearClose?: React.ReactNode;
  pcWidth?: string | number;
} & GridProps;

export function BaseModal({
  open,
  title,
  onClose,
  children,
  modalProps,
  ButtonNearClose,
  pcWidth = "700px",
  ...props
}: BaseModalProps & {
  modalProps?: Omit<ModalProps, "onClose" | "children" | "open">;
}) {
  const isMobile = useMobile();
  const p = isMobile ? 3 : 6;
  return (
    <Modal
      open={open}
      onClose={onClose}
      pcWidth={pcWidth}
      {...{ ...modalProps, sx: { p: 0, px: 0, ...modalProps?.sx } }}
    >
      <Grid p={p} pb={3} alignItems={"center"} container gap={2} {...props}>
        <Grid
          item
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
          xs={12}
          px={props.px === 0 ? p : props.px}
        >
          <Stack
            direction={isMobile ? "column" : "row"}
            alignItems={isMobile ? "flex-start" : "center"}
            justifyContent={"space-between"}
            gap={1}
            width={"100%"}
          >
            <Typography fontWeight={600} fontSize={isMobile ? 20 : 28}>
              {title}
            </Typography>
            {ButtonNearClose}
          </Stack>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        {children}
      </Grid>
    </Modal>
  );
}

type BaseFormProps<IV extends object> = {
  onClose: () => void;
  children:
    | React.ReactNode
    | React.ReactNode[]
    | ((props: FormikProps<IV>) => React.ReactNode | React.ReactNode[]);
  submitButtonName?: string;
  additionalButtons?: React.ReactNode;
  additionalButtonsPosition?: "left" | "right" | "middle";
  disableEditing?: boolean;
  disableEditingReason?: string;
  sx?: SxProps<Theme>;
} & Parameters<typeof Formik<IV>>[0];

export function BaseForm<IV extends object>({
  children,
  onClose,
  submitButtonName = "Save",
  additionalButtons,
  disableEditing,
  disableEditingReason = "",
  additionalButtonsPosition = "left",
  ...formikProps
}: BaseFormProps<IV>) {
  const handleSubmit = useCallback<BaseFormProps<IV>["onSubmit"]>(
    (values, ...args) => {
      const _values = castFormValues(values, formikProps.validationSchema);
      formikProps.onSubmit(_values, ...args);
    },
    [formikProps.onSubmit, formikProps.validationSchema],
  );

  return (
    <Formik {...formikProps} onSubmit={handleSubmit}>
      {(props) => {
        useEffect(() => {
          props.setStatus(disableEditing ? "disabled" : undefined);
        }, [disableEditing]);
        return (
          <Form style={{ width: "100%" }}>
            <Grid alignItems={"center"} container gap={2}>
              <Grid
                maxHeight={"70vh"}
                sx={{
                  overflowY: "auto",
                  "::-webkit-scrollbar": { display: "none" },
                  scrollbarWidth: "none",
                }}
                container
              >
                <Tooltip
                  title={disableEditing ? disableEditingReason : ""}
                  props={{ placement: "right" }}
                >
                  {typeof children === "function" ? children(props) : children}
                </Tooltip>
              </Grid>
              <Divider flexItem sx={{ width: "100%", mb: 1 }} />
              <GridItem justifyContent="space-between">
                {additionalButtonsPosition !== "left" ? (
                  <Button variant="outlined" onClick={onClose} color="white">
                    Cancel
                  </Button>
                ) : (
                  <GridItem>{additionalButtons}</GridItem>
                )}
                <GridItem justifyContent="flex-end">
                  {additionalButtonsPosition === "middle" ? (
                    additionalButtons
                  ) : additionalButtonsPosition === "right" ? (
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={props.isSubmitting || !!disableEditing}
                    >
                      {submitButtonName}
                    </Button>
                  ) : (
                    <Button variant="outlined" onClick={onClose} color="white">
                      Cancel
                    </Button>
                  )}
                  {additionalButtonsPosition === "right" ? (
                    additionalButtons
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={props.isSubmitting || !!disableEditing}
                    >
                      {submitButtonName}
                    </Button>
                  )}
                </GridItem>
              </GridItem>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}

type OptionalId<V> = V extends object
  ? V extends { id: number }
    ? OptionalId<Omit<V, "id">> & { id?: number }
    : { [K in keyof V]: OptionalId<V[K]> }
  : V;

export function initValue<V>(
  value: V,
  defaultValue?: OptionalId<V>,
): Exclude<V, undefined | null> {
  return (value ?? defaultValue ?? "") as Exclude<V, undefined | null>;
}
