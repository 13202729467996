import { TextField } from "formik-mui";
import { FormField } from "./FormField";
import { PropsOf } from "@emotion/react";

export namespace InputField {
  export type Props = FormField.FieldProps<typeof TextField>;
}

function CustomTextField(props: PropsOf<typeof TextField>) {
  const {
    form: { status },
  } = props;

  return <TextField disabled={status === "disabled"} {...props} />;
}

export function InputField(props: InputField.Props) {
  return <FormField component={CustomTextField} fullWidth {...props} />;
}
