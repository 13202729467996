import { IconButton, InputAdornment, TextField } from "@mui/material";
import { CloseIcon, SearchIcon } from "../../icons";
import { useMobile } from "../../../themes";
import { useState, useRef, useEffect } from "react";

export function MenuSearch({
  search,
  setSearch,
}: {
  search?: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}) {
  const isMobile = useMobile();
  const [width, setWidth] = useState(0);
  const tFieldRef = useRef<HTMLInputElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const t = setTimeout(() => {
      !search &&
        tFieldRef.current?.offsetWidth &&
        setWidth(tFieldRef.current.offsetWidth);
      searchInputRef.current?.focus();
    }, 200);
    return () => {
      setSearch("");
      setWidth(0);
      clearTimeout(t);
    };
  }, []);

  return (
    <TextField
      fullWidth
      ref={tFieldRef}
      sx={{ width: width ? width : "100%", px: 1 }}
      focused
      autoFocus
      size="small"
      value={search}
      placeholder={"Search"}
      onInput={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setSearch((e.target as any).value);
      }}
      onKeyDown={(e: any) => {
        const allowedKeys = ["ArrowUp", "ArrowDown", "Enter", "Escape"];
        if (!allowedKeys.includes(e.key)) {
          e.stopPropagation();
        }
      }}
      InputProps={{
        sx: { border: "0px" },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ fontSize: isMobile ? "1em" : "1.2em" }} />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            onClick={() => {
              setSearch("");
              searchInputRef.current?.focus();
            }}
            sx={search ? {} : { opacity: 0 }}
          >
            <CloseIcon size={16} />
          </IconButton>
        ),
        inputRef: searchInputRef,
      }}
    />
  );
}
