import { FieldArray, FieldArrayConfig, FieldArrayRenderProps } from "formik";
import { ReactNode } from "react";
import { useMobile } from "../../../themes";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import { AddIcon, DeleteIcon } from "../../icons";
import { TopLabel } from "./TopLabel";
import { FormField, getMdAndXs } from "./FormField";

export namespace ArrayField {
  export type Props = Omit<FieldArrayConfig, "children"> & {
    itemInitialValue: any;
    buttonName: string;
    minLength?: number;
    maxLength?: number;
    children?: (
      props: FieldArrayRenderProps & {
        FieldWithDeleteButton: <F extends FormField.FieldProps<any>>(
          props: {
            Component: (props: F) => JSX.Element;
            index: number;
          } & F,
        ) => ReactNode;
        items: any[];
      },
    ) => ReactNode | ReactNode[];
    label?: string;
  };
}

function RemoveArrayItem({
  index,
  remove,
  minDeleteIndex,
  disabled,
}: {
  index: number;
  remove: (index: number) => void;
  minDeleteIndex?: number;
  disabled?: boolean;
}) {
  const isMobile = useMobile();
  if (minDeleteIndex && index < minDeleteIndex) return null;
  return (
    <IconButton
      sx={{ pr: 0, pl: 0.5, alignItems: "flex-start" }}
      color="error"
      onClick={() => remove(index)}
      disableRipple
      disabled={disabled}
    >
      <DeleteIcon style={{ fontSize: isMobile ? "1rem" : ".8rem" }} />
    </IconButton>
  );
}

export function ArrayField({
  buttonName,
  itemInitialValue,
  children,
  label,
  minLength,
  maxLength,
  ...props
}: ArrayField.Props) {
  const isMobile = useMobile();
  return (
    <FieldArray {...props}>
      {(renderProps) => {
        function FieldWithDeleteButton<F extends FormField.FieldProps<any>>(
          props: {
            Component: (props: F) => JSX.Element;
            index: number;
          } & F,
        ) {
          const { Component, index, ...rest } = props;
          if (minLength && index < minLength) {
            return <Component {...(rest as any)} />;
          }
          const { md, xs, topLabel, gridProps, ...fieldProps } = getMdAndXs(
            rest as FormField.FieldProps<any>,
          );
          const child = (
            <Grid item container rowSpacing={0} md={true} xs={true}>
              <Grid item md={true} xs={true}>
                <Component {...(fieldProps as any)} useGridItem={false} />
              </Grid>
              <Grid item md={"auto"} xs={"auto"}>
                <RemoveArrayItem
                  remove={renderProps.remove}
                  index={index}
                  minDeleteIndex={minLength}
                  disabled={renderProps.form.status === "disabled"}
                />
              </Grid>
            </Grid>
          );
          const withLabel =
            typeof topLabel === "string" ? (
              <TopLabel label={topLabel}>{child}</TopLabel>
            ) : (
              child
            );
          return (
            <Grid item container md={md} xs={xs} {...gridProps}>
              {withLabel}
            </Grid>
          );
        }
        const field = renderProps.form.getFieldMeta(props.name);
        return (
          <Grid container rowSpacing={1.5} columnSpacing={1.5} item>
            {!!label && (
              <Grid item xs={12}>
                <Typography fontWeight={600} color="#ACAEB6" mt={1}>
                  {label}
                </Typography>
              </Grid>
            )}
            {!!children &&
              children({
                ...renderProps,
                FieldWithDeleteButton,
                items:
                  (renderProps.form.getFieldMeta(props.name).value as any[]) ||
                  [],
              })}
            <Grid item xs={12}>
              <Button
                sx={{
                  py: 2,
                  px: 0,
                  height: 0,
                }}
                onClick={() => renderProps.push(itemInitialValue)}
                disableRipple
                disabled={
                  renderProps.form.status === "disabled" ||
                  !!(maxLength && (field?.value as any)?.length >= maxLength)
                }
              >
                <AddIcon style={{ fontSize: isMobile ? "1rem" : ".8rem" }} />
                <Typography marginLeft={0.8} fontWeight={500}>
                  {buttonName}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        );
      }}
    </FieldArray>
  );
}
