import { Button } from "@mui/material";
import { differenceInHours } from "date-fns";
import { BookingType } from "../../global";
import { useCallback } from "react";
import {
  useActivityActionsList,
  useActivityTypesList,
} from "../hooks/useLists";
import { useCreateUserTimerActivityMutation } from "../../state/rtk-query/state/timer";
import { useSelector } from "react-redux";
import { timerSelectors } from "../../state";
import { useTimerActions } from "../hooks";
interface Props {
  booking?: BookingType;
  miniCard?: boolean;
  orgId?: number;
}
export const JoinZoomMeetingButton = ({ booking, miniCard, orgId }: Props) => {
  const { getActionId } = useActivityActionsList();
  const { getActivityId } = useActivityTypesList();

  const [createTimerActivity] = useCreateUserTimerActivityMutation();

  const { state } = useSelector(timerSelectors.currentTimer) ?? {};
  const { start_time, join_url, session_id } = booking ?? {};

  const sessionActivityId = getActivityId("sessions");
  const { startTimer, stopTimer } = useTimerActions(sessionActivityId) ?? {};
  const timerIsRunning = state === "active";

  const isJoinable = start_time
    ? [0, 1].includes(differenceInHours(new Date(start_time), new Date()))
    : false;
  const sessionStart = getActionId("start_session");
  const stopDefault = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (join_url) {
        if (sessionStart && session_id) {
          await createTimerActivity({
            body: {
              entity: "session",
              entity_id: session_id,
              action_id: sessionStart,
            },
          });
        }
        if (timerIsRunning) {
          await stopTimer();
        }
        if (startTimer && orgId) {
          await startTimer({ org_id: orgId });
        }
        window.open(join_url);
      }
    },
    [join_url, isJoinable, sessionStart, session_id],
  );

  return (
    <>
      {start_time && join_url ? (
        <Button
          onClick={stopDefault}
          variant={miniCard ? "contained" : "text"}
          size="small"
          disabled={!isJoinable}
          sx={{
            pointerEvents: !isJoinable ? "none" : "all",
            alignSelf: "flex-start",
            fontWeight: 600,
            fontSize: 14,
          }}
        >
          Join session
        </Button>
      ) : null}
    </>
  );
};
