import {
  GridRowGroupingModel,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-premium";
import { useDatagridProps, useQuery } from "../hooks";
import { StyledDataGridPremium } from "./DataGrid.styles";
import { useMobile } from "../../themes";
import { useAdminTimesColumns } from "../../columns";
import {
  GetTimeSpentOnAllOrgsApiArg,
  useBulkDeleteTimersMutation,
  useGetDetailedTimeSpentOnAllOrgsQuery,
} from "../../state/rtk-query/state/timer";
import { CardStyled } from "../styled";
import { useCallback, useMemo, useState } from "react";
import { DateRange } from "@mui/x-date-pickers-pro";
import { capitalizeSentence, filterBoolean } from "../../lib";
import { Button, Stack, Typography } from "@mui/material";
import RowsSelectedBanner from "../cards/RowsSelectedBanner";
import { useRowSelectionModel } from "../hooks/useRowSelectionModel";
import { ConfirmDeleteDialog } from "./ConfirmDeleteDialog";
import { FilterButton } from "../buttons/dropdown";
import { useList } from "../hooks/useNewLists";
import { useGridStateQuery } from "../hooks/useFilterModel";

type FilterType = GetTimeSpentOnAllOrgsApiArg["filterTime"];

interface QueryProps {
  dateRange?: DateRange<any> | undefined;
  time_filter?: FilterType;
  month?: string;
  org_name?: string;
  consultant?: string;
}

const values = {
  CW: ["Comp CW", "CW"],
  Support: ["Comp Sessions", "Sessions", "Tasks"],
};

function FilterAndExportToolbar() {
  const { filterModel, setFilterItem, setFilters } = useGridStateQuery();
  const orgs = useList("orgs");
  const orgItem = filterModel?.items?.find((i) => i.id === 123);
  const activityItem = filterModel?.items?.find((i) => i.id === "activity");
  const selectedActivity =
    activityItem?.value?.[0] === "Comp CW"
      ? "CW"
      : activityItem?.value?.[0] === "Comp Sessions"
      ? "Support"
      : undefined;

  const selectedOrg = orgs.list.find((o) => o.name === orgItem?.value)?.id;

  return (
    <GridToolbarContainer
      sx={{
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Stack
        direction={"row"}
        overflow={"auto"}
        sx={{
          "::-webkit-scrollbar": { display: "none" },
          scrollbarWidth: "none",
        }}
      >
        <FilterButton
          items={orgs.list}
          getItem={orgs.get}
          selected={selectedOrg}
          setSelected={(v) =>
            setFilterItem(
              123,
              v
                ? {
                    field: "org_name",
                    operator: "equals",
                    value: orgs.get(v)?.name,
                  }
                : undefined,
            )
          }
          withSearch
        >
          Org
        </FilterButton>
        <FilterButton
          items={["CW", "Support"]}
          selected={selectedActivity}
          setSelected={(v) => {
            setFilterItem(
              "activity",
              v
                ? { field: "timer_type", operator: "isAnyOf", value: values[v] }
                : undefined,
            );
          }}
        >
          Activity
        </FilterButton>
      </Stack>
      <Stack
        direction={"row"}
        gap={1}
        justifyContent={"flex-end"}
        alignContent={"center"}
      >
        {!!(selectedOrg || selectedActivity) && (
          <Button
            variant="text"
            color="info"
            sx={{ minWidth: "fit-content" }}
            size="small"
            onClick={() => {
              setFilters(
                filterModel.items.filter(
                  (i) => i.id !== 123 && i.id !== "activity",
                ),
              );
            }}
          >
            <Typography fontSize={16} fontWeight={"500"}>
              Reset filters
            </Typography>
          </Button>
        )}
        <GridToolbarExport
          slotProps={{ button: { color: "info" } }}
          csvOptions={{ disableToolbarButton: true }}
          printOptions={{ disableToolbarButton: true }}
        />
      </Stack>
    </GridToolbarContainer>
  );
}

export const AdminTimeTable = () => {
  const isMobile = useMobile();
  const apiRef = useGridApiRef();
  const [deleteTimers] = useBulkDeleteTimersMutation();
  const [query] = useQuery<QueryProps>("dateRange", "time_filter");

  const columns = useAdminTimesColumns(isMobile);
  const { slotProps, autosizeOptions } = useDatagridProps();
  const { time_filter, dateRange } = query;

  const hasDateRange = useMemo(
    () => dateRange && !!filterBoolean(dateRange)?.length,
    [dateRange],
  );

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ["org_name"] as GridRowGroupingModel,
      },
      aggregation: {
        model: {
          time_spent: "sum",
        },
      },
    },
  });

  const formatGroupByName = useCallback((models?: GridRowGroupingModel) => {
    return capitalizeSentence(
      (models ?? [])
        .map((m) => (m === "org_name" ? "Organization" : m.replace("_", " ")))
        .join(", "),
    );
  }, []);

  const [groupByName, setGroupByName] = useState(
    formatGroupByName(initialState?.rowGrouping?.model) ?? "",
  );

  const { currentData } = useGetDetailedTimeSpentOnAllOrgsQuery({
    dateRange: hasDateRange ? dateRange : undefined,
    filterTime: time_filter,
  });
  const { onCellClick, onColumnHeaderClick, selectedRows, setSelectedRows } =
    useRowSelectionModel(currentData);
  const changeName = useCallback(
    (models: GridRowGroupingModel) => {
      setGroupByName(capitalizeSentence(formatGroupByName(models)));
    },
    [groupByName],
  );

  const {
    filterModel,
    paginationModel,
    setFilterModel,
    setPaginationModel,
    setSortModel,
    sortModel,
  } = useGridStateQuery();

  const Table = useMemo(() => {
    return (
      <StyledDataGridPremium
        rows={currentData ?? []}
        filterModel={filterModel}
        apiRef={apiRef}
        columns={columns}
        disableRowSelectionOnClick
        initialState={initialState}
        autosizeOnMount={true}
        autosizeOptions={autosizeOptions}
        slotProps={{ ...slotProps, toolbar: {} }}
        groupingColDef={{
          flex: isMobile ? undefined : 1,
          headerName: `Group by "${groupByName}"`,
        }}
        sx={{
          ".MuiDataGrid-virtualScroller": {
            height: "100%",
          },
        }}
        onCellClick={onCellClick}
        onRowGroupingModelChange={changeName}
        pagination
        onFilterModelChange={setFilterModel}
        onColumnHeaderClick={onColumnHeaderClick}
        rowSelectionModel={selectedRows}
        checkboxSelection={true}
        isRowSelectable={(p) => {
          return p.row.time_spent !== undefined;
        }}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        slots={{
          toolbar: FilterAndExportToolbar,
        }}
      />
    );
  }, [
    currentData,
    columns,
    apiRef,
    groupByName,
    initialState,
    selectedRows,
    filterModel,
    setFilterModel,
    paginationModel,
    setPaginationModel,
    sortModel,
    setSortModel,
  ]);

  return (
    <>
      <RowsSelectedBanner
        selectedRowsCt={selectedRows.length}
        onClear={() => setSelectedRows([])}
        onSelectAll={() => setSelectedRows(currentData?.map((r) => r.id) || [])}
      >
        <ConfirmDeleteDialog
          buttonText="Delete timers"
          buttonColor="info"
          handleConfirm={async () =>
            await deleteTimers({ body: { timers: selectedRows } })
          }
          titleText="Delete timers"
          subTitleText="Are you sure you want to delete these timers?"
        />
      </RowsSelectedBanner>
      <CardStyled
        sx={{
          height: "100%",
          mb: 2,
          mt: selectedRows.length > 0 ? -2 : 0,
        }}
      >
        <Stack height={"100%"} overflow={"hidden"}>
          {Table}
        </Stack>
      </CardStyled>
    </>
  );
};
