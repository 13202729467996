import { useDispatch, useSelector } from "react-redux";
import { authSelectors, timerActions, timerSelectors } from "../../state";
import {
  useCreateTimerMutation,
  useStopActiveTimerMutation,
} from "../../state/rtk-query/state/timer";
import { useActivityTypesList } from "./useLists";
import { matchPath } from "react-router-dom";
import { MainPages } from "../../pages";

interface StartTimerProps {
  org_id: number;
  task_id?: number;
  task_code?: string;
  task_title?: string;
}
const useStartTimer = (activityTypeId?: number) => {
  const dispatch = useDispatch();
  const [createTimer] = useCreateTimerMutation();
  const { getActivityId } = useActivityTypesList();
  const isIt = useSelector(authSelectors.isIT);
  const isInternal = useSelector(authSelectors.isInternal);
  const isConsultant = useSelector(authSelectors.isConsultant);
  const isCustomWorkUser = useSelector(authSelectors.isCustomWorkUser);
  const { inferredOrgId } = useSelector(timerSelectors.currentTimer) ?? {};
  const isSessionPage = matchPath(
    MainPages(true).session.path,
    location.pathname,
  );
  if (!isInternal) return;

  const activityId = getActivityId(
    isConsultant
      ? "tasks"
      : isCustomWorkUser
      ? "custom_work"
      : isIt
      ? "it"
      : isSessionPage
      ? "sessions"
      : undefined,
  );
  const activity_type_id = activityTypeId ?? activityId;

  const startTimer = async ({
    org_id,
    task_id: _taskId,
    task_code: taskCode,
    task_title: taskTitle,
  }: StartTimerProps) => {
    const cwTask = isCustomWorkUser
      ? { taskId: _taskId, taskCode, taskTitle }
      : undefined;
    const newTimer = await createTimer({
      body: {
        org_id,
        activity_type_id,
        task_id: isCustomWorkUser ? _taskId : undefined,
      },
    }).unwrap();

    if (newTimer) {
      dispatch(
        timerActions.setTimerAction({
          state: "active",
          id: newTimer.id,
          orgId: org_id,
          inferredOrgId,
          customWork: cwTask,
        }),
      );
    }
  };
  return startTimer;
};

const useRestartTimer = () => {
  const dispatch = useDispatch();
  const [stopTimer] = useStopActiveTimerMutation();
  const startTimer = useStartTimer();
  const { inferredOrgId, orgId, customWork } =
    useSelector(timerSelectors.currentTimer) ?? {};

  const { taskCode, taskId, taskTitle } = customWork ?? {};
  const isCW = useSelector(authSelectors.isCustomWorkUser);
  const org = inferredOrgId ?? orgId;
  if (!org) return;
  const restart = async () => {
    if (startTimer && org) {
      dispatch(timerActions.setTimerMidSwitchAction(true));
      await stopTimer();
      await startTimer({
        org_id: org,
        task_id: isCW ? taskId : undefined,
        task_title: isCW ? taskTitle : undefined,
        task_code: isCW ? taskCode : undefined,
      });
      dispatch(timerActions.setTimerMidSwitchAction());
    }
  };
  return restart;
};

const useStopTimer = () => {
  const dispatch = useDispatch();
  const [stopTimer] = useStopActiveTimerMutation();
  const { state } = useSelector(timerSelectors.currentTimer) ?? {};
  const stop = async () => {
    if (state) {
      await stopTimer();
      dispatch(timerActions.setTimerStateAction("stopped"));
    }
  };
  return stop;
};

export const useTimerActions = (activityId?: number) => {
  const restartTimer = useRestartTimer();
  const startTimer = useStartTimer(activityId);
  const stopTimer = useStopTimer();
  return { restartTimer, startTimer, stopTimer };
};
