import { Autocomplete } from "formik-mui";
import { DefaultItem, Multiple, Option } from "../../buttons/dropdown/index";
import { CloseIcon } from "../../icons";
import { SmallDownIcon } from "./SmallDownIcon";
import { Chip, MenuItem, TextField } from "@mui/material";
import { FormField } from "./FormField";
import { yup } from "../../../lib";
import { PropsOf } from "@emotion/react";

function getOptionLabel(option: Option) {
  return typeof option === "string" ? option : option.name;
}

function getOptionKey(option: Option) {
  return typeof option === "string" ? option : option.id;
}

function isOptionEqualToValue(option: Option, value: Option) {
  if (option === value) return true;
  if (typeof option === "string") return false;
  if (typeof value === "string") return false;
  return option.id === value.id;
}

function SmallCloseIcon(props: any) {
  return <CloseIcon {...props} color="#636469" width={18} height={18} />;
}

type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

const slotProps = {
  popper: { sx: { width: "100%", minWidth: "400px" } },
};

function CustomAutocomplete<
  O extends Option,
  M extends Multiple,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  items,
  renderOption: renderItem,
  ...props
}: Omit<
  Optional<
    PropsOf<typeof Autocomplete<O, M, DisableClearable, FreeSolo>>,
    "renderInput"
  >,
  "options"
> & { items: O[] }) {
  const {
    form: { setFieldTouched, getFieldMeta },
    field: { name },
  } = props;
  const { error, touched } = getFieldMeta(name);
  const defaultProps: Partial<typeof props> = {
    getOptionKey,
    getOptionLabel,
    clearIcon: <SmallCloseIcon />,
    popupIcon: <SmallDownIcon />,
    renderTags,
    isOptionEqualToValue,
  };
  return (
    <Autocomplete
      options={items}
      disableCloseOnSelect={!!props.multiple}
      renderInput={(riProps) => {
        return (
          <TextField
            {...riProps}
            multiline={!!props.multiple}
            name={name}
            error={touched && !!error}
            helperText={touched && error}
          />
        );
      }}
      {...defaultProps}
      {...props}
      renderOption={
        renderItem
          ? (props, val, state, ownerState) =>
              renderOption({ ...props, renderItem }, val, state, ownerState)
          : renderOption
      }
      slotProps={
        props.slotProps
          ? {
              ...slotProps,
              ...props.slotProps,
              popper: {
                ...slotProps.popper,
                ...props.slotProps.popper,
                sx: {
                  ...slotProps.popper?.sx,
                  ...props.slotProps?.popper?.sx,
                },
              },
            }
          : slotProps
      }
      onClose={(...args) => {
        props.onClose && props.onClose(...args);
        setTimeout(() => setFieldTouched(name, true), 100);
      }}
    />
  );
}

function renderTags(
  values: any[],
  getProps: (ind: { index: number }) => any,
  _ownerState: any,
) {
  return values.map((val, index) => {
    const { key, ...props } = getProps({ index });
    return (
      <Chip
        key={key}
        {...props}
        label={typeof val === "string" ? val : val.name}
      />
    );
  });
}

function renderOption(
  { key, renderItem, ...props }: any,
  option: any,
  state: any,
  ownerState: any,
) {
  const label = ownerState.getOptionLabel(option);

  return (
    <MenuItem
      key={key}
      {...props}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        props.onClick?.(e);
      }}
      sx={
        option.deleted_at
          ? {
              ...props?.sx,
              display: "none",
            }
          : {
              textWrap: "wrap",
              ...props?.sx,
            }
      }
    >
      <DefaultItem
        label={label}
        checked={!!state.selected}
        withCheckbox={ownerState.multiple}
      >
        {renderItem && renderItem({ ...props, key }, option, state, ownerState)}
      </DefaultItem>
    </MenuItem>
  );
}

const optionSchema = yup
  .object({
    id: yup.number().required(),
  })
  .nullable(true);

function _AutocompleteField<
  O extends Option,
  M extends Multiple,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(props: AutocompleteField.Props<O, M, DisableClearable, FreeSolo>) {
  return (
    <FormField
      component={CustomAutocomplete}
      {...(props as FormField.FieldProps<typeof CustomAutocomplete>)}
    />
  );
}

type ACField = typeof _AutocompleteField & { schema: typeof optionSchema };

export const AutocompleteField = _AutocompleteField as ACField;
Object.defineProperty(AutocompleteField, "schema", {
  get: () => optionSchema.clone(),
});

export namespace AutocompleteField {
  export type Props<
    O extends Option,
    M extends Multiple,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined,
  > = FormField.FieldProps<
    typeof CustomAutocomplete<O, M, DisableClearable, FreeSolo>
  >;
}
