import { useCallback, useEffect, useState } from "react";
import { VITE_INACTIVITY_TIMEOUT } from "../../config";
import { authSelectors, logout, useSelector } from "../../state";

function getInactivityValue(inactivityTimeout = "12h") {
  const type = inactivityTimeout.charAt(inactivityTimeout.length - 1);
  const amt = +inactivityTimeout.slice(0, inactivityTimeout.length - 1);
  switch (type) {
    case "s":
      return amt * 1000;
    case "m":
      return amt * 60 * 1000;
    case "h":
      return amt * 60 * 60 * 1000;
    case "d":
      return amt * 24 * 60 * 60 * 1000;
    case "w":
      return amt * 7 * 24 * 60 * 60 * 1000;
    default:
      return 0;
  }
}

const inactivityTimeout = getInactivityValue(VITE_INACTIVITY_TIMEOUT);

export function useInactivityTracker() {
  const isAdmin = useSelector(authSelectors.isAdmin);
  const [lastActivity, setLastActivity] = useState(Date.now());

  const updateActivity = useCallback(
    (update = true) => {
      setLastActivity((prev) => {
        if (Date.now() - prev > inactivityTimeout) {
          logout();
        }
        return update ? Date.now() : prev;
      });
    },
    [setLastActivity, inactivityTimeout],
  );

  useEffect(() => {
    if (isAdmin) {
      return;
    }
    const events = [
      "mousemove",
      "mousedown",
      "keydown",
      "scroll",
      "touchstart",
    ];

    const update = () => updateActivity();

    events.forEach((event) => window.addEventListener(event, update));

    const interval = setInterval(() => updateActivity(false), 60000);

    return () => {
      clearInterval(interval);
      events.forEach((event) => window.removeEventListener(event, update));
    };
  }, [isAdmin, updateActivity]);

  return lastActivity;
}
