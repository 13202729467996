/**
 * @file This file is used to normalize environment variables and provide
 * intellisense/autocomplete for them. Import your settings from this file
 * instead of directly from `process.env`.
 */

/** Common environment names. */
const Environments = {
  development: "development",
  production: "production",
};

/** Name of the current environment. */
const NODE_ENV = process.env.NODE_ENV || Environments.development;
/** Host name used to detect production mode. */
const VITE_PROD_HOSTNAME = import.meta.env.VITE_PROD_HOSTNAME;
/** True if the app is in development mode. */
const __DEV__ = NODE_ENV === Environments.development;
/**
 * True if the app is in production mode.
 * NOTE: We don't use Environments.production to test for this
 * because Create-React-App uses "production" for every other non-development
 * environment.
 */
const __STAGING__ = window.location.hostname === "consultant-tool.bitbean.dev";
const __PROD__ = window.location.hostname === "help.admirepro.com";
const COLLAPSIBLE_DRAWER = import.meta.env.COLLAPSIBLE_DRAWER || true;
/** Base URL of the API. */
const VITE_API_URL = import.meta.env.VITE_API_URL;
const VITE_API_DOMAIN = import.meta.env.VITE_API_DOMAIN;
/** URL to the company website. */
const VITE_COMPANY_SITE_URL = import.meta.env.VITE_COMPANY_SITE_URL;
/** DSN URL for the error telemetry API. */
const VITE_ERRORS_DSN = import.meta.env.VITE_ERRORS_DSN;
/** True to report errors during development. */
const VITE_ERRORS_DEV = import.meta.env.VITE_ERRORS_DEV === "true";
/** Key to store redux state under localStorage. */
const VITE_PERSIST_KEY = import.meta.env.VITE_PERSIST_KEY;
/** Site title string. */
const VITE_SITE_TITLE = import.meta.env.VITE_SITE_TITLE;
/** Package version string from the package.json file. */
const VITE_VERSION = import.meta.env.VITE_VERSION;
/** Google client id */
const MFA_Active = import.meta.env.VITE_MFA === "on";
const VITE_MUI_LICENSE = import.meta.env.VITE_MUI_LICENSE;
const VITE_CLIENT_ID = import.meta.env.VITE_CLIENT_ID || "";
const VITE_AAD_ENDPOINT = import.meta.env.VITE_AAD_ENDPOINT || "";
const VITE_TENANT_ID = import.meta.env.VITE_TENANT_ID;

const VITE_MFA_REMEMBER_DAYS = import.meta.env.VITE_MFA_REMEMBER_DAYS;
const VITE_TIMER_RESET_MINUTES = import.meta.env.VITE_TIMER_RESET_MINUTES;
const VITE_INACTIVITY_TIMEOUT = import.meta.env.VITE_INACTIVITY_TIMEOUT;

export {
  __DEV__,
  __STAGING__,
  __PROD__,
  NODE_ENV,
  VITE_API_URL,
  VITE_API_DOMAIN,
  VITE_COMPANY_SITE_URL,
  VITE_ERRORS_DSN,
  VITE_ERRORS_DEV,
  VITE_PERSIST_KEY,
  VITE_PROD_HOSTNAME,
  VITE_SITE_TITLE,
  VITE_VERSION,
  COLLAPSIBLE_DRAWER,
  MFA_Active,
  VITE_MUI_LICENSE,
  VITE_CLIENT_ID,
  VITE_AAD_ENDPOINT,
  VITE_TENANT_ID,
  VITE_MFA_REMEMBER_DAYS,
  VITE_TIMER_RESET_MINUTES,
  VITE_INACTIVITY_TIMEOUT,
};
