import { Stack, Typography } from "@mui/material";

interface UsageProps {
  count: number;
  title: string;
  decimalPlaces?: number;
}

export const DisplayUsage = ({ count, title, decimalPlaces = 2 }: UsageProps) => {
  return (
    <Stack direction={"column"}>
      <Typography fontWeight={600}>
        {Number(count.toFixed(decimalPlaces) ?? 0)}
      </Typography>
      <Typography variant="subtitle2">{title}</Typography>
    </Stack>
  );
};
