import { Box, Button } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

export default forwardRef((props: any, ref: any) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index: number) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: item });
    }
  };

  const arrowHandler = (direction: "up" | "down") => {
    if (direction === "up") {
      setSelectedIndex(
        (selectedIndex + props.items.length - 1) % props.items.length,
      );
    } else {
      setSelectedIndex((selectedIndex + 1) % props.items.length);
    }
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: KeyboardEvent }) => {
      switch (event.key) {
        case "ArrowUp":
          arrowHandler("up");
          return true;
        case "ArrowDown":
          arrowHandler("down");
          return true;
        case "Enter":
          enterHandler();
          return true;
        default:
          return false;
      }
    },
  }));

  const itemStyles = {
    background: "transparent",
    border: "1px solid transparent",
    borderRadius: " 0.4rem",
    display: "block",
    margin: 0,
    padding: "0.2rem 0.4rem",
    textAlign: "left",
    width: " 100%",
    fontSize: ".55em",
    "&.is-selected": {
      borderColor: " #000",
    },
  };

  return (
    <Box
      className="items"
      sx={{
        background: "#fff",
        borderRadius: "0.5rem",
        boxShadow:
          " 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1)",
        color: "rgba(0, 0, 0, 0.8)",
        fontSize: "0.9rem",
        maxHeight: "300px",
        padding: "0.2rem",
        overflow: "auto",
        position: "relative",
      }}
    >
      {props.items.length ? (
        props.items.map((item: any, index: number) => {
          const name = typeof item === "string" ? item : item?.name;
          return (
            <Button
              className={`item ${index === selectedIndex ? "is-selected" : ""}`}
              key={index}
              onClick={() => selectItem(index)}
              color="black"
              sx={{
                ...itemStyles,
                "&.is-selected": {
                  borderColor: " #000",
                },
              }}
            >
              {name || ""}
            </Button>
          );
        })
      ) : (
        <Box sx={itemStyles}>No results</Box>
      )}
    </Box>
  );
});
