import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Timer {
  id?: number;
  orgId?: number;
  state: "active" | "stopped" | "switching" | undefined;
  inferredOrgId?: number;
  stayOnOrgId?: number;
  cancelledSwitch?: boolean;
  midSwitch?: boolean;
  customWork?: {
    taskId?: number;
    taskCode?: string;
    taskTitle?: string;
  };
}
export interface TimerState {
  timer: Timer;
}

const initialState: TimerState = {
  timer: {
    id: undefined,
    orgId: undefined,
    state: undefined,
    inferredOrgId: undefined,
    stayOnOrgId: undefined,
    cancelledSwitch: false,
    midSwitch: false,
    customWork: undefined,
  },
};

export const timer = {
  ...createSlice({
    name: "timer",
    initialState,
    reducers: {
      setTimer(state, { payload }: PayloadAction<Timer | undefined>) {
        if (!payload) {
          return initialState;
        }
        state.timer = payload;
      },
      setTimerId(state, { payload }: PayloadAction<number | undefined>) {
        state.timer.id = payload;
      },
      setTimerOrgId(state, { payload }: PayloadAction<number | undefined>) {
        state.timer.orgId = payload;
      },
      setTimerState(
        state,
        { payload }: PayloadAction<TimerState["timer"]["state"]>,
      ) {
        state.timer.state = payload;
      },
      setTimerCw(
        state,
        { payload }: PayloadAction<Timer["customWork"] | undefined>,
      ) {
        state.timer.customWork = payload;
      },
      setTimerInferredOrgId(
        state,
        { payload }: PayloadAction<number | undefined>,
      ) {
        state.timer.inferredOrgId = payload;
      },
      setTimerStayOnOrgId(
        state,
        { payload }: PayloadAction<number | undefined>,
      ) {
        state.timer.stayOnOrgId = payload;
      },
      setTimerCancelledSwitch(state, { payload }: PayloadAction<boolean>) {
        state.timer.cancelledSwitch = payload;
      },
      setTimerMidSwitch(state, { payload }: PayloadAction<boolean>) {
        state.timer.midSwitch = payload;
      },
      resetTimer(state) {
        state.timer = initialState.timer;
      },
    },
  }),
  persist: true,
};
