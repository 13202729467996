import { useEffect, useState } from "react";

function valuesAreEqual(a: any, b: any) {
  return a === b || JSON.stringify(a) === JSON.stringify(b);
}

export function useParallelState<V>(
  value: V | undefined,
  setValue: (val: V | undefined) => void,
) {
  const parallelState = useState(value);
  const [parallel, setParallel] = parallelState;

  useEffect(() => {
    if (valuesAreEqual(parallel, value)) return;
    setValue(parallel);
  }, [JSON.stringify(parallel)]);

  useEffect(() => {
    if (valuesAreEqual(parallel, value)) return;
    setParallel(value);
  }, [JSON.stringify(value)]);
  return parallelState;
}
