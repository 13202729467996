import { Tabs, Tab, Divider, Box } from "@mui/material";
import {
  GetOrgByIdApiResponse,
  GetOrgsApiResponse,
} from "../../state/rtk-query/state/organization";
import { BaseModal, AddOrgForm, AddTermsForm } from "../forms";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { useMobile } from "../../themes";

interface Props {
  open: boolean;
  onClose: (event?: any, reason?: "backdropClick" | "escapeKeyDown") => void;
  editOrg?: GetOrgsApiResponse["rows"][number] | GetOrgByIdApiResponse;
}
type TabTypes = "org" | "terms";
export const AddOrEditOrgModal = ({ open, editOrg, onClose }: Props) => {
  const [tab, setTab] = useState<TabTypes>("org");
  const isAdmin = useSelector(authSelectors.isAdmin);
  const isMobile = useMobile();
  const handleChangeTab = useCallback(
    (_event: React.SyntheticEvent, newValue: TabTypes) => {
      setTab(newValue);
    },
    [tab],
  );
  return (
    <BaseModal
      open={open}
      title={editOrg ? "Edit organization" : "Add organization"}
      onClose={onClose}
    >
      {editOrg && isAdmin ? (
        <Box width={"100%"} mb={1}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            allowScrollButtonsMobile
            variant="scrollable"
          >
            <Tab
              label="Org"
              value={"org"}
              sx={{ fontSize: isMobile ? "16px" : "1em" }}
            />
            <Tab
              label="Terms"
              value={"terms"}
              sx={{ fontSize: isMobile ? "16px" : "1em" }}
            />
          </Tabs>
          <Divider sx={{ width: "100%" }} />
        </Box>
      ) : null}
      {tab === "org" ? (
        <AddOrgForm onClose={onClose} editOrg={editOrg} />
      ) : (
        <AddTermsForm onClose={onClose} editOrg={editOrg} />
      )}
    </BaseModal>
  );
};
