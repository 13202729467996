import { PropsOf } from "@emotion/react";
import { FormControl, Typography } from "@mui/material";

export namespace TopLabel {
  export type Props = {
    label: string;
    typographyProps?: PropsOf<typeof Typography>;
  } & PropsOf<typeof FormControl>;
}

export function TopLabel({
  label,
  typographyProps = {},
  children,
  ...props
}: TopLabel.Props) {
  return (
    <FormControl fullWidth {...props}>
      <Typography
        fontSize={12}
        lineHeight={0}
        my={2}
        {...typographyProps}
        sx={{ fontWeight: 500, ...typographyProps.sx }}
      >
        {label}
      </Typography>
      {children}
    </FormControl>
  );
}
