import { ReactNode, useState } from "react";
import { EmptyState, EmptyStateProps } from "./EmptyState";

export type EmptiableProps = Omit<EmptyStateProps, "open" | "setOpen"> & {
  isEmpty?: boolean;
  children: ReactNode;
};
export function Emptiable(props: EmptiableProps) {
  const [open, setOpen] = useState(false);
  if (props.isEmpty || open) {
    return (
      <EmptyState
        type={props.type}
        hasFilters={props.hasFilters}
        orgId={props.orgId}
        sessionId={props.sessionId}
        open={open}
        setOpen={setOpen}
      />
    );
  }
  return <>{props.children}</>;
}
