import { Field, FieldConfig } from "formik";
import { TopLabel } from "./TopLabel";
import { Grid, GridProps } from "@mui/material";
import { PropsOf } from "@emotion/react";

export namespace FormField {
  export type Props<C extends (...props: any[]) => JSX.Element> = Omit<
    FieldConfig,
    "component"
  > & {
    component: C;
    topLabel?: string | boolean;
    useGridItem?: boolean;
  } & Omit<PropsOf<C>, "field" | "meta" | "form"> &
    Omit<FormItemProps, "children">;
  export type FieldProps<C extends (...props: any[]) => JSX.Element> = Omit<
    FormField.Props<C>,
    "component"
  >;
}

type Nums = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type FormItemProps = {
  md?: GridProps["md"];
  xs?: GridProps["xs"];
  children?: React.ReactNode;
  gridProps?: Omit<GridProps, "children">;
} & { [K in `${"md" | "xs"}${Nums}`]?: boolean };

export function getMdAndXs<Props extends Omit<FormItemProps, "children">>(
  props: Props,
) {
  const numArr = Array.from({ length: 12 }).map((_, i) => (i + 1) as Nums);
  const md =
    "md" in props
      ? props.md
      : numArr.find((i) => (props as any)[`md${i}`]) ?? 6;
  const xs =
    ("xs" in props ? props.xs : undefined) ??
    (numArr.find((i) => (props as any)[`xs${i}`]) ||
      (typeof md === "number" && md <= 6 ? md * 2 : md));
  const {
    md: _,
    xs: __,
    [`md${md}` as "md"]: ___,
    [`xs${xs}` as "xs"]: ____,
    ...rest
  } = props;
  return { md, xs, ...rest };
}

export function FormItem({
  gridProps,
  children,
  xs,
  md,
}: {
  md: GridProps["md"];
  xs?: GridProps["xs"];
  children?: React.ReactNode;
  gridProps?: Omit<GridProps, "children">;
}) {
  return (
    <Grid item md={md} xs={xs} {...gridProps}>
      {children}
    </Grid>
  );
}

function FormFieldTopLabel<C extends (...props: any[]) => JSX.Element>({
  topLabel,
  ...props
}: FormField.Props<C>) {
  const field = <Field {...props} />;
  if (typeof topLabel === "string" || topLabel) {
    return (
      <TopLabel label={topLabel === true ? capitalize(props.name) : topLabel}>
        {field}
      </TopLabel>
    );
  }
  return field;
}

export function FormField<C extends (...props: any[]) => JSX.Element>({
  gridProps,
  useGridItem = true,
  ...props
}: FormField.Props<C>) {
  const { md, xs, ...rest } = getMdAndXs(props);
  const field = <FormFieldTopLabel {...(rest as FormField.Props<C>)} />;
  if (useGridItem) {
    return (
      <FormItem md={md ?? 6} xs={xs ?? 12} gridProps={gridProps}>
        {field}
      </FormItem>
    );
  }
  return field;
}

function capitalize(s: string) {
  const [first, ...words] = s.split("_");
  const capFirst = first.charAt(0).toUpperCase() + first.slice(1);
  if (words.at(-1) === "id") {
    words.pop();
  }
  return [capFirst, ...words].join(" ");
}
