import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { CloseIcon, SearchIcon } from "../icons";
import { useMobile } from "../../themes";

export namespace Searchbar {
  export type Props = {
    search?: string;
    setSearch: (value: string) => void;
  } & Omit<Partial<TextFieldProps>, "onChange" | "value">;
}
const sx = {
  width: "100%",
  height: 36,
  borderRadius: "4px 4px 0 0",
  transition: " all 0.15s",
  position: "relative",
  "& :focus": {
    backgroundColor: "#FFF",
  },
};

export function Searchbar({ search, setSearch, ...rest }: Searchbar.Props) {
  const isMobile = useMobile();
  return (
    <TextField
      name="global_search"
      sx={sx}
      placeholder={"Search"}
      onChange={(e) => setSearch(e.target.value || "")}
      value={search ?? ""}
      inputRef={(input) => input && isMobile && input.focus()}
      InputProps={{
        //sx,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ fontSize: isMobile ? "1em" : "1.2em" }} />
          </InputAdornment>
        ),
        endAdornment: !!search && (
          <InputAdornment position="end">
            <IconButton onClick={() => setSearch("")}>
              <CloseIcon style={{ fontSize: isMobile ? "1em" : "1.1em" }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
}
