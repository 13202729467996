import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Role, User } from "../types";
import { getRoleInfoById } from "../../lib";

interface BaseAuthState {
  dealerId?: number;
  roles?: Role[];
  token?: string;
  userId?: number;
  userName?: string;
  user?: User;
  requiresMfa?: Boolean;
}

export interface AuthState extends BaseAuthState {
  oldAuth?: (BaseAuthState & { returnUrl?: string }) | null;
}

const initialState: AuthState = {};

export const auth = {
  ...createSlice({
    name: "auth",
    initialState,
    reducers: {
      setAuthState(state, { payload }: PayloadAction<AuthState | undefined>) {
        if (!payload) {
          return initialState;
        }
        Object.assign(state, { ...payload, oldAuth: null });
      },
      enterImpersonation(
        state,
        {
          payload,
        }: PayloadAction<(AuthState & { returnUrl?: string }) | undefined>,
      ) {
        if (!payload) {
          return state;
        }
        const { returnUrl, ...rest } = payload;
        Object.assign(state, { ...rest, oldAuth: { ...state, returnUrl } });
      },
      exitImpersonation(state) {
        if (!state.oldAuth) {
          return state;
        }
        const {
          oldAuth: { returnUrl: _, ...oldAuth },
        } = state;
        Object.assign(state, { ...oldAuth, oldAuth: null });
      },
      setOrgRoles(
        state,
        { payload: orgId }: PayloadAction<number | undefined>,
      ) {
        const roles = state.user?.orgs
          ?.find((org) => org.id === orgId)
          ?.user_roles.map((ur) => ({
            id: ur.role_id,
            ...getRoleInfoById(ur.role_id),
          }));
        state.roles = roles;
      },
    },
  }),
  persist: true,
};
