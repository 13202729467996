import { Editor } from "@tiptap/react";
import { FormHelperText, Stack, useTheme } from "@mui/material";
import { CustomEditorBody } from "./CustomEditorBody";
import { CustomEditorHeader } from "./CustomEditorHeader";
import { FocusEvent, MouseEvent } from "react";

interface Props {
  editor: Editor | null;
  placeholder?: string;
  onBlur?: (e: FocusEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>) => void;
  error?: any;
  my?: number | string;
}

export const CustomEditor = ({
  editor,
  placeholder = "",
  onBlur,
  error,
  my,
}: Props) => {
  const { palette } = useTheme();

  if (!editor) return null;

  return (
    <Stack direction={"column"} width={"100%"} my={my ?? 2}>
      <Stack
        direction={"column"}
        sx={{
          border: error
            ? `2px solid ${palette.error.light}`
            : `1px solid ${palette.grey["500"]}`,
          borderRadius: "4px",
        }}
        onBlur={onBlur}
        width={"100%"}
      >
        <CustomEditorHeader editor={editor} />
        <CustomEditorBody editor={editor} placeholder={placeholder} />
      </Stack>
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </Stack>
  );
};
