import {
  authSelectors,
  clientActions,
  clientSelectors,
  redirectToLogin,
  uiSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import Notification from "../../components/Notification";
import { Outlet } from "react-router-dom";
import { useMobile } from "../../themes";
import {
  MobileDrawer,
  NavDrawer,
  collapsedDrawerWidth,
  OrgSelectBar,
  TimerBar,
} from "./drawer";
import { MainContentStyled, MainPageStyled } from "./MainLayout.styles";
import { BackdropLoader } from "./BackdropLoader";
import React, { useEffect } from "react";
import { Navigation, useLocation } from "../../lib";
import { MainPages } from "../../pages/main";
import {
  TaskDrawer,
  TaskModal,
  useNotificationSW,
  useOrgId,
  useInactivityTracker,
} from "../../components";
import { useGetUserProfileQuery } from "../../state/rtk-query/state/user";
import { useGetMyPreferencesQuery } from "../../state/rtk-query/state/preferences";
import { skipToken } from "@reduxjs/toolkit/query";
import { useHeightOffset } from "../../components/hooks/useHeightOffset";

function _MainLayout() {
  const orgId = useOrgId();
  const loading = useSelector(uiSelectors.loading);
  const isInternal = useSelector(authSelectors.isInternal);
  const isAdmin = useSelector(authSelectors.isAdmin);
  const inImpersonation = useSelector(authSelectors.inImpersonation);
  const isMobile = useMobile();
  const { pathname } = useLocation();
  const pages = MainPages(isInternal, isAdmin);
  const drawerMargin = isMobile ? 0 : `${collapsedDrawerWidth + 1}px`;
  const preScreen = pages.prescreen.path;
  const onPrescreen = pathname === preScreen;

  const dispatch = useDispatch();
  const currentOrg = useSelector(clientSelectors.currentOrg);
  const taskViewPref = useSelector(clientSelectors.taskPreference);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const { currentData: userInfo } = useGetUserProfileQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const { currentData: userPref } = useGetMyPreferencesQuery(
    orgId ? { orgId } : skipToken,
  );
  const modalPref = userPref?.find(
    (up) => up?.internal_name === "view_task_as_modal",
  );

  const { orgs = [] } = userInfo || {};

  useNotificationSW();

  const defaultOrg =
    orgs.length &&
    (orgs.length === 1
      ? orgs[0]
      : orgs.find(
          (o) => userInfo?.default_org_id && o.id === userInfo?.default_org_id,
        ));

  const goToPrescreen = !defaultOrg && orgs.length > 1;

  useEffect(() => {
    let timeout: undefined | NodeJS.Timeout;
    if (isLoggedIn) {
      if (
        !isInternal &&
        !onPrescreen &&
        goToPrescreen &&
        (!currentOrg || !orgs.find((org) => org.id === currentOrg.id))
      ) {
        Navigation.go(preScreen);
      } else if (
        !isInternal &&
        defaultOrg &&
        (!currentOrg ||
          onPrescreen ||
          !orgs.find((org) => org.id === currentOrg.id))
      ) {
        dispatch(clientActions.setCurrentOrg(defaultOrg));
        Navigation.go("/");
      }
    } else {
      timeout = setTimeout(() => {
        dispatch(clientActions.setDraftChats({}));
        redirectToLogin();
      }, 300);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isInternal, pathname, isLoggedIn, orgs]);
  useEffect(() => {
    if (modalPref) {
      dispatch(
        clientActions.setTaskPreferenceAction(
          modalPref?.value ? "modal" : "drawer",
        ),
      );
    }
  }, [modalPref]);

  const heightOffset = useHeightOffset();

  useInactivityTracker();

  if (!isLoggedIn) return null;

  const showTimer =
    isInternal && !inImpersonation && !!userInfo?.timer_is_active;
  const showOrgSelect = orgs.length > 1 && !isInternal && !onPrescreen;
  return (
    <>
      {showOrgSelect ? <OrgSelectBar /> : showTimer ? <TimerBar /> : null}

      {onPrescreen ? null : isMobile ? <MobileDrawer /> : <NavDrawer />}
      <MainContentStyled
        // since the dashboard has a split background it uses its own style component to shade
        shadeBackground={pathname !== "/" && !onPrescreen}
        sx={{
          marginLeft: onPrescreen || isMobile ? 0 : drawerMargin,
        }}
      >
        <MainPageStyled heightOffset={heightOffset}>
          {<Outlet />}
          {taskViewPref === "drawer" ? <TaskDrawer /> : <TaskModal />}
        </MainPageStyled>
      </MainContentStyled>
      <Notification />
      <BackdropLoader
        loading={loading}
        sx={(theme) => ({
          marginLeft: drawerMargin, // prevent backdrop from covering drawer menu
          zIndex: theme.zIndex.tooltip + 1, // max zIndex
        })}
      />
    </>
  );
}

export const MainLayout = React.memo(_MainLayout);
