import { fixJsFormattedDate, yup } from "../../lib";
import { useGetOrgTermsQuery } from "../../state/rtk-query/state/terms";
import { BaseForm, initValue } from "./BaseForm";
import { Fragment, useEffect, useMemo, useState } from "react";
import {
  InputField,
  SelectField,
  ArrayField,
  SingleDatePickerField,
} from "./fields";
import { skipToken } from "@reduxjs/toolkit/query";
import { format, isAfter, isBefore } from "date-fns";
import { useTermItemTypesList } from "../hooks/useLists";
import { useUpdateTermsMutation } from "../../state/rtk-query/state/terms";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { uiActions } from "../../state";
import { ConfirmDeleteModal } from "../tables";
import {
  GetOrgByIdApiResponse,
  GetOrgsApiResponse,
} from "../../state/rtk-query/state/organization";

const validationSchema = yup.object({
  term_items: yup
    .array(
      yup.object({
        item_id: yup.number().required("Item is required"),
        quantity: yup.number(),
        rate: yup.number().test({
          test: (v, context) => {
            const { options } = context;
            return !!(v && v > 0) || (options as any).index;
          },
          message: "Rate is required for additional custom work",
        }),
      }),
    )
    .min(1, "Additional custom work is required"),
  org_id: yup.number().required("Organization is required"),
  start_date: yup
    .date()
    .test("start_date_test", (value, { createError, parent, path }) => {
      if (value) {
        const { end_date } = parent;
        if (isAfter(new Date(value), new Date(end_date))) {
          return createError({
            path,
            message: "Start date must be before the end date",
          });
        }
      }
      return true;
    })
    .required("Start date is required"),
  end_date: yup
    .date()
    .test("start_date_test", (value, { createError, path, parent }) => {
      if (value) {
        const { start_date } = parent;
        if (isBefore(new Date(value), new Date(start_date))) {
          return createError({
            path,
            message: "End date must be later than start date",
          });
        }
      }
      return true;
    })
    .required("End date is required"),
});

interface Props {
  onClose: () => void;
  editOrg?: GetOrgsApiResponse["rows"][0] | GetOrgByIdApiResponse;
}

export const AddTermsForm = ({ onClose, editOrg }: Props) => {
  const { id } = editOrg ?? {};
  const dispatch = useDispatch();
  const { termItemTypes, getTermItemTypes } = useTermItemTypesList();
  const { currentData: myTerms } = useGetOrgTermsQuery(
    id ? { orgId: id } : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const [updateOrgTerms, { isSuccess }] = useUpdateTermsMutation();

  useEffect(() => {
    if (isSuccess) {
      onClose();
      dispatch(uiActions.showSuccess("Terms successfully updated"));
    }
  }, [isSuccess]);

  const addedCwItem = termItemTypes?.find(
    (i) => i.internal_name === "added_custom_work",
  );

  const termItems = [...(myTerms?.term_items || [])]
    .sort(
      (a, b) =>
        +(b.item_id === addedCwItem?.id) - +(a.item_id === addedCwItem?.id),
    )
    .map((ti) => ({
      item_id: ti.item_id,
      quantity: ti.quantity,
      rate: ti.rate,
    }));

  if (termItems[0]?.item_id !== addedCwItem?.id) {
    termItems.unshift({ item_id: addedCwItem?.id, quantity: 0, rate: 180 });
  } else if (termItems[0]?.rate === 0) {
    termItems[0].rate = 180;
  }

  const initialValues = useMemo(() => {
    return {
      term_items: termItems,
      org_id: initValue(editOrg?.id),
      start_date: initValue(
        myTerms?.start_date ? fixJsFormattedDate(myTerms?.start_date) : null,
      ),
      end_date: initValue(
        myTerms?.end_date ? fixJsFormattedDate(myTerms?.end_date) : null,
      ),
    };
  }, [editOrg, myTerms]);

  const handleConfirm = async (values: typeof initialValues) => {
    if (id) {
      const body = {
        ...values,
        start_date: format(
          new Date(values?.start_date),
          "yyyy-MM-dd",
        ).toString(),
        end_date: format(new Date(values?.end_date), "yyyy-MM-dd").toString(),
        term_items: values?.term_items?.length
          ? values?.term_items?.map((ti) => ({
              ...ti,
              quantity: Number(ti.quantity) || 0,
              rate: Number(ti.rate) || 0,
            }))
          : [],
      };
      if (body.start_date === undefined || body.end_date === undefined) {
        console.error("Not saving - missing start or end date");
        return;
      }
      updateOrgTerms({ orgId: id, body });
    }
  };

  const [openDeleteModel, setOpenDeleteModel] = useState<
    (() => void) | undefined
  >(undefined);

  const handleSubmit = async (values: typeof initialValues) => {
    if (id) {
      if (
        initialValues.start_date &&
        new Date(initialValues.start_date).getTime() !==
          new Date(values.start_date).getTime()
      ) {
        setOpenDeleteModel(() => () => handleConfirm(values));
      } else {
        handleConfirm(values);
      }
    }
  };

  return (
    <>
      <BaseForm
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        onClose={onClose}
        enableReinitialize
        disableEditing={!!editOrg?.deleted_at}
      >
        {({ values }) => {
          const currentItemIds = values.term_items.map((item) => item.item_id);
          const termItemsAvailable = useMemo(() => {
            return (
              termItemTypes.filter((allItems) => {
                return !currentItemIds.includes(allItems.id);
              }) ?? termItemTypes
            );
          }, [values.term_items, termItemTypes]);
          return (
            <Grid container rowSpacing={2} columnSpacing={1.5}>
              <SingleDatePickerField name="start_date" disableFuture topLabel />
              <SingleDatePickerField name="end_date" topLabel />
              <ArrayField
                name="term_items"
                buttonName="Add term item"
                itemInitialValue={{
                  item_id: "",
                  quantity: 0,
                  rate: 0,
                }}
                label="Term items"
                minLength={1}
                maxLength={termItemTypes.length}
              >
                {({ FieldWithDeleteButton }) =>
                  values.term_items.map((ti, index) => {
                    const item = getTermItemTypes(ti.item_id);
                    const items = item ? [item] : termItemsAvailable;
                    return (
                      <Fragment key={index}>
                        <SelectField
                          name={`term_items[${index}].item_id`}
                          items={items}
                          datatype="number"
                          topLabel="Term type"
                          required
                          readOnly={!!ti.item_id}
                        />
                        <InputField
                          name={`term_items[${index}].quantity`}
                          type="number"
                          topLabel="Quantity"
                          md3
                          xs6
                        />
                        <FieldWithDeleteButton
                          Component={InputField}
                          name={`term_items[${index}].rate`}
                          topLabel="Rate"
                          index={index}
                          md3
                          xs6
                        />
                      </Fragment>
                    );
                  })
                }
              </ArrayField>
            </Grid>
          );
        }}
      </BaseForm>
      <ConfirmDeleteModal
        handleConfirm={() => {
          openDeleteModel && openDeleteModel();
        }}
        open={!!openDeleteModel}
        onClose={() => {
          setOpenDeleteModel(undefined);
        }}
        confirmActionButtonColor="primary"
        confirmActionButtonText="Confirm"
        titleText="Create term"
        subTitleText="Are you sure you want to create new term dates? Doing so will restart all term items. Used prepaid hours will be set to 0."
      />
    </>
  );
};
