import { DataGridProProps } from "@mui/x-data-grid-pro";
import { StyledDataGrid } from "./DataGrid.styles";
import { Stack, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import loadAnimation from "../../assets/img/AdmireLoader.gif";
import { ToolBar } from "./ToolBar";

export const Loading = memo(() => {
  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
      sx={{
        "& > img:hover": {
          transition: "all .5s",
          transform: "rotate(360deg)",
        },
      }}
    >
      <img
        style={{
          width: 100,
          height: 100,
        }}
        src={loadAnimation}
        alt="sidebar"
      />
    </Stack>
  );
});

const Default = () => {
  // No row's flashes right before the data is displayed it seems like it's a server-side issue
  // see: https://github.com/mui/mui-x/issues/4309#issuecomment-1172401858
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 300);
    return () => clearTimeout(timeout);
  }, []);

  if (!show) return null;
  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
    >
      <Typography variant="subtitle1">No Rows Found</Typography>
    </Stack>
  );
};

const pageSizeOptions = [10, 25, 50, 100, 250, 500];

const slotProps = {
  panel: {
    sx: {
      "& .MuiIconButton-root, .MuiSvgIcon-root": {
        fontSize: "20px !important",
      },
    },
  },
  columnMenu: {
    sx: {
      "& .MuiIconButton-root, .MuiSvgIcon-root": {
        fontSize: "1em !important",
      },
    },
  } as any,
  toolbar: {
    hideToolbar: true,
  },
};

const sx = {
  ".MuiDataGrid-columnHeaderTitle": {
    textTransform: "uppercase",
  },
  ".MuiDataGrid-row": {
    cursor: "pointer",
  },
};

const slots = {
  toolbar: ToolBar,
  noResultsOverlay: Default,
  noRowsOverlay: Default,
};

const fetchingSlots = {
  toolbar: ToolBar,
  noResultsOverlay: Loading,
  noRowsOverlay: Loading,
};

const autosizeOptions = {
  includeHeaders: true,
  outliersFactor: 100,
  includeOutliers: false,
};

export namespace BaseDataGrid {
  export type Props<R extends Record<string, any> = any> =
    DataGridProProps<R> & { isFetching?: boolean };
}

export function BaseDataGrid<R extends Record<string, any> = any>({
  isFetching,
  ...props
}: BaseDataGrid.Props<R>) {
  const slotsToUse = isFetching ? fetchingSlots : slots;
  return (
    <StyledDataGrid
      disableMultipleColumnsSorting
      pagination
      density="standard"
      autosizeOnMount
      pageSizeOptions={pageSizeOptions}
      autosizeOptions={autosizeOptions}
      {...(props as any)}
      sx={props.sx ? { ...sx, ...props.sx } : sx}
      slots={props.slots ? { ...slotsToUse, ...props.slots } : slotsToUse}
      slotProps={
        props.slotProps
          ? {
              ...slotProps,
              ...props.slotProps,
              toolbar: { hideToolbar: true, ...props.slotProps?.toolbar },
            }
          : slotProps
      }
    />
  );
}
