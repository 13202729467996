import { Alert, useTheme } from "@mui/material";
import { useOrgId } from "../../../components/hooks";
import { useGetMyPreferencesQuery } from "../../../state/rtk-query/state/preferences";
import {
  PreferenceCardsContainer,
  PreferenceCard,
  ProfileCard,
} from "../../../components";
import { useSelector } from "react-redux";
import { authSelectors, clientSelectors } from "../../../state";
import { useGetUserProfileQuery } from "../../../state/rtk-query/state/user";

export const ProfilePage = () => {
  const orgId = useOrgId();
  const isInternal = useSelector(authSelectors.isInternal);
  const { palette } = useTheme();
  const { currentData } = useGetMyPreferencesQuery({ orgId });

  const emailPrefs = currentData?.filter((p) => p?.type === "email");
  const userPrefs = currentData?.filter((p) => p?.type === "user");
  const notificationPrefs = currentData?.filter(
    (p) => p?.type === "notification",
  );

  const org = useSelector(clientSelectors.currentOrg);
  const isExternalAdmin = useSelector(authSelectors.isExternalAdmin);

  const { currentData: userProfile } = useGetUserProfileQuery();
  const { orgs = [] } = userProfile || {};
  return (
    <>
      <ProfileCard data={userProfile} />

      {orgs?.length > 1 && !isInternal ? (
        <Alert
          severity="warning"
          sx={{ fontWeight: 500, border: `1px solid ${palette.warning[500]}` }}
        >
          {`Please note, changes below will only apply to ${
            org?.name ?? "your currently logged in org"
          }`}
        </Alert>
      ) : null}

      <PreferenceCardsContainer title="Email Preferences">
        {emailPrefs?.map((pref) => {
          return <PreferenceCard key={pref.id} {...pref} />;
        })}
      </PreferenceCardsContainer>

      <PreferenceCardsContainer title="User Preferences">
        {userPrefs?.map((pref) => {
          return <PreferenceCard key={pref.id} {...pref} />;
        })}
      </PreferenceCardsContainer>

      {isExternalAdmin && !isInternal && (
        <PreferenceCardsContainer title="Notification Preferences">
          {notificationPrefs?.map((pref) => {
            return <PreferenceCard key={pref.id} {...pref} />;
          })}
        </PreferenceCardsContainer>
      )}
    </>
  );
};
