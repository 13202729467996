import {
  Stack,
  Button,
  Typography,
  IconButton,
  Box,
  useTheme,
  SxProps,
  Theme,
} from "@mui/material";

import { FileIcon, MediaDownload, CloseIcon, Tooltip } from "../icons";
import { EllipsisTypographyStyled } from "../styled";
import { ConfirmDeleteDialog } from "../tables";
import { PreviewFileExtensions } from "../../lib";
import { ArrayElement } from "../../global";
import { GetTaskApiResponse } from "../../state/rtk-query/state/tasks";
import { Dispatch, SetStateAction, useCallback } from "react";
import {
  GetSignedUrlByMediaIdApiResponse,
  useDeleteMediaMutation,
  useLazyGetSignedUrlByMediaIdQuery,
} from "../../state/rtk-query/state/media";
import { format } from "date-fns";
import { useOrgId } from "../hooks";

interface Props {
  taskId: number;
  setLightBoxSlides: Dispatch<
    SetStateAction<GetSignedUrlByMediaIdApiResponse[] | undefined>
  >;
  setLightBoxIndex: Dispatch<SetStateAction<number>>;
  allMedia: GetTaskApiResponse["media"];
  isComplete?: boolean;
  condensed?: boolean;
  attachmentSx?: SxProps<Theme>;
}
export const AttachmentsWithIcons = ({
  taskId,
  setLightBoxSlides,
  setLightBoxIndex,
  allMedia,
  isComplete,
  attachmentSx,
  condensed,
}: Props) => {
  const { palette } = useTheme();
  const [getMediaSignedUrl] = useLazyGetSignedUrlByMediaIdQuery();
  const [deleteMedia] = useDeleteMediaMutation();
  const orgId = useOrgId();
  const mediaClick = useCallback(
    async (media: { filename?: string; id?: number } | undefined) => {
      if (media && media?.id) {
        if (media?.filename?.toLowerCase()?.endsWith(".pdf")) {
          const getPdf = await getMediaSignedUrl({
            id: media?.id,
            download: false,
            orgId,
          }).unwrap();
          window.open(getPdf.signedUrl);
        } else {
          const inLightBox: GetTaskApiResponse["media"] =
            allMedia?.filter((mf) => {
              const filename = mf?.filename;
              const extension = filename?.split(".").pop()?.toLowerCase();

              if (extension === "pdf") return false;

              return PreviewFileExtensions.includes(
                `.${extension}` as ArrayElement<typeof PreviewFileExtensions>,
              );
            }) ?? [];

          const getSignedUrlData = await Promise.all(
            inLightBox.map((s) => {
              if (s?.id) {
                return getMediaSignedUrl({
                  id: s?.id,
                  download: false,
                  orgId,
                }).unwrap();
              }
            }),
          );
          const data = getSignedUrlData.filter(
            //https://stackoverflow.com/a/54317362  typescript doesn't behave with Array.filter
            (s): s is GetSignedUrlByMediaIdApiResponse =>
              typeof s !== "boolean",
          );

          if (data.length) {
            const index = inLightBox.map((img) => img.id).indexOf(media?.id);
            setLightBoxIndex(index);
            setLightBoxSlides(() => data);
          }
        }
      }
    },
    [taskId, allMedia, setLightBoxIndex, setLightBoxSlides],
  );
  const downloadMedia = useCallback(
    async (mediaId?: number) => {
      if (mediaId) {
        const data = await getMediaSignedUrl({
          id: mediaId,
          download: true,
          orgId,
        }).unwrap();
        const link = document.createElement("a");
        link.href = data.signedUrl;
        link.click();
      }
    },
    [taskId],
  );

  const deleteMediaClick = useCallback(
    async (mediaId: number) => {
      if (mediaId) {
        await deleteMedia({ id: mediaId, orgId });
      }
    },
    [taskId, orgId],
  );

  return (
    <>
      {allMedia?.map((mf) => {
        const shouldBeDisabled = !PreviewFileExtensions.filter((ext) =>
          mf?.filename?.toLowerCase()?.endsWith(ext),
        ).length;
        return (
          <Tooltip
            title={shouldBeDisabled ? `Cannot preview ${mf?.filename}` : ""}
            key={mf.id}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              width={"100%"}
              sx={{ ...attachmentSx }}
            >
              <Button
                onClick={() =>
                  mediaClick({
                    id: mf?.id,
                    filename: mf?.filename?.toLowerCase() || undefined,
                  })
                }
                sx={{
                  width: condensed ? "100%" : "170px",
                  justifyContent: "flex-start",
                  paddingLeft: 0,
                  height: "30px",
                  alignItems: "center",
                }}
                disabled={shouldBeDisabled}
              >
                <FileIcon width={15} height={22} style={{ minWidth: 15 }} />
                <EllipsisTypographyStyled ml={1}>
                  {mf?.filename}
                </EllipsisTypographyStyled>
              </Button>

              <Typography
                variant="subtitle2"
                width={"100%"}
                pr={condensed ? 1 : 0}
                whiteSpace={condensed ? "nowrap" : "inherit"}
              >
                {condensed ? "" : "Uploaded by"}{" "}
                {mf.uploaded_by_user?.first_name}{" "}
                {mf.uploaded_by_user?.last_name}
                {mf?.created_at
                  ? ` on ${format(new Date(mf.created_at), "M.d")}`
                  : ""}
              </Typography>
              <IconButton
                onClick={() => downloadMedia(mf?.id)}
                size="small"
                sx={{ mb: 0.5 }}
              >
                <MediaDownload />
              </IconButton>

              {!isComplete && (
                <ConfirmDeleteDialog
                  handleConfirm={() => deleteMediaClick(mf?.id ?? 0)}
                  titleText={`Are you sure you want to delete ${mf.filename}`}
                  ButtonIcon={
                    <Box sx={{ color: palette.grey[900] }}>
                      <CloseIcon width={18} height={22} color="inherit" />
                    </Box>
                  }
                />
              )}
            </Stack>
          </Tooltip>
        );
      })}
    </>
  );
};
